import React, { PropsWithChildren } from 'react';
import { InvoicesFiltersProvider } from '../modules/invoices';
import { AuthProvider } from './auth.provider';
import { DateTimeServiceProvider } from './date-time-service.provider';
import GlobalStyle from './GlobalStyle';
import { MonthContextProviderWrapper } from './month.provider';

const Providers: React.FC<PropsWithChildren<any>> = ({ children }) => (
  <AuthProvider>
    <GlobalStyle />
    <DateTimeServiceProvider>
      <MonthContextProviderWrapper>
        <InvoicesFiltersProvider>{children}</InvoicesFiltersProvider>
      </MonthContextProviderWrapper>
    </DateTimeServiceProvider>
  </AuthProvider>
);

export default Providers;
