import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Drawer,
  FormControlLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useCalendarFilterContext } from './FilterProvider';

const Circle = styled.div<{
  color: string;
}>`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  ${({ theme, color }) => `
        background-color: ${color};
        margin-right: ${theme.spacing(1.5)};
    `}
`;

const Filters = () => {
  const { t } = useTranslation();
  const [isShown, setIsShown] = useState(false);
  const { setPaidFilter, setUnpaidFilter, showPaid, showUnpaid } =
    useCalendarFilterContext();
  let buttonText = t('Unpaid only');
  if (showPaid && !showUnpaid) {
    buttonText = t('Paid only');
  }
  if (showPaid && showUnpaid) {
    buttonText = t('All expenses');
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <Tooltip title={t('Show filters')}>
          <Button
            startIcon={<FilterAltIcon />}
            onClick={() => setIsShown(true)}
            color="primary"
            variant="contained"
            component="span"
          >
            {buttonText}
          </Button>
        </Tooltip>
      </Box>
      <Drawer anchor="right" open={isShown} onClose={() => setIsShown(false)}>
        <Card
          sx={{
            width: '250px',
          }}
        >
          <CardContent sx={{ padding: 3 }}>
            <Box mb={4} />
            <Box display="flex" flexDirection="column">
              <Typography variant="h3">{t('Filters')}</Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setUnpaidFilter(!showUnpaid)}
                    checked={showUnpaid}
                  />
                }
                label={t('Show unpaid')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => setPaidFilter(!showPaid)}
                    checked={showPaid}
                  />
                }
                label={t('Show paid')}
              />
              <Box mb={4} />
              <Typography variant="h3">{t('Legend')}</Typography>
              <Box mb={2} />
              <Box alignItems="center" mb={2} display="flex">
                <Circle color="#1991FF" />
                <Typography variant="body1">{t('Supplies')}</Typography>
              </Box>
              <Box alignItems="center" mb={2} display="flex">
                <Circle color="#DC6FEE" />
                <Typography variant="body1">{t('Repetitive')}</Typography>
              </Box>
              <Box alignItems="center" mb={2} display="flex">
                <Circle color="#1DAE93" />
                <Typography variant="body1">{t('One-time')}</Typography>
              </Box>
              <Box alignItems="center" mb={2} display="flex">
                <Circle color="#5C61D7" />
                <Typography variant="body1">{t('Labor')}</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Drawer>
    </>
  );
};

export default Filters;
