import { StringMap, TFunctionKeys, TFunctionResult, TOptions } from 'i18next';

export function translateAndCastToString<TKeys extends TFunctionKeys = string>(
  keys: TKeys,
) {
  return translate(keys) as unknown as string;
}

export function translate<
  TKeys extends TFunctionKeys = string,
  TInterpolationMap extends object = StringMap,
>(
  key: TKeys | TKeys[],
  defaultValue?: string,
  options?: TOptions<TInterpolationMap> | string,
): [TKeys | TKeys[], TOptions<TInterpolationMap> | string];
// eslint-disable-next-line no-redeclare
export function translate(...args: TFunctionResult[]) {
  return args;
}
