import { Color } from '../enums';

const revenueIndexToColorMap = {
  0: Color.Blue,
  1: Color.Tertiary,
  2: Color.DarkGreen,
  3: Color.Purple,
  4: Color.Lemon,
};

export const getRevenueColorByIndex = (
  index: keyof typeof revenueIndexToColorMap,
) => {
  return revenueIndexToColorMap[index];
};

const expenseIndexToColorMap = {
  0: Color.DarkRed,
  1: Color.Yellow,
  2: Color.Orange,
  3: Color.Pink,
  4: Color.DarkPurple,
};

export const getExpenseColorByIndex = (
  index: keyof typeof expenseIndexToColorMap,
) => {
  return expenseIndexToColorMap[index] || Color.Grey;
};
