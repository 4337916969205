import { Paper, PaperProps, Toolbar, ToolbarProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const ToolbarPaper = styled(({ square, ...other }: PaperProps) => (
  <Paper square sx={{ boxShadow: 'none' }} {...other} />
))``;

const SecondaryToolbar = styled(
  ({ ...other }: Omit<ToolbarProps, 'component' | 'zindex'>) => (
    <Toolbar component={ToolbarPaper} zindex={1} {...other} />
  ),
)`
  box-shadow: none;
  height: ${({ theme }) => theme.toolbar.desktopHeight}px;
`;

export default SecondaryToolbar;
