import styled from 'styled-components';

const AbsoluteModalWrapper = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -30%);
  width: 90%;
  height: 85vh;
  ${({ theme }) => `
	${theme.breakpoints.up('sm')} {
		width: 50%;
		height: 80vh;
}`}
`;

export default AbsoluteModalWrapper;
