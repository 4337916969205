import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Button, Chip, InputAdornment, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { orderBy } from 'lodash/fp';
import { DatePickerField, SelectField, TextField } from '../../../../ui';
import { useUser } from '../../../../providers/current-user.provider';
import { useMoneyService } from '../../../../providers/money-service.provider';
import { translateAndCastToString } from '../../../../utils/translate';
import {
  refetchCategoriesAndSuppliersQuery,
  useCreateCategoryMutation,
  useCreateSupplierMutation,
} from '../../../../generated/graphql';

const validationSchema = yup.object({
  name: yup
    .string()
    .required(translateAndCastToString('Name is required'))
    .min(
      2,
      translateAndCastToString('Name should be of minimum 2 characters length'),
    ),
  categoryId: yup
    .string()
    .required(translateAndCastToString('Category is required')),
  supplierId: yup.string(),
  amount: yup
    .number()
    .required(translateAndCastToString('Amount is required'))
    .min(0, translateAndCastToString('Amount cannot be negative'))
    .max(
      1000000,
      translateAndCastToString('Amount cannot be larger than 1000000'),
    ),
  firstPaymentDate: yup
    .date()
    .required(translateAndCastToString('First payment date is required')),
});

interface RecurrentExpenseFormProps {
  categories: Array<{ value: string; label: string }>;
  suppliers: Array<{ value: string; label: string }>;
  onSubmit: (values: RecurrentExpenseFormValues) => void;
  initialData?: Partial<RecurrentExpenseFormValues>;
}

export interface RecurrentExpenseFormValues {
  name: string;
  amount: number | '';
  firstPaymentDate: DateTime | null;
  categoryId: string;
  supplierId?: string;
}

const RecurrentExpenseForm = ({
  categories,
  suppliers,
  onSubmit,
  initialData,
}: RecurrentExpenseFormProps) => {
  const [createNewSupplier, { loading: createSupplierLoading }] =
    useCreateSupplierMutation({
      refetchQueries: [refetchCategoriesAndSuppliersQuery()],
    });

  const [createNewCategory, { loading: createCategoryLoading }] =
    useCreateCategoryMutation({
      refetchQueries: [refetchCategoriesAndSuppliersQuery()],
    });

  const loading = createCategoryLoading || createSupplierLoading;

  const orderedSuppliers = orderBy('label', 'asc', suppliers);
  const orderedCategories = orderBy('label', 'asc', categories);

  const { t } = useTranslation();
  const { moneyService } = useMoneyService();
  const { user } = useUser();

  const formik = useFormik<RecurrentExpenseFormValues>({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      name: '',
      amount: '',
      firstPaymentDate: null,
      categoryId: '',
      ...initialData,
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit({
        ...values,
      });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <TextField
        autoFocus
        fullWidth
        required
        id="amount"
        name="amount"
        label={t('Amount')}
        value={formik.values.amount}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.amount && Boolean(formik.errors.amount)}
        helperText={formik.touched.amount && formik.errors.amount}
        InputProps={{
          type: 'number',
          endAdornment: (
            <InputAdornment position="end">
              {/* getCurrency doesn't localize it... We need another way */}
              {moneyService.getCurrency({
                currency: user.currency,
                locale: user.languageCode,
              })}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        required
        id="name"
        name="name"
        label={t('Name')}
        placeholder={t('Rent')}
        value={formik.values.name}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      <SelectField
        fullWidth
        select
        required
        disabled={loading}
        id="categoryId"
        name="categoryId"
        label={t('Category')}
        placeholder={t('General')}
        value={formik.values.categoryId}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
        helperText={formik.touched.categoryId && formik.errors.categoryId}
        options={orderedCategories}
        onCreateNewMutation={async (name: string) => {
          try {
            const result = await createNewCategory({
              variables: {
                name,
              },
              toastConfig: {
                successText: t(
                  `New category {{name}} was successfully created`,
                  {
                    name,
                  },
                ),
              },
            });

            if (result?.data) {
              await formik.setFieldValue(
                'categoryId',
                result.data.createCategory.id,
              );
            }
          } catch (e) {
            // Nothing we can do
          }
        }}
      />
      <Box mt={1} mb={2} display="flex" alignItems="center">
        <Typography sx={{ fontWeight: 'bold', marginRight: 2 }} variant="body1">
          {t('Repeats:')}
        </Typography>
        <Chip
          label={
            <Typography sx={{ fontWeight: 'bold' }} variant="body1">
              {t('Monthly')}
            </Typography>
          }
          color="primary"
        />
      </Box>
      <DatePickerField
        required
        name="firstPaymentDate"
        label={t('First payment date')}
        value={formik.values.firstPaymentDate}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.firstPaymentDate &&
          Boolean(formik.errors.firstPaymentDate)
        }
        helperText={
          formik.touched.firstPaymentDate && formik.errors.firstPaymentDate
        }
      />
      <SelectField
        fullWidth
        select
        disabled={loading}
        placeholder={t('Makro s.r.o.')}
        id="supplierId"
        name="supplierId"
        label={t('Supplier')}
        value={formik.values.supplierId}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.supplierId && Boolean(formik.errors.supplierId)}
        helperText={formik.touched.supplierId && formik.errors.supplierId}
        options={orderedSuppliers}
        addNewText={t('Create supplier')}
        onCreateNewMutation={async (name: string) => {
          try {
            const result = await createNewSupplier({
              variables: {
                name,
              },
              toastConfig: {
                successText: t(
                  `New supplier {{name}} was successfully created`,
                  {
                    name,
                  },
                ),
              },
            });

            if (result?.data) {
              await formik.setFieldValue(
                'supplierId',
                result.data.createSupplier.id,
              );
            }
          } catch (e) {
            // Nothing we can do
          }
        }}
      />
      <Box mt={3} display="flex" justifyContent="flex-end" alignItems="center">
        <Button color="primary" variant="contained" type="submit">
          {t('Create expense')}
        </Button>
      </Box>
    </form>
  );
};

export default RecurrentExpenseForm;
