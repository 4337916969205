import { PropsWithChildren, createContext, useContext, useState } from 'react';
import { LaborExpense } from '../types';

interface DialogContextInterface {
  entity: LaborExpense | null;

  closeDialog(): void;

  openDialog(data: LaborExpense | null): void;
}

const DialogContext = createContext({ entity: null } as DialogContextInterface);
export const DialogContextProvider = ({ children }: PropsWithChildren) => {
  const [currentEntity, setCurrentEntity] = useState<LaborExpense | null>(null);
  return (
    <DialogContext.Provider
      value={{
        entity: currentEntity,
        closeDialog: () => setCurrentEntity(null),
        openDialog(data: LaborExpense | null) {
          setCurrentEntity(data);
        },
      }}
    >
      {children}
    </DialogContext.Provider>
  );
};

export const useDialog = () => useContext(DialogContext);
