import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';
import { useDateTimeService } from './date-time-service.provider';

interface ContextProps {
  currentMonth: Date;
  setToPrevMonth: () => void;
  setToNextMonth: () => void;
  resetMonth: () => void;
}

const defaultState = {
  currentMonth: new Date(),
  setToPrevMonth: () => {},
  setToNextMonth: () => {},
  resetMonth: () => {},
};

const MonthContext = createContext<ContextProps>(defaultState);

const MonthContextProvider: React.FC<
  PropsWithChildren<{ currentMonth?: Date }>
> = ({ children, currentMonth }) => {
  const context = useProvideMonthContext(currentMonth);

  return (
    <MonthContext.Provider value={context}>{children}</MonthContext.Provider>
  );
};

export const useMonthContext = () => {
  return useContext(MonthContext);
};

function useProvideMonthContext(initialState?: Date) {
  const { dateTimeService } = useDateTimeService();

  const currentMonthDate = initialState || new Date();

  const [currentMonth, setMonth] =
    useState<ContextProps['currentMonth']>(currentMonthDate);

  const setToPrevMonth = () =>
    setMonth(dateTimeService.deductMonth(currentMonth));
  const setToNextMonth = () => setMonth(dateTimeService.addMonth(currentMonth));

  const resetMonth = () => setMonth(initialState || defaultState.currentMonth);

  return {
    currentMonth,
    setToPrevMonth,
    setToNextMonth,
    resetMonth,
  };
}

export const MonthContextProviderWrapper: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  return <MonthContextProvider>{children}</MonthContextProvider>;
};
