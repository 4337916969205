import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CultureCode } from '../../../../generated/graphql';
import { PasswordField, SelectField, TextField } from '../../../../ui';
import { ProfileFormValues } from './types';
import { profileFormValidation } from './validation';

const localeOptions = [
  { value: 'null', label: '\u2014' },
  { value: CultureCode.CsCz, label: 'Czech' },
  { value: CultureCode.EnUs, label: 'English' },
];

interface User {
  id: string;
  venue: { id: string; name: string };
}

interface ProfileFormProps {
  initialValues?: Partial<ProfileFormValues>;
  users: User[];
  email: string;
  defaultUserId?: string | null;
  onSubmit: (values: ProfileFormValues) => void;
  isSubmitting: boolean;
  buttonText: string;
}

export const ProfileForm = ({
  initialValues = {},
  users,
  email,
  defaultUserId,
  onSubmit,
  isSubmitting,
  buttonText,
}: ProfileFormProps) => {
  const { t } = useTranslation();

  let finalDefaultUserId = defaultUserId;
  if (!finalDefaultUserId) {
    finalDefaultUserId = users[0].id;
  }
  const formik = useFormik<ProfileFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      cultureCode: '' as CultureCode,
      languageCode: '' as CultureCode,
      password: '',
      repeatPassword: '',
      defaultUserId: finalDefaultUserId,
      ...initialValues,
    },
    validationSchema: profileFormValidation,
    onSubmit,
  });

  const venueSelectOptions: { value: string; label: string }[] = [];

  users.forEach(({ id, venue: { name } }) => {
    venueSelectOptions.push({
      value: id,
      label: name,
    });
  });

  const showRepeatPassword = formik.values.password
    ? formik.values.password.length > 0
    : false;

  useEffect(() => {
    if (!showRepeatPassword && formik.values.repeatPassword) {
      formik
        .setFieldValue('repeatPassword', '', false)
        .catch((e) => console.error(e));
    }
  }, [showRepeatPassword]);

  const cultureCodeLabelWithTooltip = (
    <Tooltip title={t('cultureCodeTooltip')}>
      <span>
        {t('Culture code')}
        <InfoOutlinedIcon
          sx={{ marginLeft: '4px', fontSize: '1rem' }}
          color="primary"
        />
      </span>
    </Tooltip>
  );

  return (
    <form onSubmit={formik.handleSubmit} autoComplete="none">
      <TextField
        required
        value={formik.values.firstName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
        helperText={formik.touched.firstName && formik.errors.firstName}
        name="firstName"
        id="firstName"
        label={t('First name')}
      />
      <TextField
        required
        value={formik.values.lastName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
        helperText={formik.touched.lastName && formik.errors.lastName}
        name="lastName"
        id="lastName"
        label={t('Last name')}
      />
      <SelectField
        value={formik.values.cultureCode}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.cultureCode && Boolean(formik.errors.cultureCode)}
        helperText={formik.touched.cultureCode && formik.errors.cultureCode}
        name="cultureCode"
        id="cultureCode"
        label={cultureCodeLabelWithTooltip}
        options={localeOptions}
      />
      <SelectField
        value={formik.values.languageCode}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.languageCode && Boolean(formik.errors.languageCode)
        }
        helperText={formik.touched.languageCode && formik.errors.languageCode}
        name="languageCode"
        id="languageCode"
        label={t('Language')}
        options={localeOptions}
      />
      {venueSelectOptions.length > 1 ? (
        <SelectField
          value={formik.values.defaultUserId}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.defaultUserId && Boolean(formik.errors.defaultUserId)
          }
          helperText={
            formik.touched.defaultUserId && formik.errors.defaultUserId
          }
          name="defaultUserId"
          id="defaultUserId"
          label={t('Default venue')}
          options={venueSelectOptions}
        />
      ) : null}
      {/* this ensures that correct login data is updated after password change */}
      <input
        disabled
        name="username"
        autoComplete="username"
        id="username"
        value={email}
        tabIndex={-1}
        style={{ display: 'none' }}
      />
      <PasswordField
        value={formik.values.password}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
        name="password"
        id="password"
        label={t('New password')}
      />
      {showRepeatPassword ? (
        <PasswordField
          value={formik.values.repeatPassword}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.repeatPassword &&
            Boolean(formik.errors.repeatPassword)
          }
          helperText={
            formik.touched.repeatPassword && formik.errors.repeatPassword
          }
          name="repeatPassword"
          id="repeatPassword"
          label={t('Repeat new password')}
        />
      ) : null}
      <Box mt={3} display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={isSubmitting}
        >
          {buttonText}
        </Button>
      </Box>
    </form>
  );
};
