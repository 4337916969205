import { orderBy } from 'lodash/fp';
import { useEffect } from 'react';
import { pdfjs } from 'react-pdf';
// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useTranslation } from 'react-i18next';
import { InvoicesStatus } from '../../enums';
import {
  InvoiceUploadsQuery,
  InvoiceUploadsQueryResult,
  UploadError,
  UploadedInvoiceState,
  useInvoiceUploadsLazyQuery,
} from '../../generated/graphql';
import { getFullName } from '../expenses/components/labor/components/utils';
import { InvoiceList, PageContainer } from './components';
import Filters from './components/Filters';
import { InvoiceListItemProps } from './components/InvoiceListItem';
import UploadInvoice from './components/UploadInvoice';
import InvoicesToolbar from './components/InvoicesToolbar';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const imageUploadsEndpoint = import.meta.env.VITE_IMAGE_UPLOADS_ENDPOINT;

const mapBeStatusToFe = (
  serverEnum: UploadedInvoiceState,
  uploadError?: UploadError | null,
) => {
  if (uploadError) {
    return InvoicesStatus.Rejected;
  }
  switch (serverEnum) {
    case UploadedInvoiceState.Approved:
      return InvoicesStatus.Processed;
    case UploadedInvoiceState.Error:
    case UploadedInvoiceState.FailedImport:
    case UploadedInvoiceState.Failed:
    case UploadedInvoiceState.Rejected:
    case UploadedInvoiceState.OverTheLimit:
      return InvoicesStatus.Rejected;
    case UploadedInvoiceState.Processed:
      return InvoicesStatus.ReadyToReview;
    case UploadedInvoiceState.Processing:
    case UploadedInvoiceState.Uploaded:
      return InvoicesStatus.Processing;
    default:
      return InvoicesStatus.Rejected;
  }
};

const normalizeInvoices = ({
  data,
}: Pick<InvoiceUploadsQueryResult, 'data'>) => {
  const invoicesWithMeta: InvoiceUploadItems = [];
  const metaKeyedByParentId: Record<
    string,
    Pick<
      InvoiceUpload,
      'emailUploadMeta' | 'manualUploadMeta' | 'uploadMethod' | 'createdAt'
    >
  > = {};
  if (data && data.invoiceUploads.length > 0) {
    data.invoiceUploads.forEach(
      ({
        id,
        createdAt,
        items,
        uploadMethod,
        emailUploadMeta,
        manualUploadMeta,
      }) => {
        if (items.length === 0) {
          return;
        }
        invoicesWithMeta.push(...items);
        metaKeyedByParentId[id] = {
          manualUploadMeta,
          emailUploadMeta,
          uploadMethod,
          createdAt,
        };
      },
    );
  }
  const normalizedInvoices: InvoiceListItemProps[] = [];
  invoicesWithMeta.forEach(
    ({
      id,
      fileName,
      state,
      mimeType,
      invoiceUploadId,
      ocrExpense,
      uploadError,
      key,
    }) => {
      const parentMeta = metaKeyedByParentId[invoiceUploadId];
      if (!parentMeta) {
        // should never happen
        console.error('No metadata for upload found');

        return;
      }
      const { emailUploadMeta, manualUploadMeta, uploadMethod, createdAt } =
        parentMeta;
      const uploadedAt = emailUploadMeta?.receivedAt || createdAt;
      const createdBy = manualUploadMeta
        ? getFullName(manualUploadMeta.createdBy)
        : undefined;
      const uploadedBy = emailUploadMeta?.receivedFrom || createdBy || 'none';
      normalizedInvoices.push({
        id,
        fileName: fileName || '',
        imageURL: key ? `${imageUploadsEndpoint}/${key}` : undefined,
        status: mapBeStatusToFe(state, uploadError),
        uploadMethod: uploadMethod.toString(),
        uploadedAt,
        uploadedBy,
        fileType: mimeType,
        ocrExpenseId: ocrExpense?.id,
      });
    },
  );

  return orderBy(({ uploadedAt }) => uploadedAt, 'desc', normalizedInvoices);
};

type InvoiceUpload = InvoiceUploadsQuery['invoiceUploads'][0];
type InvoiceUploadItems = InvoiceUpload['items'];

const InvoicesPage = () => {
  const { t } = useTranslation();

  const [fetchInvoiceUploads, { data, startPolling }] =
    useInvoiceUploadsLazyQuery();

  useEffect(() => {
    void fetchInvoiceUploads();
    startPolling(10000);
  }, []);

  const sortedInvoices = normalizeInvoices({ data });
  return (
    <>
      <InvoicesToolbar
        title={t('Uploaded invoices')}
        buttonComponent={<UploadInvoice />}
        filtersComponent={<Filters />}
      />
      <PageContainer>
        <InvoiceList invoices={sortedInvoices} />
      </PageContainer>
    </>
  );
};

export default InvoicesPage;
