import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Badge as MuiBadge,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import styled from 'styled-components';
import ToggleableChevron from './ToggleableChevron';

interface DropDownProps {
  handleMenuItemClick: (id: string) => void;
  selectedId: string;
  menuItems: Array<{ id: string; name: string; icon?: React.ReactNode;   badgeNumber?: number; }>;
}

const Badge = styled(MuiBadge)`
  & .MuiBadge-badge {
    min-width: 16px;
    font-size: 10px;
    width: 16px;
    height: 16px;
    top: 16px;
    right: -4px;
    ${({ theme }) => `
      background-color ${theme.palette.error.main};
      color: #FFFFFF;
  `}
  }
`;

const DropDown = ({
  selectedId,
  menuItems,
  handleMenuItemClick,
}: DropDownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onHandleMenuItemClick = (id: string) => {
    setIsOpen(false);
    handleMenuItemClick(id);
  };

  const anchorRef = useRef<HTMLDivElement>(null);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(isOpen);
  useEffect(() => {
    if (anchorRef.current && prevOpen.current && !isOpen) {
      anchorRef.current.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen]);

  const currentMenuItem = menuItems.find(({ id }) => id === selectedId);

  return (
    <>
      <div ref={anchorRef}>
        <Box display="flex" flexDirection="column">
          <Box
            aria-controls={isOpen ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={() => setIsOpen(true)}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="caption">{currentMenuItem?.name}</Typography>
            <Box marginRight={1} />
            <ToggleableChevron open={isOpen} />
          </Box>
        </Box>
      </div>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom"
      >
        {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={{ width: '100vw' }}>
              <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                <MenuList autoFocusItem={isOpen} id="menu-list-grow">
                  {menuItems.map(({ id, name, icon, badgeNumber }, index) => {
                    const menuItem = (
                      <MenuItem
                        key={id || index}
                        selected={id === selectedId}
                        onClick={() => onHandleMenuItemClick(id)}
                      >
                        {icon}
                        <Box mr={2} />
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          minWidth="320px"
                        >
                          {name}
                        </Box>
                      </MenuItem>
                    );
                    return badgeNumber ? (
                      <Badge badgeContent={badgeNumber}>{menuItem}</Badge>
                    ) : (
                      menuItem
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default DropDown;
