import React from 'react';
import {
  Box,
  Button,
  DrawerProps,
  Drawer as MuiDrawer,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import { Divider, FixedBox, SecondaryToolbar } from '../../../ui';

const Drawer = styled(({ ...props }: DrawerProps) => <MuiDrawer {...props} />)`
  ${({ theme }) => `
        & .MuiDrawer-paper {
			width: 100%;
			height: ${theme.toolbar.desktopHeight}px;
            background-color: ${theme.palette.background.paper};
            margin-top: ${theme.toolbar.mobileHeight}px;
            overflow-x: hidden;
    `}
`;

interface InvoicesToolbarProps {
  title: string;
  filtersComponent: React.ReactNode;
  buttonComponent: React.ReactNode;
}

const InvoicesToolbar = (props: InvoicesToolbarProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { title, filtersComponent, buttonComponent } = props;

  return (
    <>
      <SecondaryToolbar>
        <FixedBox isFullWidth display="flex" showBottomBorder showTopBorder>
          <Box
            width="100%"
            sx={{
              padding: '8px 16px',
            }}
            display="flex"
            alignItems="center"
          >
            <Typography variant="subtitle1">{title}</Typography>
            <Box mr={2} />
            <Divider isFullHeight />
            <Box mr={2} />
            {filtersComponent}
            {isDesktop ? (
              <>
                <Box mr={2} />
                <Divider isFullHeight />
                <Box mr={2} />
                {buttonComponent}
              </>
            ) : null}
          </Box>
        </FixedBox>
      </SecondaryToolbar>
      {!isDesktop ? (
        <Drawer anchor="bottom" variant="permanent">
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            height="100%"
            padding="0 24px"
          >
            {buttonComponent}
          </Box>
        </Drawer>
      ) : null}
    </>
  );
};

export default InvoicesToolbar;
