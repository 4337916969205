import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useLocalizationContext } from '@mui/x-date-pickers/internals/hooks/useUtils';
import { DateTime, Info } from 'luxon';
import {
  Box,
  BoxProps,
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Dialog,
  Grid,
  Grow,
  IconButton,
  Paper,
  Popper,
  Tooltip,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { InternalRefetchQueriesInclude } from '@apollo/client';
import styled from 'styled-components';
import { useDateTimeService } from '../../../../providers/date-time-service.provider';
import { useUser } from '../../../../providers/current-user.provider';
import { ExpenseType } from '../../../../enums';
import { AllExpensesForm } from '../allExpenses';
import {
  OneTimeExpenseCard,
  RecurringExpenseCard,
  SupplyExpenseCard,
} from '../..';
import { LaborExpenseItem } from '../labor/components/LaborExpenses/LaborExpenseCard/LaborExpenseItem';
import { LaborExpense } from '../labor/components/types';

interface LaborExpenseCalendarData extends Omit<CalendarExpense, 'expense'> {
  expense: LaborExpense;
}

const laborExpenseTypeGuard = (
  data: CalendarExpense,
): data is LaborExpenseCalendarData =>
  !!(data as LaborExpenseCalendarData).expense;

export interface CalendarExpense {
  date: Date;
  dueDate: string;
  purchaseDate: string;
  supplier?: string;
  category: string;
  id: string;
  type: ExpenseType;
  amount: string;
  name: string;
  paymentId?: string | null;
  isPaid?: boolean;
  recurrencePaymentDate?: Date;
  expense?: LaborExpense;
  link?: string | null;
}

interface CalendarProps {
  currentDate: Date;
  expenses: Array<CalendarExpense>;
  refetchQueries: InternalRefetchQueriesInclude;
}

const getBackgroundColorByExpenseType = (expenseType: ExpenseType) => {
  switch (expenseType) {
    case ExpenseType.Labor:
      return '#5C61D7';
    case ExpenseType.OneTime:
      return '#1DAE93';

    case ExpenseType.Recurring:
      return '#DC6FEE';

    case ExpenseType.Supplies:
      return '#1991FF';

    default:
      return '#5C61D7';
  }
};

export const StyledCalendarExpenseCard = styled(
  ({
    expenseType,
    ...props
  }: {
    expenseType: ExpenseType;
    // eslint-disable-next-line react/jsx-props-no-spreading
  } & BoxProps) => <Box {...props} />,
)`
  cursor: pointer;
  padding-left: 8px;
  padding-right: 4px;
  border-radius: 3px;
  overflow: hidden;
  whitespace: nowrap;
  textoverflow: ellipsis;
  background-color: ${({ expenseType }) =>
    getBackgroundColorByExpenseType(expenseType)};

  &:hover {
    background-color: ${({ expenseType }) =>
      alpha(getBackgroundColorByExpenseType(expenseType), 0.8)};
  }
`;

interface CalendarExpenseCardProps {
  date: DateTime;
  expensesMap: Record<string, Array<CalendarExpense>>;
  refetchQueries: InternalRefetchQueriesInclude;
}

const CalendarExpenseCard = ({
  date,
  expensesMap,
  refetchQueries,
}: CalendarExpenseCardProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentExpenseId, setCurrentExpenseId] = useState<string | null>(null);
  const [currentExpenseCard, setCurrentExpenseCard] = useState<ReactNode>(null);

  const anchorRef = useRef<HTMLDivElement>(null);
  const key = `${date.get('day')}-${date.get('month')}`;
  const expenses = expensesMap[key];

  const prevOpen = useRef(isOpen);
  useEffect(() => {
    if (anchorRef.current && prevOpen.current && !isOpen) {
      anchorRef.current.focus();
    }

    prevOpen.current = isOpen;
  }, [isOpen]);

  const handleClose = () => {
    setIsOpen(false);
    setCurrentExpenseId(null);
    setCurrentExpenseCard(null);
  };

  useEffect(() => {
    const currentExpenseToShow = expenses?.find(
      ({ id }) => id === currentExpenseId,
    );

    if (!currentExpenseToShow) {
      handleClose();
      return;
    }

    const {
      id,
      type,
      amount,
      category,
      dueDate,
      paymentId,
      purchaseDate,
      supplier,
      name,
      date: paymentDay,
      isPaid,
      link,
    } = currentExpenseToShow;

    if (type === ExpenseType.Supplies) {
      setCurrentExpenseCard(
        <SupplyExpenseCard
          id={id}
          isSmall
          amount={amount}
          category={category}
          paymentDay={dueDate}
          paymentId={paymentId}
          purchaseDate={purchaseDate}
          supplier={supplier}
          refetchQueries={refetchQueries}
          link={link}
        />,
      );
    }

    if (type === ExpenseType.Recurring) {
      setCurrentExpenseCard(
        <RecurringExpenseCard
          id={id}
          isSmall
          amount={amount}
          category={category}
          supplier={supplier}
          isPaid={isPaid}
          localizedPaymentDay={dueDate}
          refetchQueries={refetchQueries}
          name={name}
          paymentDay={paymentDay}
          recurrence={t('Monthly')}
        />,
      );
    }

    if (type === ExpenseType.OneTime) {
      setCurrentExpenseCard(
        <OneTimeExpenseCard
          id={id}
          isSmall
          name={name}
          amount={amount}
          category={category}
          paymentDay={dueDate}
          paymentId={paymentId}
          purchaseDate={purchaseDate}
          supplier={supplier}
          link={link}
          refetchQueries={refetchQueries}
        />,
      );
    }
    if (
      type === ExpenseType.Labor &&
      laborExpenseTypeGuard(currentExpenseToShow)
    ) {
      setCurrentExpenseCard(
        <LaborExpenseItem
          isMobile
          expense={currentExpenseToShow.expense}
          refetchQueries={refetchQueries}
        />,
      );
    }
  }, [currentExpenseId, expenses]);

  if (expenses && expenses.length > 0) {
    return (
      <div ref={anchorRef}>
        {isDesktop ? (
          <Popper
            sx={{
              zIndex: '1290',
            }}
            open={isOpen}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            // disablePortal
            placement="auto"
          >
            {/* eslint-disable-next-line @typescript-eslint/naming-convention */}
            {({ TransitionProps }) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <Grow {...TransitionProps}>
                <Paper sx={{ width: '430px' }}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <Box>{currentExpenseCard}</Box>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        ) : (
          <Dialog
            fullWidth
            fullScreen
            scroll="body"
            open={isOpen}
            onClose={handleClose}
          >
            <Box display="flex" flexDirection="column">
              {currentExpenseCard}
              <Box padding={3} display="flex" justifyContent="flex-end">
                <Button onClick={handleClose} variant="contained" color="error">
                  {t('Back')}
                </Button>
              </Box>
            </Box>
          </Dialog>
        )}
        {expenses.map(({ id, amount, name, type }) => {
          return (
            <StyledCalendarExpenseCard
              expenseType={type}
              onClick={() => {
                setIsOpen(true);
                setCurrentExpenseId(id);
              }}
              display="flex"
              mb="4px"
              key={id}
            >
              <Typography
                variant="body2"
                fontSize="10px"
                fontWeight="500"
                color="#FAFBFF"
              >
                {amount}
              </Typography>
              <Typography variant="body2" fontSize="10px" color="#FAFBFF">
                &nbsp;{`${name}`}
              </Typography>
            </StyledCalendarExpenseCard>
          );
        })}
      </div>
    );
  }

  return null;
};

const ExpenseCalendarView = ({
  currentDate,
  expenses,
  refetchQueries,
}: CalendarProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [pickedExpenseDate, setPickedExpenseDate] = useState<DateTime>();

  const { t } = useTranslation();
  const { dateTimeService } = useDateTimeService();
  const { user } = useUser();

  const { utils } = useLocalizationContext<DateTime>();
  const weekDays = Info.weekdaysFormat('short', { locale: user.cultureCode });
  const weeksArray = utils.getWeekArray(
    dateTimeService.getDateTimeFromJsDate(currentDate),
  );

  const expensesMap: Record<string, Array<CalendarExpense>> = {};
  expenses.forEach(({ date, ...rest }) => {
    const key = `${date.getDate()}-${date.getMonth() + 1}`;

    if (!expensesMap[key]) {
      expensesMap[key] = [];
    }

    expensesMap[key].push({
      ...rest,
      date,
    });
  });

  return (
    <>
      <Card>
        <CardContent>
          <Grid container display="flex">
            {isDesktop
              ? weekDays.map((wd) => (
                  <Grid item md={1.7} xs={12} key={wd}>
                    {wd}
                  </Grid>
                ))
              : null}
          </Grid>
          {weeksArray.map((w, i) => (
            <Grid container key={i}>
              {w.map((day) => {
                const isToday = day.hasSame(DateTime.local(), 'day');
                const borderColor = isToday ? '#FF8F6B' : theme.palette.divider;

                return (
                  <Grid
                    md={1.7}
                    xs={12}
                    item
                    style={{
                      minHeight: '100px',
                      border: `1px solid ${borderColor}`,
                    }}
                  >
                    <Box display="flex" flexDirection="column" padding={1}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        {isToday ? (
                          <Typography
                            fontWeight="bold"
                            color="#FF8F6B"
                            variant="caption"
                          >
                            {day.get('day')}
                          </Typography>
                        ) : (
                          <Typography variant="caption">
                            {day.get('day')}
                          </Typography>
                        )}
                        <Tooltip title={t('Add expense')}>
                          <IconButton
                            onClick={() => {
                              setPickedExpenseDate(day);
                              setIsDialogOpen(true);
                            }}
                            color="primary"
                            size="small"
                            aria-label="add-expense"
                            component="span"
                          >
                            <AddIcon sx={{ fontSize: '20px' }} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <CalendarExpenseCard
                        refetchQueries={refetchQueries}
                        date={day}
                        expensesMap={expensesMap}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          ))}
        </CardContent>
      </Card>
      <AllExpensesForm
        refetchQueries={refetchQueries}
        isControlledDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        expenseDate={pickedExpenseDate}
      />
    </>
  );
};

export default ExpenseCalendarView;
