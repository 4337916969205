import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Box, Button, InputAdornment, MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import { orderBy } from 'lodash/fp';
import { DatePickerField, SelectField, TextField } from '../../../ui';
import { useUser } from '../../../providers/current-user.provider';
import { useMoneyService } from '../../../providers/money-service.provider';
import { translateAndCastToString } from '../../../utils/translate';
import {
  refetchRevenueSourcesQuery,
  useCreateRevenueSourceMutation,
} from '../../../generated/graphql';

const validationSchema = yup.object({
  sourceId: yup
    .string()
    .required(translateAndCastToString('Source is required')),
  amount: yup
    .number()
    .required(translateAndCastToString('Amount is required'))
    .min(0, translateAndCastToString('Amount cannot be negative'))
    .max(
      1000000,
      translateAndCastToString('Amount cannot be larger than 1000000'),
    ),
  revenueDate: yup
    .date()
    .required(translateAndCastToString('Due date is required')),
});

interface RevenueFormProps {
  sources: Array<{ value: string; label: string }>;
  onSubmit: (values: RevenueFormValues) => void;
  initialData?: Partial<RevenueFormValues>;
  buttonName: string;
}

export interface RevenueFormValues {
  amount: number | '';
  revenueDate: DateTime | null;
  sourceId: string;
}

const RevenueForm = ({
  sources,
  onSubmit,
  initialData,
  buttonName,
}: RevenueFormProps) => {
  const [createNewRevenueSource, { loading }] = useCreateRevenueSourceMutation({
    refetchQueries: [refetchRevenueSourcesQuery()],
  });
  const { t } = useTranslation();
  const { moneyService } = useMoneyService();
  const { user } = useUser();

  const orderedSources = orderBy('label', 'asc', sources);

  const formik = useFormik<RevenueFormValues>({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      amount: '',
      revenueDate: DateTime.now(),
      sourceId: '',
      ...initialData,
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <TextField
        fullWidth
        autoFocus
        required
        id="amount"
        name="amount"
        label={t('Amount')}
        value={formik.values.amount}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.amount && Boolean(formik.errors.amount)}
        helperText={formik.touched.amount && formik.errors.amount}
        InputProps={{
          type: 'number',
          endAdornment: (
            <InputAdornment position="end">
              {/* getCurrency doesn't localize it... We need another way */}
              {moneyService.getCurrency({
                currency: user.currency,
                locale: user.languageCode,
              })}
            </InputAdornment>
          ),
        }}
      />
      <SelectField
        fullWidth
        select
        required
        onCreateNewMutation={async (name: string) => {
          try {
            const result = await createNewRevenueSource({
              variables: {
                name,
              },
              toastConfig: {
                successText: t(
                  `New revenue source {{name}} was successfully created`,
                  {
                    name,
                  },
                ),
              },
            });

            if (result?.data) {
              await formik.setFieldValue(
                'sourceId',
                result.data.createRevenueSource.id,
              );
            }
          } catch (e) {
            // Nothing we can do
          }
        }}
        addNewText={t('New source of revenue')}
        id="sourceId"
        name="sourceId"
        disabled={loading}
        label={t('Source')}
        value={formik.values.sourceId}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.sourceId && Boolean(formik.errors.sourceId)}
        helperText={formik.touched.sourceId && formik.errors.sourceId}
        options={orderedSources}
      />
      <DatePickerField
        name="revenueDate"
        label={t('Revenue date')}
        required
        value={formik.values.revenueDate}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.revenueDate && Boolean(formik.errors.revenueDate)}
        helperText={formik.touched.revenueDate && formik.errors.revenueDate}
      />
      <Box mt={3} display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          color="primary"
          variant="contained"
          type="submit"
          disabled={loading}
        >
          {buttonName}
        </Button>
      </Box>
    </form>
  );
};

export default RevenueForm;
