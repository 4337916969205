import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  from,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { CssBaseline, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { captureException } from '@sentry/react';
import './index.css';
import { IndexPage } from './modules/index-page';
import { Providers } from './providers';
import theme from './theme';

let host: string;
let httpProtocol: string;
const env = import.meta.env.VITE_HOST_ENV;

switch (env) {
  case 'demo':
    host = 'demo-api.dishboard.app/graphql';
    httpProtocol = 'https://';
    break;
  case 'prod':
    host = 'api.dishboard.app/graphql';
    httpProtocol = 'https://';
    break;
  case 'development':
  default:
    host = 'localhost:4000/graphql';
    httpProtocol = 'http://';
}

const uri = `${httpProtocol}${host}`;

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, response }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, extensions, stack }) => {
        // We don't want to show Unauth errors, so we will not spam user
        // They are handled in auth.provider
        // @ts-ignore
        if (extensions?.response?.statusCode !== 401) {
          const extra = {
            operationName: operation.operationName,
            variables: JSON.stringify(operation.variables),
            stack,
            response: JSON.stringify(response),
          };

          captureException(new Error(message), {
            tags: { source: 'graphql-error-link' },
            extra,
          });

          toast(message, { type: 'error', theme: 'colored' });
        }
      });
    if (networkError) console.error(`[Network error]: ${networkError}`);
  },
);

const link = from([
  errorLink,
  createUploadLink({ uri, credentials: 'include' }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-and-network',
    },
  },
});

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <CssBaseline />
          <ToastContainer />
          <Router>
            <Providers>
              <IndexPage />
            </Providers>
          </Router>
        </ApolloProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;
