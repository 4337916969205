import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useAuth } from '../../providers/auth.provider';
import { CurrentUserProvider } from '../../providers/current-user.provider';
import DateLocalizationprovider from '../../providers/DateLocalizationProvider';
import { MoneyServiceProvider } from '../../providers/money-service.provider';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const RedirectRoute = () => {
  const [count, setCount] = useState(3);
  useEffect(() => {
    const interval = setInterval(() => {
      if (count <= 0) {
        return;
      }
      setCount(count - 1);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  });
  setTimeout(() => {
    window.location.replace('https://dishboard.app');
  }, 3000);
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="100%"
      flexDirection="column"
    >
      <Typography variant="h3">
        Dishboard app has moved to{' '}
        <a href="https://dishboard.app">https://dishboard.app</a>
      </Typography>
      <Typography>You will be redirected in {count}</Typography>
    </Box>
  );
};

export const IndexPage = () => {
  const { logout, user } = useAuth();
  const { t } = useTranslation();

  if (user) {
    return (
      <CurrentUserProvider user={user} logout={logout}>
        <MoneyServiceProvider>
          <DateLocalizationprovider>
            <SentryRoutes>
              <Route path="*" element={<RedirectRoute />} />
            </SentryRoutes>
          </DateLocalizationprovider>
        </MoneyServiceProvider>
      </CurrentUserProvider>
    );
  }

  return (
    <Routes>
      <Route path="*" element={<RedirectRoute />} />
    </Routes>
  );
};
