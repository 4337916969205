import Dinero from 'dinero.js';
import { Currency } from '../../generated/graphql';
import { countFractionDigits } from './helpers';

class MoneyService {
  private locale: string;

  constructor({ locale }: { locale: string }) {
    this.locale = locale;
  }

  public assertInteger(int: number, intDescription: string) {
    if (!Number.isInteger(int)) {
      throw Error(`${intDescription} should be int number, received ${int}`);
    }
  }

  public add(amount: number, addendent: number, currency: Currency): number {
    const { currencyCode, precision } = currency;
    this.assertInteger(addendent, 'Price to sum with');
    this.assertInteger(amount, 'Price');

    const currentPrice = Dinero({ amount, currency: currencyCode, precision });
    const calculatedPrice = currentPrice.add(
      Dinero({ amount: addendent, currency: currencyCode, precision }),
    );

    return calculatedPrice.getAmount();
  }

  public dividePrices(
    nominator: number,
    divider: number,
    { currencyCode, precision }: Currency,
    targetPrecision = precision,
  ) {
    this.assertInteger(nominator, 'Nominator');
    this.assertInteger(divider, 'Divider');
    const dividerAsUnit = Dinero({
      amount: divider,
      currency: currencyCode,
      precision,
    }).toUnit();
    return Dinero({ amount: nominator, currency: currencyCode, precision })
      .convertPrecision(Math.max(precision, targetPrecision) * 2)
      .divide(dividerAsUnit, 'HALF_AWAY_FROM_ZERO')
      .convertPrecision(targetPrecision)
      .toUnit();
  }

  public toInteger(price: number, currency: Currency): number {
    const mockPrice = Dinero({ amount: 0, currency: currency.currencyCode });
    const precision = mockPrice.getPrecision();

    return Dinero({ amount: 10 ** precision, precision })
      .multiply(price)
      .getAmount();
  }

  public toUnit(amount: number, currency: Currency): number {
    this.assertInteger(amount, 'Amount');
    return Dinero({ amount, currency: currency.currencyCode }).toUnit();
  }

  public toLocaleString(amount: number, currency: Currency) {
    /* This is needed to overcome -0 value being localized */
    const finalAmount = Object.is(amount, -0) ? -amount : amount;
    this.assertInteger(finalAmount, 'Money');

    const { currencyCode, precision } = currency;

    return Dinero({
      amount: finalAmount,
      currency: currencyCode,
      precision,
    })
      .setLocale(this.locale)
      .toFormat();
  }

  public getCurrency({
    locale,
    currency,
  }: {
    locale: string;
    currency: Currency;
  }) {
    return Dinero({ currency: currency.currencyCode })
      .setLocale(locale)
      .getCurrency();
  }

  public fractionToPercent(fraction: number, precision = 2) {
    const fractionPrecision = countFractionDigits(fraction);
    return Dinero({
      amount: 10 ** fractionPrecision,
      precision: fractionPrecision,
    })
      .multiply(fraction)
      .multiply(100)
      .convertPrecision(precision)
      .toUnit();
  }
}

export default MoneyService;
