export enum Color {
  Blue,
  Green,
  LightBlue,
  Lemon,
  Orange,
  Grey,
  Purple,
  Red,
  Brown,
  DeepOrange,
  Amber,
  Cyan,
  Yellow,
  DarkRed,
  Pink,
  DarkPurple,
  DarkGreen,
  Tertiary,
}
