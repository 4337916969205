import { SchemaOf, mixed, object, ref, string } from 'yup';
import i18n from '../../../../intl';
import { CultureCode } from '../../../../generated/graphql';
import { translateAndCastToString } from '../../../../utils/translate';
import { ProfileFormValues } from './types';

export const profileFormValidation: SchemaOf<ProfileFormValues> =
  object().shape({
    firstName: string().required('First name is required'),
    lastName: string().required('Last name is required'),
    cultureCode: mixed<CultureCode>().oneOf(Object.values(CultureCode)),
    languageCode: mixed<CultureCode>().oneOf(Object.values(CultureCode)),
    password: string()
      .min(6, ({ min }) =>
        i18n.t('form.minLengthError{{count}}', { count: min }),
      )
      .max(50, ({ max }) =>
        i18n.t('form.maxLengthError{{count}}', { count: max }),
      )
      .oneOf(
        [ref('repeatPassword'), null],
        translateAndCastToString('form.mustMatchError'),
      ),
    repeatPassword: string()
      .oneOf(
        [ref('password'), null],
        translateAndCastToString('form.mustMatchError'),
      )
      .when('password', {
        is: (password: string) => password && password.length > 0,
        then: string().required(translateAndCastToString('form.emptyError')),
      }),
    defaultUserId: string(),
  });
