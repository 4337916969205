import { DateTime } from 'luxon';

class DateTimeService {
  private assertDate(date: string | number | Date) {
    if (typeof date !== 'number') {
      if (Number.isNaN(Date.parse(date.toString())) === true) {
        throw new Error(`Invalid date provided, ${JSON.stringify(date)}`);
      }
    }
  }

  public addMonth(date: Date) {
    this.assertDate(date);
    const dateTime = this.getDateTimeFromJsDate(date);
    return dateTime.plus({ month: 1 }).toJSDate();
  }

  public deductMonth(
    date: Date,
    { numberOfMonths = 1 }: { numberOfMonths?: number } = {},
  ) {
    this.assertDate(date);
    const dateTime = this.getDateTimeFromJsDate(date);
    return dateTime.minus({ month: numberOfMonths }).toJSDate();
  }

  public getDateTimeFromJsDate(date: Date) {
    return DateTime.fromJSDate(date);
  }

  public getStartOfTheMonthJsDate = (date: Date) => {
    const dateTime = this.getDateTimeFromJsDate(date);
    return dateTime.startOf('month').toJSDate();
  };

  public getEndOfTheDay = (date: Date) => {
    const dateTime = this.getDateTimeFromJsDate(date);
    return dateTime.endOf('day').toJSDate();
  };

  public getEndOfTheMonthJsDate = (date: Date) => {
    const dateTime = this.getDateTimeFromJsDate(date);
    return dateTime.endOf('month').toJSDate();
  };

  /* Localization */

  public localizeMonthAndYear(date: string | number | Date, locale: string) {
    this.assertDate(date);
    return new Date(date).toLocaleString(locale, {
      month: 'long',
      year: 'numeric',
    });
  }

  public localizeShortMonthAndYear(
    date: string | number | Date,
    locale: string,
  ) {
    this.assertDate(date);
    return new Date(date).toLocaleString(locale, {
      month: 'numeric',
      year: 'numeric',
    });
  }

  public localizeShortDateTime(date: string | number | Date, locale: string) {
    this.assertDate(date);
    return new Date(date).toLocaleString(locale, {
      month: 'short',
      day: 'numeric',
    });
  }

  public localizeShortMonthAndTime(
    date: string | number | Date,
    locale: string,
  ) {
    this.assertDate(date);
    return new Date(date).toLocaleString(locale, {
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }
}

export default DateTimeService;
