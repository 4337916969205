import * as yup from 'yup';
import { translateAndCastToString } from '../../../../utils/translate';

export const validationSchema = yup.object({
  name: yup
    .string()
    .required(translateAndCastToString('Name is required'))
    .min(
      2,
      translateAndCastToString('Name should be of minimum 2 characters length'),
    ),
  categoryId: yup
    .string()
    .required(translateAndCastToString('Category is required')),
  supplierId: yup.string(),
  amount: yup
    .number()
    .required(translateAndCastToString('Amount is required'))
    .min(0, translateAndCastToString('Amount cannot be negative'))
    .max(
      1000000,
      translateAndCastToString('Amount cannot be larger than 1000000'),
    ),
  dueDate: yup
    .date()
    .required(translateAndCastToString('Due date is required')),
  purchaseDate: yup
    .date()
    .required(translateAndCastToString('Purchase date is required')),
  link: yup.string().nullable(),
});
