import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  StandardTextFieldProps,
} from '@mui/material';
import * as yup from 'yup';
import React, { useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { translateAndCastToString } from '../utils/translate';
import TextField from './TextField';

interface SelectFieldProps extends StandardTextFieldProps {
  options: Array<{ value: string; label: string }>;
  onCreateNewMutation?: (name: string) => Promise<void>;
  addNewText?: string;
}

const validationSchema = yup.object({
  name: yup
    .string()
    .required(translateAndCastToString('Name is required'))
    .min(3, translateAndCastToString('Name should have at lest 3 characters'))
    .max(50, translateAndCastToString('Name can have 50 characters max')),
});

interface SelectFieldValues {
  name: string;
}

const SelectField = ({
  id,
  name,
  required,
  label,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  options,
  addNewText,
  disabled,
  onCreateNewMutation,
}: SelectFieldProps) => {
  const [isNewFieldActive, setIsNewFieldActive] = useState(false);
  const { t } = useTranslation();

  const formik = useFormik<SelectFieldValues>({
    initialValues: {
      name: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      if (onCreateNewMutation) {
        try {
          await onCreateNewMutation(values.name);
        } catch (e) {
          setIsNewFieldActive(false);
        } finally {
          setIsNewFieldActive(false);
        }
      }
    },
  });

  return isNewFieldActive ? (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <Box display="flex" alignItems="center">
        <TextField
          autoFocus
          required={required}
          disabled={disabled}
          id="name"
          name="name"
          label={label}
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setIsNewFieldActive(false)}
                  color="error"
                  aria-label="close-dialog"
                  component="span"
                >
                  <CloseRoundedIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box mr={2} />
        <Button
          color="success"
          variant="contained"
          disabled={disabled}
          onClick={formik.submitForm}
        >
          {t('Create')}
        </Button>
      </Box>
    </form>
  ) : (
    <TextField
      fullWidth
      select
      required={required}
      disabled={disabled}
      id={id}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
    >
      {options.map(({ value: optionValue, label: optionLabel }) => (
        <MenuItem key={optionValue} value={optionValue}>
          {optionLabel}
        </MenuItem>
      ))}
      {onCreateNewMutation ? (
        <MenuItem
          sx={{ borderTop: '2px solid #DDE6FD;' }}
          onClick={() => setIsNewFieldActive(true)}
        >
          <AddCircleOutlineIcon
            sx={{
              borderRadius: '50%',
              background: '#554DF029',
              color: '#554DF0',
            }}
          />
          <Box mr={2} />
          {addNewText || t('New category')}
        </MenuItem>
      ) : null}
    </TextField>
  );
};

export default SelectField;
