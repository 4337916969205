import { DrawerProps, Drawer as MuiDrawer } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

export const Drawer = styled(
  ({ width, ...props }: { width?: number } & DrawerProps) => (
    <MuiDrawer {...props} />
  ),
)`
  flex-shrink: 0;
  overflow-x: hidden;
  white-space: nowrap;
  position: absolute;
  ${({ theme, width }) => `
        & .MuiDrawer-paper {
            background-color: ${theme.background};
            margin-top: ${theme.toolbar.mobileHeight}px;
            width: ${width || theme.drawer.width}px;
            overflow-x: hidden;
            ${
              theme.direction === 'ltr'
                ? `left: -${theme.drawer.width}px;`
                : `right: ${theme.drawer.width}px;`
            }
            ${theme.breakpoints.up('sm')} {
                margin-top: ${theme.toolbar.desktopHeight}px;
            }
            ${theme.breakpoints.up('md')} {
                margin-top: ${theme.toolbar.desktopHeight}px;
                ${theme.direction === 'ltr' ? `left: 0;` : `right: 0;`}
                width: ${theme.drawer.width}px;
            }
        }
    `}
`;
