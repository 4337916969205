import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';

interface Filters {
  showForReview: boolean;
  showReviewed: boolean;
  showRejected: boolean;
  showInProcess: boolean;
}

interface Numbers {
  numberForReview: number;
  numberInProcess: number;
}

interface ContextProps {
  showForReview: boolean;
  showReviewed: boolean;
  showRejected: boolean;
  showInProcess: boolean;
  numberForReview: number;
  numberInProcess: number;
  setFilters: (filters: Partial<Filters>) => void;
  setNumbers: (numbers: Partial<Numbers>) => void;
}

const defaultState = {
  showForReview: false,
  showReviewed: false,
  showRejected: false,
  showInProcess: false,
  numberForReview: 0,
  numberInProcess: 0,
  setFilters: () => {},
  setNumbers: () => {},
};

const FiltersContext = createContext<ContextProps>(defaultState);

const FilterContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const context = useInvoicesFiltersInitialContext();

  return (
    <FiltersContext.Provider value={context}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useInvoicesFilterContext = () => {
  return useContext(FiltersContext);
};

function useInvoicesFiltersInitialContext() {
  const [currentFilters, setFiltersState] = useState({
    showForReview: true,
    showReviewed: false,
    showRejected: false,
    showInProcess: false,
  });

  const [currentNumbers, setNumbersState] = useState({
    numberForReview: 0,
    numberInProcess: 0,
  });

  const setFilters = (filters: Partial<Filters> = {}) => {
    setFiltersState({
      showForReview: false,
      showReviewed: false,
      showRejected: false,
      showInProcess: false,
      ...filters,
    });
  };

  const setNumbers = (numbers: Partial<Numbers> = {}) => {
    setNumbersState({
      ...currentNumbers,
      ...numbers,
    });
  };

  return {
    ...currentFilters,
    ...currentNumbers,
    setFilters,
    setNumbers,
  };
}

export const InvoicesFiltersProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  return <FilterContextProvider>{children}</FilterContextProvider>;
};
