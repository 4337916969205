import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from 'react';

interface ContextProps {
  showUnpaid: boolean;
  showPaid: boolean;
  setPaidFilter: (value: boolean) => void;
  setUnpaidFilter: (value: boolean) => void;
}

const defaultState = {
  setPaidFilter: () => {},
  setUnpaidFilter: () => {},
  showUnpaid: true,
  showPaid: false,
};

const FiltersContext = createContext<ContextProps>(defaultState);

const FilterContextProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const context = useCalendarFiltersInitialContext();

  return (
    <FiltersContext.Provider value={context}>
      {children}
    </FiltersContext.Provider>
  );
};

export const useCalendarFilterContext = () => {
  return useContext(FiltersContext);
};

function useCalendarFiltersInitialContext() {
  const [currentFilters, setFilters] = useState({
    showUnpaid: true,
    showPaid: false,
  });

  const setUnpaidFilterState = (value: boolean) => {
    setFilters({
      ...currentFilters,
      showUnpaid: value,
    });
  };

  const setPaidFilterState = (value: boolean) => {
    setFilters({
      ...currentFilters,
      showPaid: value,
    });
  };

  const setUnpaidFilter = (value: boolean) => {
    // Not letting to have 0 filters
    if (currentFilters.showUnpaid && !currentFilters.showPaid) {
      return;
    }

    setUnpaidFilterState(value);
  };

  const setPaidFilter = (value: boolean) => {
    // Not letting to have 0 filters
    if (currentFilters.showPaid && !currentFilters.showUnpaid) {
      return;
    }

    setPaidFilterState(value);
  };

  return {
    setPaidFilter,
    setUnpaidFilter,
    showUnpaid: currentFilters.showUnpaid,
    showPaid: currentFilters.showPaid,
  };
}

export const CalendarFiltersProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  return <FilterContextProvider>{children}</FilterContextProvider>;
};
