import { date, number, object, string } from 'yup';
import { translateAndCastToString } from '../../../../../../utils/translate';

export const oneTimeExpenseValidationSchema = object({
  note: string()
    .min(
      2,
      translateAndCastToString(
        'Invoice number should be of minimum 2 characters length',
      ),
    )
    .nullable(),
  employeeId: string().required(
    translateAndCastToString('Employee is required'),
  ),
  amount: number()
    .required(translateAndCastToString('Amount is required'))
    .min(0, translateAndCastToString('Amount cannot be negative'))
    .max(
      1000000,
      translateAndCastToString('Amount cannot be larger than 1000000'),
    ),
  dueDate: date().required(
    translateAndCastToString('Payment date is required'),
  ),
  accountingDate: date().required(
    translateAndCastToString('Workday date is required'),
  ),
});

export const repeatingExpenseValidationSchema = object({
  note: string()
    .min(
      2,
      translateAndCastToString('Name should be of minimum 2 characters length'),
    )
    .nullable(),
  employeeId: string().required(
    translateAndCastToString('Employee is required'),
  ),
  amount: number()
    .required(translateAndCastToString('Amount is required'))
    .min(0, translateAndCastToString('Amount cannot be negative'))
    .max(
      1000000,
      translateAndCastToString('Amount cannot be larger than 1000000'),
    ),
  firstPaymentDate: date().required(
    translateAndCastToString('First payment date is required'),
  ),
});
