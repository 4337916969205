import { CurrencyCode } from '../../generated/graphql';

export const countFractionDigits = (number = 0) => {
  const stringRepresentation = number.toString();
  if (stringRepresentation.indexOf('e-') > 0) {
    // It's too small for a normal string representation, e.g. 1e-7 instead of 0.00000001
    return parseInt(stringRepresentation.split('e-')[1], 10);
  }
  const fractionDigits = stringRepresentation.split('.')[1];
  return fractionDigits ? fractionDigits.length : 0;
};

export const getCurrencySymbol = ({
  locale,
  currency,
}: {
  locale: string;
  currency: CurrencyCode;
}) =>
  (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
