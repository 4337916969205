import { Tab as MuiTab } from '@mui/material';
import styled from 'styled-components';

const getTabBackgroundColor = (color?: string) => {
  if (color === 'success') {
    return '#97EDDD99';
  }

  if (color === 'pink') {
    return '#FFBEE999';
  }

  if (color === 'lightBlue') {
    return '#D0E5FF99';
  }
  return '#B2AEF899';
};

const getTabTextColor = (color?: string) => {
  if (color === 'success') {
    return '#1DAEA6';
  }

  if (color === 'pink') {
    return '#DC6FEE';
  }

  if (color === 'lightBlue') {
    return '#1991FF';
  }

  return '#5C61D7';
};

const Tab = styled(MuiTab)`
  font-family: 'GT Walsheim Pro';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-transform: none;
  display: flex;
  justify-content: center;

  min-height: 40px;
  height: 40px;
  border-radius: 24px;
  padding: 8px 24px;
  background: ${(props) => getTabBackgroundColor(props.color)};
  border: 2px solid transparent;
  color: ${(props) => getTabTextColor(props.color)};

  &.Mui-selected {
    color: ${(props) => getTabTextColor(props.color)};
    border: 2px solid ${(props) => getTabTextColor(props.color)};
  }
`;

export default Tab;
