import React, { PropsWithChildren, createContext, useContext } from 'react';
import { MoneyService } from '../services';
import { useUser } from './current-user.provider';

interface ContextProps {
  moneyService: MoneyService;
}

const defaultState = {
  moneyService: new MoneyService({ locale: 'cs-CZ' }),
};

const MoneyServiceContext = createContext<ContextProps>(defaultState);

export const MoneyServiceProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const moneyService = useProvideMoneyService();

  return (
    <MoneyServiceContext.Provider value={moneyService}>
      {children}
    </MoneyServiceContext.Provider>
  );
};

export const useMoneyService = () => {
  return useContext(MoneyServiceContext);
};

function useProvideMoneyService() {
  const { user } = useUser();

  const moneyService = new MoneyService({ locale: user.cultureCode });

  return {
    moneyService,
  };
}
