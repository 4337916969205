import {
  ListItemIcon as MuiListItemIcon,
  ListItemIconProps as MuiListItemIconProps,
  Theme,
  alpha,
} from '@mui/material';
import styled from 'styled-components';

interface ListItemIconProps extends MuiListItemIconProps {
  inactiveColor?: boolean;
  isActive?: boolean;
}

const CircleIcon = styled.div<{ isActive?: boolean }>`
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme, isActive }) => `
		width: 36px;
		height: 36px;
        background-color: ${
          isActive ? alpha(theme.palette.icon.highlighted, 0.16) : 'inherit'
        };
	`}
`;

const getIconColor = (
  theme: Theme,
  { isActive, inactiveColor }: { isActive?: boolean; inactiveColor?: boolean },
) => {
  if (!isActive) {
    return inactiveColor
      ? theme.palette.icon.notActive
      : theme.palette.common.black;
  }

  return isActive ? theme.palette.icon.highlighted : theme.palette.common.black;
};

const StyledListItemIcon = styled(
  ({ inactiveColor, isActive, ...props }: ListItemIconProps) => (
    <MuiListItemIcon {...props} />
  ),
)`
  display: flex;
  justify-content: center;
  ${({ theme, inactiveColor, isActive }) => `
        min-width: 40px;
        && {
            color: ${getIconColor(theme, { isActive, inactiveColor })};
        }
    `}
`;

const ListItemIcon = ({ isActive, ...other }: ListItemIconProps) => (
  <CircleIcon isActive={isActive}>
    <StyledListItemIcon isActive={isActive} {...other} />
  </CircleIcon>
);

export default ListItemIcon;
