import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Drawer,
  Badge as MuiBadge,
  Typography,
  useMediaQuery,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { useInvoicesFilterContext } from './FilterProvider';

interface ChipProps {
  isActive?: boolean;
  fullWidth?: boolean;
}

const Badge = styled(MuiBadge)`
  & .MuiBadge-badge {
    min-width: 16px;
    font-size: 10px;
    width: 16px;
    height: 16px;
    right: 14px;
    top: 18px;
    ${({ theme }) => `
    background-color ${theme.palette.error.main};
    color: #FFFFFF;
  `}
  }
`;

const Chip = styled.div<ChipProps>`
  user-select: none;
  min-width: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 12px 4px 12px;
  cursor: pointer;
  height: 36px;
  background-color: #dadcf7;
  border-radius: 30px;
  ${({ theme, isActive, fullWidth }) => `
        ${fullWidth && `width: 100%;`}
        color: ${theme.palette.primary.main};
        border: 2px solid ${
          isActive ? theme.palette.primary.main : 'transparent'
        };
    `}
`;

const Filters = () => {
  const { t } = useTranslation();
  const [isShown, setIsShown] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const {
    numberForReview,
    numberInProcess,
    setFilters,
    showForReview,
    showInProcess,
    showRejected,
    showReviewed,
  } = useInvoicesFilterContext();

  const filters = (
    <>
      <Badge badgeContent={numberForReview}>
        <Chip
          fullWidth={!isDesktop}
          onClick={() =>
            setFilters({
              showForReview: true,
            })
          }
          isActive={showForReview}
          color="primary"
        >
          <Typography sx={{ fontWeight: 'bold' }} variant="body1">
            {t('For review')}
            &nbsp;
          </Typography>
        </Chip>
      </Badge>
      {isDesktop ? <Box ml={1} /> : <Box mb={1} />}
      <Badge badgeContent={numberInProcess}>
        <Chip
          fullWidth={!isDesktop}
          onClick={() =>
            setFilters({
              showInProcess: true,
            })
          }
          isActive={showInProcess}
          color="primary"
        >
          <Typography sx={{ fontWeight: 'bold' }} variant="body1">
            {t('Processing')}
            &nbsp;
          </Typography>
        </Chip>
      </Badge>
      {isDesktop ? <Box ml={1} /> : <Box mb={1} />}
      <Chip
        fullWidth={!isDesktop}
        onClick={() =>
          setFilters({
            showRejected: true,
          })
        }
        isActive={showRejected}
        color="primary"
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="body1">
          {t('Rejected')}
        </Typography>
      </Chip>
      {isDesktop ? <Box ml={1} /> : <Box mb={1} />}
      <Chip
        fullWidth={!isDesktop}
        onClick={() =>
          setFilters({
            showReviewed: true,
          })
        }
        isActive={showReviewed}
        color="primary"
      >
        <Typography sx={{ fontWeight: 'bold' }} variant="body1">
          {t('Reviewed')}
        </Typography>
      </Chip>
    </>
  );

  return isDesktop ? (
    <Box display="flex" alignItems="center">
      {filters}
    </Box>
  ) : (
    <>
      <Box display="flex" alignItems="center">
        <Button
          startIcon={<FilterAltIcon />}
          onClick={() => setIsShown(true)}
          color="primary"
          variant="contained"
          component="span"
        >
          {t('Show filters')}
        </Button>
      </Box>
      <Drawer anchor="right" open={isShown} onClose={() => setIsShown(false)}>
        <Card
          sx={{
            width: '250px',
          }}
        >
          <CardContent sx={{ padding: 3 }}>
            <Box mb={4} />
            <Box display="flex" flexDirection="column">
              <Typography variant="h3">{t('Filters')}</Typography>
              <Box mb={2} />
              {filters}
            </Box>
          </CardContent>
        </Card>
      </Drawer>
    </>
  );
};

export default Filters;
