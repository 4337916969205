import React from 'react';
import {
  Box,
  Button,
  DrawerProps,
  Drawer as MuiDrawer,
  useMediaQuery,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import AddIcon from '@mui/icons-material/Add';
import { FixedBox, SecondaryToolbar } from '../../../../ui';
import ExpensesToolbar, { ExpensesToolbarProps } from './ExpensesToolbar';

const Drawer = styled(({ ...props }: DrawerProps) => <MuiDrawer {...props} />)`
  ${({ theme }) => `
        & .MuiDrawer-paper {
			width: 100%;
			height: ${theme.toolbar.desktopHeight}px;
            background-color: ${theme.palette.background.paper};
            margin-top: ${theme.toolbar.mobileHeight}px;
            overflow-x: hidden;
    `}
`;

const ToolbarOrDrawer = (props: ExpensesToolbarProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const {
    title,
    TotalCostComponent,
    onButtonClick,
    withMonthSwitcher,
    onButtonNavigate,
    buttonComponent,
    isFullWidth,
  } = props;

  return (
    <>
      <SecondaryToolbar>
        <FixedBox isFullWidth={isFullWidth} display="flex" showBottomBorder>
          <ExpensesToolbar
            buttonComponent={buttonComponent}
            onButtonNavigate={onButtonNavigate}
            title={title}
            TotalCostComponent={TotalCostComponent}
            onButtonClick={onButtonClick}
            withMonthSwitcher={withMonthSwitcher}
          />
        </FixedBox>
      </SecondaryToolbar>
      {!isDesktop ? (
        <Drawer anchor="bottom" variant="permanent">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height="100%"
            padding="0 24px"
          >
            {TotalCostComponent ? <TotalCostComponent /> : null}
            <Box mr={2} />
            {buttonComponent || (
              <Button
                to={onButtonNavigate}
                component={onButtonNavigate ? RouterLink : (undefined as any)}
                variant="outlined"
                size="large"
                color="success"
                sx={{ padding: '8px', minWidth: '24px' }}
              >
                <AddIcon />
              </Button>
            )}
          </Box>
        </Drawer>
      ) : null}
    </>
  );
};

export default ToolbarOrDrawer;
