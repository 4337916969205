import React, { PropsWithChildren, createContext, useContext } from 'react';
import { DateTimeService } from '../services/date-time-service';

interface ContextProps {
  dateTimeService: DateTimeService;
}

const defaultState = {
  dateTimeService: new DateTimeService(),
};

const DateTimeServiceContext = createContext<ContextProps>(defaultState);

export const DateTimeServiceProvider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const dateTimeService = useProvideDateTimeService();

  return (
    <DateTimeServiceContext.Provider value={dateTimeService}>
      {children}
    </DateTimeServiceContext.Provider>
  );
};

export const useDateTimeService = () => {
  return useContext(DateTimeServiceContext);
};

function useProvideDateTimeService() {
  const dateTimeService = new DateTimeService();
  return {
    dateTimeService,
  };
}
