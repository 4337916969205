import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as React from 'react';
import * as ApolloReactComponents from '../graphql/customGqlComponents';
import * as ApolloReactHooks from '../graphql/customGqlHooks';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Upload: any;
}

export interface Account {
  __typename?: 'Account';
  createdAt: Scalars['Date'];
  cultureCode?: Maybe<CultureCode>;
  defaultUserId?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  languageCode?: Maybe<CultureCode>;
  lastName: Scalars['String'];
  users: Array<User>;
}

export interface Category {
  __typename?: 'Category';
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  parentCategory?: Maybe<Category>;
  parentCategoryId?: Maybe<Scalars['ID']>;
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export enum CountryCode {
  Afghanistan = 'Afghanistan',
  AlandIslands = 'AlandIslands',
  Albania = 'Albania',
  Algeria = 'Algeria',
  AmericanSamoa = 'AmericanSamoa',
  Andorra = 'Andorra',
  Angola = 'Angola',
  Anguilla = 'Anguilla',
  Antarctica = 'Antarctica',
  AntiguaAndBarbuda = 'AntiguaAndBarbuda',
  Argentina = 'Argentina',
  Armenia = 'Armenia',
  Aruba = 'Aruba',
  Australia = 'Australia',
  Austria = 'Austria',
  Azerbaijan = 'Azerbaijan',
  Bahamas = 'Bahamas',
  Bahrain = 'Bahrain',
  Bangladesh = 'Bangladesh',
  Barbados = 'Barbados',
  Belarus = 'Belarus',
  Belgium = 'Belgium',
  Belize = 'Belize',
  Benin = 'Benin',
  Bermuda = 'Bermuda',
  Bhutan = 'Bhutan',
  Bolivia = 'Bolivia',
  BosniaAndHerzegovina = 'BosniaAndHerzegovina',
  Botswana = 'Botswana',
  BouvetIsland = 'BouvetIsland',
  Brazil = 'Brazil',
  BritishIndianOceanTerritory = 'BritishIndianOceanTerritory',
  BruneiDarussalam = 'BruneiDarussalam',
  Bulgaria = 'Bulgaria',
  BurkinaFaso = 'BurkinaFaso',
  Burundi = 'Burundi',
  Cambodia = 'Cambodia',
  Cameroon = 'Cameroon',
  Canada = 'Canada',
  CapeVerde = 'CapeVerde',
  CaymanIslands = 'CaymanIslands',
  CentralAfricanRepublic = 'CentralAfricanRepublic',
  Chad = 'Chad',
  Chile = 'Chile',
  China = 'China',
  ChristmasIsland = 'ChristmasIsland',
  CocosKeelingIslands = 'CocosKeelingIslands',
  Colombia = 'Colombia',
  Comoros = 'Comoros',
  Congo = 'Congo',
  CongoDemocraticRepublic = 'CongoDemocraticRepublic',
  CookIslands = 'CookIslands',
  CostaRica = 'CostaRica',
  CoteDIvoire = 'CoteDIvoire',
  Croatia = 'Croatia',
  Cuba = 'Cuba',
  Cyprus = 'Cyprus',
  CzechRepublic = 'CzechRepublic',
  Denmark = 'Denmark',
  Djibouti = 'Djibouti',
  Dominica = 'Dominica',
  DominicanRepublic = 'DominicanRepublic',
  Ecuador = 'Ecuador',
  Egypt = 'Egypt',
  ElSalvador = 'ElSalvador',
  EquatorialGuinea = 'EquatorialGuinea',
  Eritrea = 'Eritrea',
  Estonia = 'Estonia',
  Ethiopia = 'Ethiopia',
  FalklandIslands = 'FalklandIslands',
  FaroeIslands = 'FaroeIslands',
  Fiji = 'Fiji',
  Finland = 'Finland',
  France = 'France',
  FrenchGuiana = 'FrenchGuiana',
  FrenchPolynesia = 'FrenchPolynesia',
  FrenchSouthernTerritories = 'FrenchSouthernTerritories',
  Gabon = 'Gabon',
  Gambia = 'Gambia',
  Georgia = 'Georgia',
  Germany = 'Germany',
  Ghana = 'Ghana',
  Gibraltar = 'Gibraltar',
  Greece = 'Greece',
  Greenland = 'Greenland',
  Grenada = 'Grenada',
  Guadeloupe = 'Guadeloupe',
  Guam = 'Guam',
  Guatemala = 'Guatemala',
  Guernsey = 'Guernsey',
  Guinea = 'Guinea',
  GuineaBissau = 'GuineaBissau',
  Guyana = 'Guyana',
  Haiti = 'Haiti',
  HeardIslandMcdonaldIslands = 'HeardIslandMcdonaldIslands',
  HolySeeVaticanCityState = 'HolySeeVaticanCityState',
  Honduras = 'Honduras',
  HongKong = 'HongKong',
  Hungary = 'Hungary',
  Iceland = 'Iceland',
  India = 'India',
  Indonesia = 'Indonesia',
  Iran = 'Iran',
  Iraq = 'Iraq',
  Ireland = 'Ireland',
  IsleOfMan = 'IsleOfMan',
  Israel = 'Israel',
  Italy = 'Italy',
  Jamaica = 'Jamaica',
  Japan = 'Japan',
  Jersey = 'Jersey',
  Jordan = 'Jordan',
  Kazakhstan = 'Kazakhstan',
  Kenya = 'Kenya',
  Kiribati = 'Kiribati',
  Korea = 'Korea',
  Kuwait = 'Kuwait',
  Kyrgyzstan = 'Kyrgyzstan',
  LaoPeoplesDemocraticRepublic = 'LaoPeoplesDemocraticRepublic',
  Latvia = 'Latvia',
  Lebanon = 'Lebanon',
  Lesotho = 'Lesotho',
  Liberia = 'Liberia',
  LibyanArabJamahiriya = 'LibyanArabJamahiriya',
  Liechtenstein = 'Liechtenstein',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Macao = 'Macao',
  Macedonia = 'Macedonia',
  Madagascar = 'Madagascar',
  Malawi = 'Malawi',
  Malaysia = 'Malaysia',
  Maldives = 'Maldives',
  Mali = 'Mali',
  Malta = 'Malta',
  MarshallIslands = 'MarshallIslands',
  Martinique = 'Martinique',
  Mauritania = 'Mauritania',
  Mauritius = 'Mauritius',
  Mayotte = 'Mayotte',
  Mexico = 'Mexico',
  Micronesia = 'Micronesia',
  Moldova = 'Moldova',
  Monaco = 'Monaco',
  Mongolia = 'Mongolia',
  Montenegro = 'Montenegro',
  Montserrat = 'Montserrat',
  Morocco = 'Morocco',
  Mozambique = 'Mozambique',
  Myanmar = 'Myanmar',
  Namibia = 'Namibia',
  Nauru = 'Nauru',
  Nepal = 'Nepal',
  Netherlands = 'Netherlands',
  NetherlandsAntilles = 'NetherlandsAntilles',
  NewCaledonia = 'NewCaledonia',
  NewZealand = 'NewZealand',
  Nicaragua = 'Nicaragua',
  Niger = 'Niger',
  Nigeria = 'Nigeria',
  Niue = 'Niue',
  NorfolkIsland = 'NorfolkIsland',
  NorthernMarianaIslands = 'NorthernMarianaIslands',
  Norway = 'Norway',
  Oman = 'Oman',
  Pakistan = 'Pakistan',
  Palau = 'Palau',
  PalestinianTerritory = 'PalestinianTerritory',
  Panama = 'Panama',
  PapuaNewGuinea = 'PapuaNewGuinea',
  Paraguay = 'Paraguay',
  Peru = 'Peru',
  Philippines = 'Philippines',
  Pitcairn = 'Pitcairn',
  Poland = 'Poland',
  Portugal = 'Portugal',
  PuertoRico = 'PuertoRico',
  Qatar = 'Qatar',
  Reunion = 'Reunion',
  Romania = 'Romania',
  RussianFederation = 'RussianFederation',
  Rwanda = 'Rwanda',
  SaintBarthelemy = 'SaintBarthelemy',
  SaintHelena = 'SaintHelena',
  SaintKittsAndNevis = 'SaintKittsAndNevis',
  SaintLucia = 'SaintLucia',
  SaintMartin = 'SaintMartin',
  SaintPierreAndMiquelon = 'SaintPierreAndMiquelon',
  SaintVincentAndGrenadines = 'SaintVincentAndGrenadines',
  Samoa = 'Samoa',
  SanMarino = 'SanMarino',
  SaoTomeAndPrincipe = 'SaoTomeAndPrincipe',
  SaudiArabia = 'SaudiArabia',
  Senegal = 'Senegal',
  Serbia = 'Serbia',
  Seychelles = 'Seychelles',
  SierraLeone = 'SierraLeone',
  Singapore = 'Singapore',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  SolomonIslands = 'SolomonIslands',
  Somalia = 'Somalia',
  SouthAfrica = 'SouthAfrica',
  SouthGeorgiaAndSandwichIsl = 'SouthGeorgiaAndSandwichIsl',
  Spain = 'Spain',
  SriLanka = 'SriLanka',
  Sudan = 'Sudan',
  Suriname = 'Suriname',
  SvalbardAndJanMayen = 'SvalbardAndJanMayen',
  Swaziland = 'Swaziland',
  Sweden = 'Sweden',
  Switzerland = 'Switzerland',
  SyrianArabRepublic = 'SyrianArabRepublic',
  Taiwan = 'Taiwan',
  Tajikistan = 'Tajikistan',
  Tanzania = 'Tanzania',
  Thailand = 'Thailand',
  TimorLeste = 'TimorLeste',
  Togo = 'Togo',
  Tokelau = 'Tokelau',
  Tonga = 'Tonga',
  TrinidadAndTobago = 'TrinidadAndTobago',
  Tunisia = 'Tunisia',
  Turkey = 'Turkey',
  Turkmenistan = 'Turkmenistan',
  TurksAndCaicosIslands = 'TurksAndCaicosIslands',
  Tuvalu = 'Tuvalu',
  Uganda = 'Uganda',
  Ukraine = 'Ukraine',
  UnitedArabEmirates = 'UnitedArabEmirates',
  UnitedKingdom = 'UnitedKingdom',
  UnitedStates = 'UnitedStates',
  UnitedStatesOutlyingIslands = 'UnitedStatesOutlyingIslands',
  Uruguay = 'Uruguay',
  Uzbekistan = 'Uzbekistan',
  Vanuatu = 'Vanuatu',
  Venezuela = 'Venezuela',
  VietNam = 'VietNam',
  VirginIslandsBritish = 'VirginIslandsBritish',
  VirginIslandsUs = 'VirginIslandsUS',
  WallisAndFutuna = 'WallisAndFutuna',
  WesternSahara = 'WesternSahara',
  Yemen = 'Yemen',
  Zambia = 'Zambia',
  Zimbabwe = 'Zimbabwe',
}

export interface CreateAccountInputType {
  cultureCode?: InputMaybe<CultureCode>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  languageCode?: InputMaybe<CultureCode>;
  lastName: Scalars['String'];
  password: Scalars['String'];
}

export interface CreateBusinessEntityArgs {
  name: Scalars['String'];
}

export interface CreateLegalEntityArgs {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  companyIdentificationNumber?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<CountryCode>;
  currencyCurrencyCode: CurrencyCode;
  district?: InputMaybe<Scalars['String']>;
  legalName?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  taxIdentificationNumber?: InputMaybe<Scalars['String']>;
  venue: CreateVenueArgs;
}

export interface CreateRecurrencePatternInput {
  dayOfMonth?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<DayOfWeek>;
  endDate?: InputMaybe<Scalars['Date']>;
  interval: RecurrenceInterval;
  monthOfYear?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface CreateVenueArgs {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cultureCode: CultureCode;
  defaultTimezone: Scalars['String'];
  district?: InputMaybe<Scalars['String']>;
  languageCode: CultureCode;
  name: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
}

export enum CultureCode {
  CsCz = 'cs_CZ',
  EnUs = 'en_US',
}

export interface Currency {
  __typename?: 'Currency';
  currencyCode: CurrencyCode;
  precision: Scalars['Int'];
}

export enum CurrencyCode {
  Czk = 'CZK',
}

export interface CurrentUserObjectType {
  __typename?: 'CurrentUserObjectType';
  client_id: Scalars['String'];
  cultureCode: CultureCode;
  currency: Currency;
  email: Scalars['String'];
  family_name: Scalars['String'];
  given_name: Scalars['String'];
  languageCode: CultureCode;
  scope: ScopeObjectType;
  subscriptionData: SubscriptionData;
  zoneinfo: Scalars['String'];
}

export interface DateFromToInputType {
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export enum DayOfWeek {
  Fri = 'Fri',
  Mon = 'Mon',
  Sat = 'Sat',
  Sun = 'Sun',
  Thu = 'Thu',
  Tue = 'Tue',
  Wed = 'Wed',
}

export interface EmailInvoiceUploadMeta {
  __typename?: 'EmailInvoiceUploadMeta';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  receivedAt: Scalars['Date'];
  receivedFrom: Scalars['String'];
}

export interface Employee {
  __typename?: 'Employee';
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  deletedById?: Maybe<Scalars['ID']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export enum ExpenseDateFilterType {
  AccountingDate = 'AccountingDate',
  DueDate = 'DueDate',
}

export interface ExpenseStructuredTotalAmountObjectType {
  __typename?: 'ExpenseStructuredTotalAmountObjectType';
  oneTimeExpensesTotalAmount: TotalAmountObjectType;
  oneTimeLaborExpensesTotalAmount: TotalAmountObjectType;
  recurringExpensesTotalAmount: TotalAmountObjectType;
  recurringLaborExpensesTotalAmount: TotalAmountObjectType;
  supplyExpensesTotalAmount: TotalAmountObjectType;
}

export interface ExpenseTotalByCategoryObjectType {
  __typename?: 'ExpenseTotalByCategoryObjectType';
  amount: Scalars['Int'];
  categoryId: Scalars['ID'];
  categoryName: Scalars['String'];
  currencyCode: CurrencyCode;
  id: Scalars['ID'];
  precision: Scalars['Int'];
}

export enum ExpenseType {
  OneTime = 'OneTime',
  Recurring = 'Recurring',
}

export interface ExpensesAmountsWithPaymentByMonthObjectType {
  __typename?: 'ExpensesAmountsWithPaymentByMonthObjectType';
  amount: Scalars['Int'];
  amountPaid: Scalars['Int'];
  currencyCode: CurrencyCode;
  id: Scalars['ID'];
  /** month 1-12 */
  isoMonth: Scalars['Int'];
  /** Year in format YYYY */
  isoYear: Scalars['Int'];
  precision: Scalars['Int'];
}

export interface InvoiceUpload {
  __typename?: 'InvoiceUpload';
  createdAt: Scalars['Date'];
  emailUploadMeta?: Maybe<EmailInvoiceUploadMeta>;
  id: Scalars['ID'];
  items: Array<InvoiceUploadItem>;
  manualUploadMeta?: Maybe<ManualInvoiceUploadMeta>;
  uploadMethod: InvoiceUploadMethod;
  venueId: Scalars['ID'];
}

export interface InvoiceUploadItem {
  __typename?: 'InvoiceUploadItem';
  bucket?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  externalId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileSize: Scalars['Int'];
  id: Scalars['ID'];
  invoiceUploadId: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  mimeType: Scalars['String'];
  ocrExpense?: Maybe<OcrExpense>;
  state: UploadedInvoiceState;
  uploadError?: Maybe<UploadError>;
}

export enum InvoiceUploadMethod {
  Email = 'EMAIL',
  Manual = 'MANUAL',
}

export interface LaborExpenseTimeSeriesObjectType {
  __typename?: 'LaborExpenseTimeSeriesObjectType';
  amount: Scalars['Int'];
  currencyCode: CurrencyCode;
  currencyPrecision: Scalars['Int'];
  date: Scalars['Date'];
  employee: Employee;
  employeeId: Scalars['ID'];
  id: Scalars['String'];
  isPaid: Scalars['Boolean'];
  laborExpense: LaborExpenseUnion;
  laborExpenseId: Scalars['ID'];
  laborExpenseType: ExpenseType;
}

export type LaborExpenseUnion = OneTimeLaborExpense | RepeatingLaborExpense;

export interface ManualInvoiceUploadMeta {
  __typename?: 'ManualInvoiceUploadMeta';
  createdAt: Scalars['Date'];
  createdBy: Account;
  createdById: Scalars['ID'];
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  updatedById?: Maybe<Scalars['ID']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  addRevenue: Revenue;
  addUserToVenueAsAdmin: User;
  checkAuthState?: Maybe<CurrentUserObjectType>;
  convertOcrExpenseToOneTimeExpense: Scalars['Boolean'];
  convertOcrExpenseToSupplyExpense: Scalars['Boolean'];
  createAccountAsAdmin: Account;
  createCategory: Category;
  createEmployee: Employee;
  createOneTimeExpense: OneTimeExpense;
  createOneTimeLaborExpense: OneTimeLaborExpense;
  createPaymentMethod: PaymentMethod;
  createRepeatingExpense: RepeatingExpense;
  createRepeatingLaborExpense: RepeatingLaborExpense;
  createRevenueSource: RevenueSource;
  createSupplier: Supplier;
  createSupplyExpense: SupplyExpense;
  createVenue: Venue;
  createVenueAsAdmin: Venue;
  login: CurrentUserObjectType;
  logout: Scalars['Boolean'];
  markLaborExpensesAsPaid: Scalars['Boolean'];
  markOneTimeExpensePayed: OneTimeExpense;
  markOneTimeLaborExpensePayed: OneTimeLaborExpense;
  markRepeatingExpensePaid: Scalars['Boolean'];
  markRepeatingLaborExpensePaid: Scalars['Boolean'];
  markSupplyExpensePayed: SupplyExpense;
  overwriteRevenue: Revenue;
  removeEmployee: Scalars['Boolean'];
  removeOneTimeExpense: Scalars['Boolean'];
  removeOneTimeLaborExpense: Scalars['Boolean'];
  removePaymentMethod: Scalars['Boolean'];
  removeRepeatingExpense: Scalars['Boolean'];
  removeRepeatingLaborExpense: Scalars['Boolean'];
  removeRevenue: Scalars['Boolean'];
  removeRevenueSource: Scalars['Boolean'];
  removeSupplyExpense: Scalars['Boolean'];
  rescheduleRepeatingExpense: RepeatingExpense;
  rescheduleRepeatingLaborExpense: RepeatingLaborExpense;
  signUp: Scalars['Boolean'];
  skipRepeatingExpense: RepeatingExpense;
  skipRepeatingLaborExpense: RepeatingLaborExpense;
  switchVenue: CurrentUserObjectType;
  terminateRepeatingExpense: RepeatingExpense;
  terminateRepeatingLaborExpense: RepeatingLaborExpense;
  unmarkLaborExpensesAsPaid: Scalars['Boolean'];
  unmarkOneTimeExpensePayed: OneTimeExpense;
  unmarkOneTimeLaborExpensePayed: OneTimeLaborExpense;
  unmarkRepeatingExpensePaid: Scalars['Boolean'];
  unmarkRepeatingLaborExpensePaid: Scalars['Boolean'];
  unmarkSupplyExpensePayed: SupplyExpense;
  updateCurrentAccount: Account;
  updateEmployee: Employee;
  updateOneTimeExpense: OneTimeExpense;
  updateOneTimeLaborExpense: OneTimeLaborExpense;
  updateRevenue: Revenue;
  updateSupplyExpense: SupplyExpense;
  uploadInvoice: InvoiceUpload;
}

export interface MutationAddRevenueArgs {
  amount: Scalars['Int'];
  categoryId?: InputMaybe<Scalars['ID']>;
  date: Scalars['Date'];
  mode?: InputMaybe<RevenueMode>;
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  revenueSourceId: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationAddUserToVenueAsAdminArgs {
  accountId: Scalars['ID'];
  adminPassword: Scalars['String'];
  isNewAccount?: InputMaybe<Scalars['Boolean']>;
  venueId: Scalars['ID'];
}

export interface MutationConvertOcrExpenseToOneTimeExpenseArgs {
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  categoryId: Scalars['ID'];
  dueDate: Scalars['Date'];
  link?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ocrExpenseId: Scalars['ID'];
  supplierId?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationConvertOcrExpenseToSupplyExpenseArgs {
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  categoryId: Scalars['ID'];
  dueDate: Scalars['Date'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  ocrExpenseId: Scalars['ID'];
  supplierId: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationCreateAccountAsAdminArgs {
  adminPassword: Scalars['String'];
  cultureCode?: InputMaybe<CultureCode>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  languageCode?: InputMaybe<CultureCode>;
  lastName: Scalars['String'];
  password: Scalars['String'];
}

export interface MutationCreateCategoryArgs {
  name: Scalars['String'];
  parentCategoryId?: InputMaybe<Scalars['ID']>;
}

export interface MutationCreateEmployeeArgs {
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
}

export interface MutationCreateOneTimeExpenseArgs {
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  categoryId: Scalars['ID'];
  dueDate: Scalars['Date'];
  link?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  supplierId?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationCreateOneTimeLaborExpenseArgs {
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  categoryId?: InputMaybe<Scalars['ID']>;
  dueDate: Scalars['Date'];
  employeeId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationCreatePaymentMethodArgs {
  name: Scalars['String'];
}

export interface MutationCreateRepeatingExpenseArgs {
  amount: Scalars['Int'];
  categoryId: Scalars['ID'];
  name: Scalars['String'];
  recurrencePattern: CreateRecurrencePatternInput;
  supplierId?: InputMaybe<Scalars['ID']>;
}

export interface MutationCreateRepeatingLaborExpenseArgs {
  amount: Scalars['Int'];
  categoryId?: InputMaybe<Scalars['ID']>;
  employeeId: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  recurrencePattern: CreateRecurrencePatternInput;
}

export interface MutationCreateRevenueSourceArgs {
  name: Scalars['String'];
}

export interface MutationCreateSupplierArgs {
  name: Scalars['String'];
  tin?: InputMaybe<Scalars['String']>;
  vatId?: InputMaybe<Scalars['String']>;
}

export interface MutationCreateSupplyExpenseArgs {
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  categoryId: Scalars['ID'];
  dueDate: Scalars['Date'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  supplierId: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationCreateVenueArgs {
  cultureCode?: InputMaybe<CultureCode>;
  defaultTimezone?: InputMaybe<Scalars['String']>;
  languageCode?: InputMaybe<CultureCode>;
  name: Scalars['String'];
  userIds?: InputMaybe<Array<Scalars['ID']>>;
}

export interface MutationCreateVenueAsAdminArgs {
  accountIds: Array<Scalars['ID']>;
  adminPassword: Scalars['String'];
  cultureCode: CultureCode;
  defaultTimezone: Scalars['String'];
  languageCode: CultureCode;
  legalEntityId: Scalars['ID'];
  name: Scalars['String'];
}

export interface MutationLoginArgs {
  email: Scalars['String'];
  password: Scalars['String'];
}

export interface MutationMarkLaborExpensesAsPaidArgs {
  expenseIds: Array<Scalars['ID']>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface MutationMarkOneTimeExpensePayedArgs {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationMarkOneTimeLaborExpensePayedArgs {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationMarkRepeatingExpensePaidArgs {
  date: Scalars['Date'];
  id: Scalars['ID'];
}

export interface MutationMarkRepeatingLaborExpensePaidArgs {
  date: Scalars['Date'];
  id: Scalars['ID'];
}

export interface MutationMarkSupplyExpensePayedArgs {
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationOverwriteRevenueArgs {
  amount: Scalars['Int'];
  categoryId?: InputMaybe<Scalars['ID']>;
  date: Scalars['Date'];
  mode?: InputMaybe<RevenueMode>;
  paymentMethodId?: InputMaybe<Scalars['ID']>;
  revenueSourceId: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationRemoveEmployeeArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveOneTimeExpenseArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveOneTimeLaborExpenseArgs {
  id: Scalars['ID'];
}

export interface MutationRemovePaymentMethodArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveRepeatingExpenseArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveRepeatingLaborExpenseArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveRevenueArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveRevenueSourceArgs {
  id: Scalars['ID'];
}

export interface MutationRemoveSupplyExpenseArgs {
  id: Scalars['ID'];
}

export interface MutationRescheduleRepeatingExpenseArgs {
  id: Scalars['ID'];
  recurrencePattern: UpdateRecurrencePatternInput;
}

export interface MutationRescheduleRepeatingLaborExpenseArgs {
  id: Scalars['ID'];
  recurrencePattern: UpdateRecurrencePatternInput;
}

export interface MutationSignUpArgs {
  account: CreateAccountInputType;
  businessEntity: CreateBusinessEntityArgs;
  legalEntity: CreateLegalEntityArgs;
}

export interface MutationSkipRepeatingExpenseArgs {
  date: Scalars['Date'];
  id: Scalars['ID'];
}

export interface MutationSkipRepeatingLaborExpenseArgs {
  date: Scalars['Date'];
  id: Scalars['ID'];
}

export interface MutationSwitchVenueArgs {
  targetVenueId: Scalars['ID'];
}

export interface MutationTerminateRepeatingExpenseArgs {
  id: Scalars['ID'];
  terminationDate: Scalars['Date'];
}

export interface MutationTerminateRepeatingLaborExpenseArgs {
  id: Scalars['ID'];
  terminationDate: Scalars['Date'];
}

export interface MutationUnmarkLaborExpensesAsPaidArgs {
  expenseIds: Array<Scalars['ID']>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface MutationUnmarkOneTimeExpensePayedArgs {
  id: Scalars['ID'];
}

export interface MutationUnmarkOneTimeLaborExpensePayedArgs {
  id: Scalars['ID'];
}

export interface MutationUnmarkRepeatingExpensePaidArgs {
  date: Scalars['Date'];
  id: Scalars['ID'];
}

export interface MutationUnmarkRepeatingLaborExpensePaidArgs {
  date: Scalars['Date'];
  id: Scalars['ID'];
}

export interface MutationUnmarkSupplyExpensePayedArgs {
  id: Scalars['ID'];
}

export interface MutationUpdateCurrentAccountArgs {
  cultureCode?: InputMaybe<CultureCode>;
  defaultUserId?: InputMaybe<Scalars['ID']>;
  firstName: Scalars['String'];
  languageCode?: InputMaybe<CultureCode>;
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
}

export interface MutationUpdateEmployeeArgs {
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
}

export interface MutationUpdateOneTimeExpenseArgs {
  accountingDate?: InputMaybe<Scalars['Date']>;
  amount?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  link?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationUpdateOneTimeLaborExpenseArgs {
  accountingDate?: InputMaybe<Scalars['Date']>;
  amount?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  employeeId?: InputMaybe<Scalars['ID']>;
  id: Scalars['ID'];
  note?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationUpdateRevenueArgs {
  amount?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  revenueSourceId?: InputMaybe<Scalars['ID']>;
}

export interface MutationUpdateSupplyExpenseArgs {
  accountingDate?: InputMaybe<Scalars['Date']>;
  amount?: InputMaybe<Scalars['Int']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  id: Scalars['ID'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  supplierId?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
}

export interface MutationUploadInvoiceArgs {
  files: Array<Scalars['Upload']>;
}

export interface OcrExpense {
  __typename?: 'OcrExpense';
  /** Identifies a date when this expense will be reflected in accounting */
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  createdAt: Scalars['Date'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  dueDate: Scalars['Date'];
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  supplier: Supplier;
  supplierId: Scalars['ID'];
  venueId: Scalars['ID'];
}

export interface OneTimeExpense {
  __typename?: 'OneTimeExpense';
  /** Identifies a date when this expense will be reflected in accounting */
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  category: Category;
  categoryId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  deletedById?: Maybe<Scalars['ID']>;
  dueDate: Scalars['Date'];
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  payment?: Maybe<Payment>;
  paymentId?: Maybe<Scalars['ID']>;
  supplier?: Maybe<Supplier>;
  supplierId?: Maybe<Scalars['ID']>;
  timezone: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface OneTimeLaborExpense {
  __typename?: 'OneTimeLaborExpense';
  /** Identifies a date when this expense will be reflected in accounting */
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  deletedById?: Maybe<Scalars['ID']>;
  dueDate: Scalars['Date'];
  employee?: Maybe<Employee>;
  employeeId: Scalars['ID'];
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
  paymentId?: Maybe<Scalars['ID']>;
  timezone: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface PaginatedCategoryObjectType {
  __typename?: 'PaginatedCategoryObjectType';
  count: Scalars['Int'];
  items: Array<Category>;
}

export interface PaginatedEmployeeObjectType {
  __typename?: 'PaginatedEmployeeObjectType';
  count: Scalars['Int'];
  items: Array<Employee>;
}

export interface PaginatedOneTimeExpenseObjectType {
  __typename?: 'PaginatedOneTimeExpenseObjectType';
  count: Scalars['Int'];
  items: Array<OneTimeExpense>;
}

export interface PaginatedOneTimeLaborExpenseObjectType {
  __typename?: 'PaginatedOneTimeLaborExpenseObjectType';
  count: Scalars['Int'];
  items: Array<OneTimeLaborExpense>;
}

export interface PaginatedPaymentMethodObjectType {
  __typename?: 'PaginatedPaymentMethodObjectType';
  count: Scalars['Int'];
  items: Array<PaymentMethod>;
}

export interface PaginatedPaymentObjectType {
  __typename?: 'PaginatedPaymentObjectType';
  count: Scalars['Int'];
  items: Array<Payment>;
}

export interface PaginatedRepeatingExpenseObjectType {
  __typename?: 'PaginatedRepeatingExpenseObjectType';
  count: Scalars['Int'];
  items: Array<RepeatingExpense>;
}

export interface PaginatedRepeatingLaborExpenseObjectType {
  __typename?: 'PaginatedRepeatingLaborExpenseObjectType';
  count: Scalars['Int'];
  items: Array<RepeatingLaborExpense>;
}

export interface PaginatedRevenueObjectType {
  __typename?: 'PaginatedRevenueObjectType';
  count: Scalars['Int'];
  items: Array<Revenue>;
}

export interface PaginatedRevenueSourceObjectType {
  __typename?: 'PaginatedRevenueSourceObjectType';
  count: Scalars['Int'];
  items: Array<RevenueSource>;
}

export interface PaginatedSupplierObjectType {
  __typename?: 'PaginatedSupplierObjectType';
  count: Scalars['Int'];
  items: Array<Supplier>;
}

export interface PaginatedSupplyExpenseObjectType {
  __typename?: 'PaginatedSupplyExpenseObjectType';
  count: Scalars['Int'];
  items: Array<SupplyExpense>;
}

export interface Payment {
  __typename?: 'Payment';
  amount: Scalars['Int'];
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  oneTimeExpenseId: Scalars['ID'];
  oneTimeLaborExpenseId: Scalars['ID'];
  payedAt: Scalars['Date'];
  paymentDueDate: Scalars['Date'];
  repeatingExpenseId: Scalars['ID'];
  repeatingLaborExpenseId: Scalars['ID'];
  supplyExpenseId: Scalars['ID'];
  timezone: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface PaymentMethod {
  __typename?: 'PaymentMethod';
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
}

export interface Query {
  __typename?: 'Query';
  categories: PaginatedCategoryObjectType;
  category: Category;
  currentUser: User;
  currentUserAccount: Account;
  employee: Employee;
  employees: PaginatedEmployeeObjectType;
  expensesAmountsByMonthWithPaidAmount: Array<ExpensesAmountsWithPaymentByMonthObjectType>;
  invoiceUploads: Array<InvoiceUpload>;
  laborExpensesTimeSeries: Array<LaborExpenseTimeSeriesObjectType>;
  laborExpensesTotalAmount: TotalAmountObjectType;
  ocrExpense: OcrExpense;
  oneTimeExpense: OneTimeExpense;
  oneTimeExpenses: PaginatedOneTimeExpenseObjectType;
  oneTimeExpensesTotalAmount: TotalAmountObjectType;
  oneTimeLaborExpense: OneTimeLaborExpense;
  oneTimeLaborExpenseTotalAmount: TotalAmountObjectType;
  oneTimeLaborExpenses: PaginatedOneTimeLaborExpenseObjectType;
  payment: Payment;
  paymentMethod: PaymentMethod;
  paymentMethods: PaginatedPaymentMethodObjectType;
  payments: PaginatedPaymentObjectType;
  recurringExpensesTimeSeries: Array<RecurringExpenseTimeSeriesObjectType>;
  recurringExpensesTotalAmount: TotalAmountObjectType;
  recurringLaborExpensesTotalAmount: TotalAmountObjectType;
  repeatingExpense: RepeatingExpense;
  repeatingExpenses: PaginatedRepeatingExpenseObjectType;
  repeatingLaborExpense: RepeatingLaborExpense;
  repeatingLaborExpenses: PaginatedRepeatingLaborExpenseObjectType;
  revenue: Revenue;
  revenueSource: RevenueSource;
  revenueSources: PaginatedRevenueSourceObjectType;
  revenues: PaginatedRevenueObjectType;
  revenuesTotal: TotalAmountObjectType;
  revenuesTotalBySource: Array<RevenueTotalAmountBySourceObjectType>;
  structuredTotalExpenses: ExpenseStructuredTotalAmountObjectType;
  supplier: Supplier;
  suppliers: PaginatedSupplierObjectType;
  supplyExpense: SupplyExpense;
  supplyExpenses: PaginatedSupplyExpenseObjectType;
  supplyExpensesTotalAmount: TotalAmountObjectType;
  totalExpenses: TotalAmountObjectType;
  totalExpensesByCategory?: Maybe<Array<ExpenseTotalByCategoryObjectType>>;
  /** @deprecated Contains a typo, please use totalExpensesByCategory */
  totalExpensesByaCategory?: Maybe<Array<ExpenseTotalByCategoryObjectType>>;
  totalProfit: TotalAmountObjectType;
  venues: Array<Venue>;
}

export interface QueryCategoriesArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryCategoryArgs {
  id: Scalars['ID'];
}

export interface QueryEmployeeArgs {
  id: Scalars['ID'];
}

export interface QueryEmployeesArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryExpensesAmountsByMonthWithPaidAmountArgs {
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  oneTimeExpenses?: InputMaybe<Scalars['Boolean']>;
  oneTimeLaborExpenses?: InputMaybe<Scalars['Boolean']>;
  recurringExpenses?: InputMaybe<Scalars['Boolean']>;
  repeatingLaborExpenses?: InputMaybe<Scalars['Boolean']>;
  supplyExpenses?: InputMaybe<Scalars['Boolean']>;
  to: Scalars['Date'];
}

export interface QueryLaborExpensesTimeSeriesArgs {
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryLaborExpensesTotalAmountArgs {
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryOcrExpenseArgs {
  id: Scalars['ID'];
}

export interface QueryOneTimeExpenseArgs {
  id: Scalars['ID'];
}

export interface QueryOneTimeExpensesArgs {
  dateFilter?: InputMaybe<DateFromToInputType>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryOneTimeExpensesTotalAmountArgs {
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryOneTimeLaborExpenseArgs {
  id: Scalars['ID'];
}

export interface QueryOneTimeLaborExpenseTotalAmountArgs {
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryOneTimeLaborExpensesArgs {
  dateFilter?: InputMaybe<DateFromToInputType>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryPaymentArgs {
  id: Scalars['ID'];
}

export interface QueryPaymentMethodArgs {
  id: Scalars['ID'];
}

export interface QueryPaymentMethodsArgs {
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryPaymentsArgs {
  dateFilter: DateFromToInputType;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryRecurringExpensesTimeSeriesArgs {
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryRecurringExpensesTotalAmountArgs {
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryRecurringLaborExpensesTotalAmountArgs {
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryRepeatingExpenseArgs {
  id: Scalars['String'];
}

export interface QueryRepeatingExpensesArgs {
  dateFilter?: InputMaybe<DateFromToInputType>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryRepeatingLaborExpenseArgs {
  id: Scalars['ID'];
}

export interface QueryRepeatingLaborExpensesArgs {
  dateFilter?: InputMaybe<DateFromToInputType>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryRevenueArgs {
  id: Scalars['ID'];
}

export interface QueryRevenueSourceArgs {
  id: Scalars['ID'];
}

export interface QueryRevenueSourcesArgs {
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryRevenuesArgs {
  dateFilter?: InputMaybe<DateFromToInputType>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QueryRevenuesTotalArgs {
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryRevenuesTotalBySourceArgs {
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryStructuredTotalExpensesArgs {
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QuerySupplierArgs {
  id: Scalars['ID'];
}

export interface QuerySuppliersArgs {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QuerySupplyExpenseArgs {
  id: Scalars['ID'];
}

export interface QuerySupplyExpensesArgs {
  dateFilter?: InputMaybe<DateFromToInputType>;
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface QuerySupplyExpensesTotalAmountArgs {
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryTotalExpensesArgs {
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryTotalExpensesByCategoryArgs {
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryTotalExpensesByaCategoryArgs {
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export interface QueryTotalProfitArgs {
  from: Scalars['Date'];
  to: Scalars['Date'];
}

export enum RecurrenceInterval {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR',
}

export interface RecurrencePattern {
  __typename?: 'RecurrencePattern';
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  dayOfMonth?: Maybe<Scalars['Int']>;
  dayOfWeek?: Maybe<DayOfWeek>;
  deletedById?: Maybe<Scalars['ID']>;
  endDate?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  interval: RecurrenceInterval;
  monthOfYear?: Maybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  timezone: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
}

export interface RecurringExpenseTimeSeriesObjectType {
  __typename?: 'RecurringExpenseTimeSeriesObjectType';
  amount: Scalars['Int'];
  currencyCode: CurrencyCode;
  currencyPrecision: Scalars['Int'];
  date: Scalars['Date'];
  id: Scalars['String'];
  isPaid: Scalars['Boolean'];
  repeatingExpense: RepeatingExpense;
  repeatingExpenseId: Scalars['ID'];
}

export interface RepeatingExpense {
  __typename?: 'RepeatingExpense';
  amount: Scalars['Int'];
  category: Category;
  categoryId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  predecessorId?: Maybe<Scalars['ID']>;
  recurrencePattern: RecurrencePattern;
  recurrencePatternId: Scalars['ID'];
  supplier?: Maybe<Supplier>;
  supplierId?: Maybe<Scalars['ID']>;
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface RepeatingLaborExpense {
  __typename?: 'RepeatingLaborExpense';
  amount: Scalars['Int'];
  category?: Maybe<Category>;
  categoryId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  deletedById?: Maybe<Scalars['ID']>;
  employee: Employee;
  employeeId: Scalars['ID'];
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  predecessorId?: Maybe<Scalars['ID']>;
  recurrencePattern: RecurrencePattern;
  recurrencePatternId: Scalars['ID'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface Revenue {
  __typename?: 'Revenue';
  amount: Scalars['Int'];
  category: Category;
  categoryId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  date: Scalars['Date'];
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  mode: RevenueMode;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMethodId?: Maybe<Scalars['ID']>;
  revenueSource: RevenueSource;
  revenueSourceId: Scalars['ID'];
  timezone: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export enum RevenueMode {
  Day = 'DAY',
  Month = 'MONTH',
}

export interface RevenueSource {
  __typename?: 'RevenueSource';
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface RevenueTotalAmountBySourceObjectType {
  __typename?: 'RevenueTotalAmountBySourceObjectType';
  amount: Scalars['Int'];
  currencyCode: CurrencyCode;
  id: Scalars['ID'];
  precision: Scalars['Int'];
  revenueSourceId: Scalars['ID'];
  revenueSourceName: Scalars['String'];
}

export interface ScopeObjectType {
  __typename?: 'ScopeObjectType';
  currentUserId: Scalars['String'];
  currentVenueId: Scalars['String'];
  currentVenueName: Scalars['String'];
  userIds: Array<Scalars['String']>;
}

export interface SubscriptionData {
  __typename?: 'SubscriptionData';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  invoiceEmailAddress: Scalars['String'];
  invoiceRecognitionEnabled: Scalars['Boolean'];
  invoiceRecognitionSubscriptionStartDate: Scalars['Date'];
  invoiceRecognitionsAvailablePerMonth: Scalars['Int'];
}

export interface Supplier {
  __typename?: 'Supplier';
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  deletedById?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  tin?: Maybe<Scalars['String']>;
  updatedById?: Maybe<Scalars['ID']>;
  vatId?: Maybe<Scalars['String']>;
  venueId: Scalars['String'];
}

export interface SupplyExpense {
  __typename?: 'SupplyExpense';
  /** Identifies a date when this expense will be reflected in accounting */
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  category: Category;
  categoryId: Scalars['ID'];
  createdAt: Scalars['Date'];
  createdById: Scalars['ID'];
  currency: Currency;
  currencyCurrencyCode: CurrencyCode;
  deletedById?: Maybe<Scalars['ID']>;
  dueDate: Scalars['Date'];
  id: Scalars['ID'];
  invoiceNumber?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  payment?: Maybe<Payment>;
  paymentId?: Maybe<Scalars['ID']>;
  supplier: Supplier;
  supplierId: Scalars['ID'];
  timezone: Scalars['String'];
  updatedById?: Maybe<Scalars['ID']>;
  venueId: Scalars['ID'];
}

export interface TotalAmountObjectType {
  __typename?: 'TotalAmountObjectType';
  amount: Scalars['Int'];
  currencyCode: CurrencyCode;
  id: Scalars['ID'];
  precision: Scalars['Int'];
}

export interface UpdateRecurrencePatternInput {
  dayOfMonth?: InputMaybe<Scalars['Int']>;
  dayOfWeek?: InputMaybe<DayOfWeek>;
  endDate?: InputMaybe<Scalars['Date']>;
  interval?: InputMaybe<RecurrenceInterval>;
  monthOfYear?: InputMaybe<Scalars['Int']>;
  startDate: Scalars['Date'];
  timezone?: InputMaybe<Scalars['String']>;
}

export enum UploadError {
  FileTooBig = 'FILE_TOO_BIG',
  FormatNotSupported = 'FORMAT_NOT_SUPPORTED',
}

export enum UploadedInvoiceState {
  Approved = 'APPROVED',
  Error = 'ERROR',
  Failed = 'FAILED',
  FailedImport = 'FAILED_IMPORT',
  OverTheLimit = 'OVER_THE_LIMIT',
  Processed = 'PROCESSED',
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
  Uploaded = 'UPLOADED',
}

export interface User {
  __typename?: 'User';
  accountId: Scalars['ID'];
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  venue: Venue;
}

export interface Venue {
  __typename?: 'Venue';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  cultureCode: CultureCode;
  defaultTimezone: Scalars['String'];
  district?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  languageCode: CultureCode;
  name: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  subscriptionData: SubscriptionData;
}

export type CurrentUserFragment = {
  __typename?: 'CurrentUserObjectType';
  client_id: string;
  given_name: string;
  family_name: string;
  email: string;
  zoneinfo: string;
  cultureCode: CultureCode;
  languageCode: CultureCode;
  currency: {
    __typename?: 'Currency';
    currencyCode: CurrencyCode;
    precision: number;
  };
  scope: {
    __typename?: 'ScopeObjectType';
    currentVenueName: string;
    currentVenueId: string;
    currentUserId: string;
    userIds: Array<string>;
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename?: 'Mutation'; logout: boolean };

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'CurrentUserObjectType';
    client_id: string;
    given_name: string;
    family_name: string;
    email: string;
    zoneinfo: string;
    cultureCode: CultureCode;
    languageCode: CultureCode;
    currency: {
      __typename?: 'Currency';
      currencyCode: CurrencyCode;
      precision: number;
    };
    scope: {
      __typename?: 'ScopeObjectType';
      currentVenueName: string;
      currentVenueId: string;
      currentUserId: string;
      userIds: Array<string>;
    };
  };
};

export type SwitchVenueMutationVariables = Exact<{
  venueId: Scalars['ID'];
}>;

export type SwitchVenueMutation = {
  __typename?: 'Mutation';
  switchVenue: {
    __typename?: 'CurrentUserObjectType';
    client_id: string;
    given_name: string;
    family_name: string;
    email: string;
    zoneinfo: string;
    cultureCode: CultureCode;
    languageCode: CultureCode;
    currency: {
      __typename?: 'Currency';
      currencyCode: CurrencyCode;
      precision: number;
    };
    scope: {
      __typename?: 'ScopeObjectType';
      currentVenueName: string;
      currentVenueId: string;
      currentUserId: string;
      userIds: Array<string>;
    };
  };
};

export type CheckAuthStateMutationVariables = Exact<{ [key: string]: never }>;

export type CheckAuthStateMutation = {
  __typename?: 'Mutation';
  checkAuthState?: {
    __typename?: 'CurrentUserObjectType';
    client_id: string;
    given_name: string;
    family_name: string;
    email: string;
    zoneinfo: string;
    cultureCode: CultureCode;
    languageCode: CultureCode;
    currency: {
      __typename?: 'Currency';
      currencyCode: CurrencyCode;
      precision: number;
    };
    scope: {
      __typename?: 'ScopeObjectType';
      currentVenueName: string;
      currentVenueId: string;
      currentUserId: string;
      userIds: Array<string>;
    };
  } | null;
};

export type VenuesQueryVariables = Exact<{ [key: string]: never }>;

export type VenuesQuery = {
  __typename?: 'Query';
  venues: Array<{ __typename?: 'Venue'; id: string; name: string }>;
};

export type InvoiceUploadsInitialQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InvoiceUploadsInitialQuery = {
  __typename?: 'Query';
  invoiceUploads: Array<{
    __typename?: 'InvoiceUpload';
    id: string;
    items: Array<{
      __typename?: 'InvoiceUploadItem';
      state: UploadedInvoiceState;
      uploadError?: UploadError | null;
    }>;
  }>;
};

export type RevenuesTotalBySourceQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type RevenuesTotalBySourceQuery = {
  __typename?: 'Query';
  revenuesTotalBySource: Array<{
    __typename?: 'RevenueTotalAmountBySourceObjectType';
    revenueSourceName: string;
    revenueSourceId: string;
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  }>;
  revenuesTotal: {
    __typename?: 'TotalAmountObjectType';
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
};

export type StructuredTotalExpensesQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type StructuredTotalExpensesQuery = {
  __typename?: 'Query';
  structuredTotalExpenses: {
    __typename?: 'ExpenseStructuredTotalAmountObjectType';
    recurringExpensesTotalAmount: {
      __typename?: 'TotalAmountObjectType';
      amount: number;
      currencyCode: CurrencyCode;
      precision: number;
    };
    oneTimeExpensesTotalAmount: {
      __typename?: 'TotalAmountObjectType';
      amount: number;
      currencyCode: CurrencyCode;
      precision: number;
    };
    supplyExpensesTotalAmount: {
      __typename?: 'TotalAmountObjectType';
      amount: number;
      currencyCode: CurrencyCode;
      precision: number;
    };
  };
};

export type TotalExpensesByCategoryQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type TotalExpensesByCategoryQuery = {
  __typename?: 'Query';
  totalExpensesByCategory?: Array<{
    __typename?: 'ExpenseTotalByCategoryObjectType';
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
    categoryId: string;
    categoryName: string;
  }> | null;
  totalExpenses: {
    __typename?: 'TotalAmountObjectType';
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
};

export type SupplyExpensesTotalAmountQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
  startOfPrevMonth: Scalars['Date'];
  endOfPrevMonth: Scalars['Date'];
}>;

export type SupplyExpensesTotalAmountQuery = {
  __typename?: 'Query';
  supplyExpensesTotalAmount: {
    __typename?: 'TotalAmountObjectType';
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
  previousMonthSupplyExpensesTotalAmount: {
    __typename?: 'TotalAmountObjectType';
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
  revenuesTotal: {
    __typename?: 'TotalAmountObjectType';
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
  prevRevenuesTotal: {
    __typename?: 'TotalAmountObjectType';
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
};

export type LaborExpensesDashboardTotalAmountQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
  startOfPrevMonth: Scalars['Date'];
  endOfPrevMonth: Scalars['Date'];
}>;

export type LaborExpensesDashboardTotalAmountQuery = {
  __typename?: 'Query';
  laborExpensesTotalAmount: {
    __typename?: 'TotalAmountObjectType';
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
  previousMonthLaborExpensesTotalAmount: {
    __typename?: 'TotalAmountObjectType';
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
  revenuesTotal: {
    __typename?: 'TotalAmountObjectType';
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
  prevRevenuesTotal: {
    __typename?: 'TotalAmountObjectType';
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
};

export type TotalProfitQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type TotalProfitQuery = {
  __typename?: 'Query';
  totalProfit: {
    __typename?: 'TotalAmountObjectType';
    currencyCode: CurrencyCode;
    precision: number;
    amount: number;
  };
};

export type PaidExpensesQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type PaidExpensesQuery = {
  __typename?: 'Query';
  expensesAmountsByMonthWithPaidAmount: Array<{
    __typename?: 'ExpensesAmountsWithPaymentByMonthObjectType';
    currencyCode: CurrencyCode;
    precision: number;
    amount: number;
    isoMonth: number;
    isoYear: number;
    amountPaid: number;
  }>;
};

export type PaidLaborExpensesQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type PaidLaborExpensesQuery = {
  __typename?: 'Query';
  expensesAmountsByMonthWithPaidAmount: Array<{
    __typename?: 'ExpensesAmountsWithPaymentByMonthObjectType';
    currencyCode: CurrencyCode;
    precision: number;
    amount: number;
    isoMonth: number;
    isoYear: number;
    amountPaid: number;
  }>;
};

export type RevenuesVsExpenseCategoriesQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type RevenuesVsExpenseCategoriesQuery = {
  __typename?: 'Query';
  revenuesTotal: {
    __typename?: 'TotalAmountObjectType';
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
  totalExpenses: {
    __typename?: 'TotalAmountObjectType';
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
  totalExpensesByCategory?: Array<{
    __typename?: 'ExpenseTotalByCategoryObjectType';
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
    categoryId: string;
    categoryName: string;
  }> | null;
};

export type CreateSupplierMutationVariables = Exact<{
  name: Scalars['String'];
}>;

export type CreateSupplierMutation = {
  __typename?: 'Mutation';
  createSupplier: { __typename?: 'Supplier'; id: string };
};

export type CreateCategoryMutationVariables = Exact<{
  name: Scalars['String'];
}>;

export type CreateCategoryMutation = {
  __typename?: 'Mutation';
  createCategory: { __typename?: 'Category'; id: string };
};

export type AllExpensesQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type AllExpensesQuery = {
  __typename?: 'Query';
  oneTimeExpenses: {
    __typename?: 'PaginatedOneTimeExpenseObjectType';
    items: Array<{
      __typename?: 'OneTimeExpense';
      name: string;
      id: string;
      amount: number;
      paymentId?: string | null;
      link?: string | null;
      accountingDate: any;
      dueDate: any;
      currency: {
        __typename?: 'Currency';
        currencyCode: CurrencyCode;
        precision: number;
      };
      supplier?: { __typename?: 'Supplier'; id: string; name: string } | null;
      category: { __typename?: 'Category'; id: string; name: string };
    }>;
  };
  supplyExpenses: {
    __typename?: 'PaginatedSupplyExpenseObjectType';
    items: Array<{
      __typename?: 'SupplyExpense';
      id: string;
      amount: number;
      paymentId?: string | null;
      dueDate: any;
      accountingDate: any;
      invoiceNumber?: string | null;
      currency: {
        __typename?: 'Currency';
        currencyCode: CurrencyCode;
        precision: number;
      };
      supplier: { __typename?: 'Supplier'; id: string; name: string };
      category: { __typename?: 'Category'; id: string; name: string };
    }>;
  };
  recurringExpensesTimeSeries: Array<{
    __typename?: 'RecurringExpenseTimeSeriesObjectType';
    id: string;
    date: any;
    repeatingExpenseId: string;
    currencyCode: CurrencyCode;
    currencyPrecision: number;
    isPaid: boolean;
    repeatingExpense: {
      __typename?: 'RepeatingExpense';
      id: string;
      amount: number;
      name: string;
      category: { __typename?: 'Category'; id: string; name: string };
      supplier?: { __typename?: 'Supplier'; id: string; name: string } | null;
      recurrencePattern: {
        __typename?: 'RecurrencePattern';
        id: string;
        interval: RecurrenceInterval;
        startDate: any;
      };
    };
  }>;
  laborExpensesTimeSeries: Array<{
    __typename?: 'LaborExpenseTimeSeriesObjectType';
    id: string;
    employeeId: string;
    amount: number;
    date: any;
    isPaid: boolean;
    currencyCode: CurrencyCode;
    currencyPrecision: number;
    employee: {
      __typename?: 'Employee';
      id: string;
      firstName: string;
      lastName?: string | null;
    };
    laborExpense:
      | {
          __typename?: 'OneTimeLaborExpense';
          id: string;
          dueDate: any;
          paymentId?: string | null;
          accountingDate: any;
        }
      | {
          __typename?: 'RepeatingLaborExpense';
          id: string;
          recurrencePattern: {
            __typename?: 'RecurrencePattern';
            id: string;
            interval: RecurrenceInterval;
          };
        };
  }>;
};

export type TotalExpensesQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type TotalExpensesQuery = {
  __typename?: 'Query';
  totalExpenses: {
    __typename?: 'TotalAmountObjectType';
    id: string;
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
};

export type AllExpensesToPayQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type AllExpensesToPayQuery = {
  __typename?: 'Query';
  oneTimeExpenses: {
    __typename?: 'PaginatedOneTimeExpenseObjectType';
    items: Array<{
      __typename?: 'OneTimeExpense';
      name: string;
      id: string;
      amount: number;
      paymentId?: string | null;
      accountingDate: any;
      dueDate: any;
      link?: string | null;
      currency: {
        __typename?: 'Currency';
        currencyCode: CurrencyCode;
        precision: number;
      };
      supplier?: { __typename?: 'Supplier'; id: string; name: string } | null;
      category: { __typename?: 'Category'; id: string; name: string };
    }>;
  };
  supplyExpenses: {
    __typename?: 'PaginatedSupplyExpenseObjectType';
    items: Array<{
      __typename?: 'SupplyExpense';
      id: string;
      amount: number;
      paymentId?: string | null;
      dueDate: any;
      accountingDate: any;
      invoiceNumber?: string | null;
      link?: string | null;
      currency: {
        __typename?: 'Currency';
        currencyCode: CurrencyCode;
        precision: number;
      };
      supplier: { __typename?: 'Supplier'; id: string; name: string };
      category: { __typename?: 'Category'; id: string; name: string };
    }>;
  };
  recurringExpensesTimeSeries: Array<{
    __typename?: 'RecurringExpenseTimeSeriesObjectType';
    id: string;
    date: any;
    repeatingExpenseId: string;
    currencyCode: CurrencyCode;
    currencyPrecision: number;
    isPaid: boolean;
    repeatingExpense: {
      __typename?: 'RepeatingExpense';
      id: string;
      amount: number;
      name: string;
      category: { __typename?: 'Category'; id: string; name: string };
      supplier?: { __typename?: 'Supplier'; id: string; name: string } | null;
      recurrencePattern: {
        __typename?: 'RecurrencePattern';
        id: string;
        interval: RecurrenceInterval;
        startDate: any;
      };
    };
  }>;
  laborExpensesTimeSeries: Array<{
    __typename?: 'LaborExpenseTimeSeriesObjectType';
    id: string;
    employeeId: string;
    amount: number;
    date: any;
    isPaid: boolean;
    currencyCode: CurrencyCode;
    currencyPrecision: number;
    employee: {
      __typename?: 'Employee';
      id: string;
      firstName: string;
      lastName?: string | null;
    };
    laborExpense:
      | {
          __typename?: 'OneTimeLaborExpense';
          id: string;
          dueDate: any;
          accountingDate: any;
          note?: string | null;
          category?: { __typename?: 'Category'; name: string } | null;
        }
      | {
          __typename?: 'RepeatingLaborExpense';
          id: string;
          note?: string | null;
          category?: { __typename?: 'Category'; name: string } | null;
        };
  }>;
};

export type TotalExpensesToPayQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type TotalExpensesToPayQuery = {
  __typename?: 'Query';
  totalExpenses: {
    __typename?: 'TotalAmountObjectType';
    id: string;
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
};

export type CreateOneTimeLaborExpenseMutationVariables = Exact<{
  dueDate: Scalars['Date'];
  note?: InputMaybe<Scalars['String']>;
  employeeId: Scalars['ID'];
  amount: Scalars['Int'];
  accountingDate: Scalars['Date'];
}>;

export type CreateOneTimeLaborExpenseMutation = {
  __typename?: 'Mutation';
  createOneTimeLaborExpense: { __typename?: 'OneTimeLaborExpense'; id: string };
};

export type UpdateOneTimeLaborExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
  accountingDate?: InputMaybe<Scalars['Date']>;
  amount?: InputMaybe<Scalars['Int']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  employeeId?: InputMaybe<Scalars['ID']>;
  note?: InputMaybe<Scalars['String']>;
}>;

export type UpdateOneTimeLaborExpenseMutation = {
  __typename?: 'Mutation';
  updateOneTimeLaborExpense: { __typename?: 'OneTimeLaborExpense'; id: string };
};

export type CreateRepeatingLaborExpenseMutationVariables = Exact<{
  note?: InputMaybe<Scalars['String']>;
  employeeId: Scalars['ID'];
  amount: Scalars['Int'];
  startDate: Scalars['Date'];
  dayOfMonth: Scalars['Int'];
}>;

export type CreateRepeatingLaborExpenseMutation = {
  __typename?: 'Mutation';
  createRepeatingLaborExpense: {
    __typename?: 'RepeatingLaborExpense';
    id: string;
  };
};

export type CreateEmployeeMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
}>;

export type CreateEmployeeMutation = {
  __typename?: 'Mutation';
  createEmployee: {
    __typename?: 'Employee';
    id: string;
    firstName: string;
    lastName?: string | null;
  };
};

export type MarkOneTimeLaborExpensePayedMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MarkOneTimeLaborExpensePayedMutation = {
  __typename?: 'Mutation';
  markOneTimeLaborExpensePayed: {
    __typename?: 'OneTimeLaborExpense';
    id: string;
    paymentId?: string | null;
  };
};

export type UnmarkOneTimeLaborExpensePayedMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UnmarkOneTimeLaborExpensePayedMutation = {
  __typename?: 'Mutation';
  unmarkOneTimeLaborExpensePayed: {
    __typename?: 'OneTimeLaborExpense';
    id: string;
    paymentId?: string | null;
  };
};

export type RemoveOneTimeLaborExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveOneTimeLaborExpenseMutation = {
  __typename?: 'Mutation';
  removeOneTimeLaborExpense: boolean;
};

export type MarkRepeatingLaborExpensePayedMutationVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['Date'];
}>;

export type MarkRepeatingLaborExpensePayedMutation = {
  __typename?: 'Mutation';
  markRepeatingLaborExpensePaid: boolean;
};

export type UnmarkRepeatingLaborExpensePayedMutationVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['Date'];
}>;

export type UnmarkRepeatingLaborExpensePayedMutation = {
  __typename?: 'Mutation';
  unmarkRepeatingLaborExpensePaid: boolean;
};

export type RemoveRepeatingLaborExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveRepeatingLaborExpenseMutation = {
  __typename?: 'Mutation';
  removeRepeatingLaborExpense: boolean;
};

export type SkipRepeatingLaborExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['Date'];
}>;

export type SkipRepeatingLaborExpenseMutation = {
  __typename?: 'Mutation';
  skipRepeatingLaborExpense: {
    __typename?: 'RepeatingLaborExpense';
    id: string;
  };
};

export type TerminateRepeatingLaborExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['Date'];
}>;

export type TerminateRepeatingLaborExpenseMutation = {
  __typename?: 'Mutation';
  terminateRepeatingLaborExpense: {
    __typename?: 'RepeatingLaborExpense';
    id: string;
  };
};

export type MarkLaborExpensesAsPaidMutationVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
  expenseIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type MarkLaborExpensesAsPaidMutation = {
  __typename?: 'Mutation';
  markLaborExpensesAsPaid: boolean;
};

export type UnmarkLaborExpensesAsPaidMutationVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
  expenseIds: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type UnmarkLaborExpensesAsPaidMutation = {
  __typename?: 'Mutation';
  unmarkLaborExpensesAsPaid: boolean;
};

export type LaborExpensesTimeSeriesQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type LaborExpensesTimeSeriesQuery = {
  __typename?: 'Query';
  laborExpensesTimeSeries: Array<{
    __typename?: 'LaborExpenseTimeSeriesObjectType';
    id: string;
    employeeId: string;
    amount: number;
    date: any;
    isPaid: boolean;
    currencyCode: CurrencyCode;
    currencyPrecision: number;
    employee: {
      __typename?: 'Employee';
      id: string;
      firstName: string;
      lastName?: string | null;
    };
    laborExpense:
      | {
          __typename?: 'OneTimeLaborExpense';
          id: string;
          dueDate: any;
          accountingDate: any;
          note?: string | null;
        }
      | {
          __typename?: 'RepeatingLaborExpense';
          id: string;
          note?: string | null;
        };
  }>;
};

export type LaborExpensesTotalAmountQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
  dateFilterType?: InputMaybe<ExpenseDateFilterType>;
}>;

export type LaborExpensesTotalAmountQuery = {
  __typename?: 'Query';
  laborExpensesTotalAmount: {
    __typename?: 'TotalAmountObjectType';
    currencyCode: CurrencyCode;
    precision: number;
    id: string;
    amount: number;
  };
};

export type OneTimeLaborExpenseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OneTimeLaborExpenseQuery = {
  __typename?: 'Query';
  oneTimeLaborExpense: {
    __typename?: 'OneTimeLaborExpense';
    id: string;
    dueDate: any;
    note?: string | null;
    employeeId: string;
    amount: number;
    accountingDate: any;
  };
};

export type RepeatingLaborExpenseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RepeatingLaborExpenseQuery = {
  __typename?: 'Query';
  repeatingLaborExpense: {
    __typename?: 'RepeatingLaborExpense';
    note?: string | null;
    employeeId: string;
    amount: number;
    recurrencePattern: { __typename?: 'RecurrencePattern'; startDate: any };
  };
};

export type EmployeesQueryVariables = Exact<{ [key: string]: never }>;

export type EmployeesQuery = {
  __typename?: 'Query';
  employees: {
    __typename?: 'PaginatedEmployeeObjectType';
    items: Array<{
      __typename?: 'Employee';
      id: string;
      firstName: string;
      lastName?: string | null;
    }>;
  };
};

export type CreateOneTimeExpenseMutationVariables = Exact<{
  name: Scalars['String'];
  amount: Scalars['Int'];
  categoryId: Scalars['ID'];
  dueDate: Scalars['Date'];
  accountingDate: Scalars['Date'];
  supplierId?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<Scalars['String']>;
}>;

export type CreateOneTimeExpenseMutation = {
  __typename?: 'Mutation';
  createOneTimeExpense: { __typename?: 'OneTimeExpense'; id: string };
};

export type UpdateOneTimeExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['ID']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  accountingDate?: InputMaybe<Scalars['Date']>;
  link?: InputMaybe<Scalars['String']>;
}>;

export type UpdateOneTimeExpenseMutation = {
  __typename?: 'Mutation';
  updateOneTimeExpense: {
    __typename?: 'OneTimeExpense';
    id: string;
    amount: number;
    supplierId?: string | null;
    categoryId: string;
    dueDate: any;
    name: string;
    accountingDate: any;
  };
};

export type MarkOneTimeExpensePayedMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MarkOneTimeExpensePayedMutation = {
  __typename?: 'Mutation';
  markOneTimeExpensePayed: {
    __typename?: 'OneTimeExpense';
    id: string;
    paymentId?: string | null;
  };
};

export type UnmarkOneTimeExpensePayedMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UnmarkOneTimeExpensePayedMutation = {
  __typename?: 'Mutation';
  unmarkOneTimeExpensePayed: {
    __typename?: 'OneTimeExpense';
    id: string;
    paymentId?: string | null;
  };
};

export type RemoveOneTimeExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveOneTimeExpenseMutation = {
  __typename?: 'Mutation';
  removeOneTimeExpense: boolean;
};

export type OneTimeExpensesTotalAmountQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type OneTimeExpensesTotalAmountQuery = {
  __typename?: 'Query';
  oneTimeExpensesTotalAmount: {
    __typename?: 'TotalAmountObjectType';
    currencyCode: CurrencyCode;
    precision: number;
    id: string;
    amount: number;
  };
};

export type OneTimeExpensesQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type OneTimeExpensesQuery = {
  __typename?: 'Query';
  oneTimeExpenses: {
    __typename?: 'PaginatedOneTimeExpenseObjectType';
    items: Array<{
      __typename?: 'OneTimeExpense';
      name: string;
      id: string;
      amount: number;
      paymentId?: string | null;
      link?: string | null;
      accountingDate: any;
      dueDate: any;
      currency: {
        __typename?: 'Currency';
        currencyCode: CurrencyCode;
        precision: number;
      };
      supplier?: { __typename?: 'Supplier'; id: string; name: string } | null;
      category: { __typename?: 'Category'; id: string; name: string };
    }>;
  };
};

export type OneTimeExpenseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OneTimeExpenseQuery = {
  __typename?: 'Query';
  oneTimeExpense: {
    __typename?: 'OneTimeExpense';
    id: string;
    amount: number;
    name: string;
    supplierId?: string | null;
    categoryId: string;
    paymentId?: string | null;
    accountingDate: any;
    dueDate: any;
    link?: string | null;
    currency: {
      __typename?: 'Currency';
      currencyCode: CurrencyCode;
      precision: number;
    };
  };
};

export type CreateRepeatingExpenseMutationVariables = Exact<{
  amount: Scalars['Int'];
  name: Scalars['String'];
  categoryId: Scalars['ID'];
  startDate: Scalars['Date'];
  dayOfMonth: Scalars['Int'];
  supplierId?: InputMaybe<Scalars['ID']>;
}>;

export type CreateRepeatingExpenseMutation = {
  __typename?: 'Mutation';
  createRepeatingExpense: { __typename?: 'RepeatingExpense'; id: string };
};

export type MarkRepeatingExpensePaidMutationVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['Date'];
}>;

export type MarkRepeatingExpensePaidMutation = {
  __typename?: 'Mutation';
  markRepeatingExpensePaid: boolean;
};

export type UnmarkRepeatingExpensePaidMutationVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['Date'];
}>;

export type UnmarkRepeatingExpensePaidMutation = {
  __typename?: 'Mutation';
  unmarkRepeatingExpensePaid: boolean;
};

export type SkipRepeatingExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['Date'];
}>;

export type SkipRepeatingExpenseMutation = {
  __typename?: 'Mutation';
  skipRepeatingExpense: { __typename?: 'RepeatingExpense'; id: string };
};

export type TerminateRepeatingExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
  date: Scalars['Date'];
}>;

export type TerminateRepeatingExpenseMutation = {
  __typename?: 'Mutation';
  terminateRepeatingExpense: { __typename?: 'RepeatingExpense'; id: string };
};

export type RemoveRepeatingExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveRepeatingExpenseMutation = {
  __typename?: 'Mutation';
  removeRepeatingExpense: boolean;
};

export type RecurringExpensesTimeSeriesQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type RecurringExpensesTimeSeriesQuery = {
  __typename?: 'Query';
  recurringExpensesTimeSeries: Array<{
    __typename?: 'RecurringExpenseTimeSeriesObjectType';
    id: string;
    date: any;
    repeatingExpenseId: string;
    currencyCode: CurrencyCode;
    currencyPrecision: number;
    isPaid: boolean;
    repeatingExpense: {
      __typename?: 'RepeatingExpense';
      id: string;
      amount: number;
      name: string;
      category: { __typename?: 'Category'; id: string; name: string };
      supplier?: { __typename?: 'Supplier'; id: string; name: string } | null;
      recurrencePattern: {
        __typename?: 'RecurrencePattern';
        id: string;
        interval: RecurrenceInterval;
        startDate: any;
      };
    };
  }>;
};

export type RecurringExpensesTotalAmountQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type RecurringExpensesTotalAmountQuery = {
  __typename?: 'Query';
  recurringExpensesTotalAmount: {
    __typename?: 'TotalAmountObjectType';
    currencyCode: CurrencyCode;
    precision: number;
    id: string;
    amount: number;
  };
};

export type CategoriesAndSuppliersQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CategoriesAndSuppliersQuery = {
  __typename?: 'Query';
  categories: {
    __typename?: 'PaginatedCategoryObjectType';
    items: Array<{ __typename?: 'Category'; id: string; name: string }>;
  };
  suppliers: {
    __typename?: 'PaginatedSupplierObjectType';
    items: Array<{ __typename?: 'Supplier'; id: string; name: string }>;
  };
};

export type CreateSupplyExpenseMutationVariables = Exact<{
  amount: Scalars['Int'];
  dueDate: Scalars['Date'];
  accountingDate: Scalars['Date'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  supplierId: Scalars['ID'];
  categoryId: Scalars['ID'];
  link?: InputMaybe<Scalars['String']>;
}>;

export type CreateSupplyExpenseMutation = {
  __typename?: 'Mutation';
  createSupplyExpense: { __typename?: 'SupplyExpense'; id: string };
};

export type UpdateSupplyExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
  amount?: InputMaybe<Scalars['Int']>;
  supplierId?: InputMaybe<Scalars['ID']>;
  categoryId?: InputMaybe<Scalars['ID']>;
  dueDate?: InputMaybe<Scalars['Date']>;
  accountingDate?: InputMaybe<Scalars['Date']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
}>;

export type UpdateSupplyExpenseMutation = {
  __typename?: 'Mutation';
  updateSupplyExpense: {
    __typename?: 'SupplyExpense';
    id: string;
    amount: number;
    supplierId: string;
    categoryId: string;
    dueDate: any;
    accountingDate: any;
    invoiceNumber?: string | null;
    link?: string | null;
  };
};

export type MarkSupplyExpensePayedMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type MarkSupplyExpensePayedMutation = {
  __typename?: 'Mutation';
  markSupplyExpensePayed: {
    __typename?: 'SupplyExpense';
    id: string;
    paymentId?: string | null;
  };
};

export type UnmarkSupplyExpensePayedMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UnmarkSupplyExpensePayedMutation = {
  __typename?: 'Mutation';
  unmarkSupplyExpensePayed: {
    __typename?: 'SupplyExpense';
    id: string;
    paymentId?: string | null;
  };
};

export type SuppliesExpensesTotalAmountQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type SuppliesExpensesTotalAmountQuery = {
  __typename?: 'Query';
  supplyExpensesTotalAmount: {
    __typename?: 'TotalAmountObjectType';
    id: string;
    amount: number;
    currencyCode: CurrencyCode;
    precision: number;
  };
};

export type SuppliesExpensesQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type SuppliesExpensesQuery = {
  __typename?: 'Query';
  supplyExpenses: {
    __typename?: 'PaginatedSupplyExpenseObjectType';
    items: Array<{
      __typename?: 'SupplyExpense';
      id: string;
      amount: number;
      paymentId?: string | null;
      dueDate: any;
      accountingDate: any;
      invoiceNumber?: string | null;
      link?: string | null;
      currency: {
        __typename?: 'Currency';
        currencyCode: CurrencyCode;
        precision: number;
      };
      supplier: { __typename?: 'Supplier'; id: string; name: string };
      category: { __typename?: 'Category'; id: string; name: string };
    }>;
  };
};

export type SupplyExpenseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type SupplyExpenseQuery = {
  __typename?: 'Query';
  supplyExpense: {
    __typename?: 'SupplyExpense';
    id: string;
    amount: number;
    invoiceNumber?: string | null;
    supplierId: string;
    categoryId: string;
    paymentId?: string | null;
    accountingDate: any;
    dueDate: any;
    link?: string | null;
    currency: {
      __typename?: 'Currency';
      currencyCode: CurrencyCode;
      precision: number;
    };
  };
};

export type RemoveSupplyExpenseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveSupplyExpenseMutation = {
  __typename?: 'Mutation';
  removeSupplyExpense: boolean;
};

export type UploadInvoiceMutationVariables = Exact<{
  files: Array<Scalars['Upload']> | Scalars['Upload'];
}>;

export type UploadInvoiceMutation = {
  __typename?: 'Mutation';
  uploadInvoice: { __typename?: 'InvoiceUpload'; id: string };
};

export type ConvertOcrExpenseToOneTimeExpenseMutationVariables = Exact<{
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  categoryId: Scalars['ID'];
  dueDate: Scalars['Date'];
  link?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  ocrExpenseId: Scalars['ID'];
  supplierId?: InputMaybe<Scalars['ID']>;
  timezone?: InputMaybe<Scalars['String']>;
}>;

export type ConvertOcrExpenseToOneTimeExpenseMutation = {
  __typename?: 'Mutation';
  convertOcrExpenseToOneTimeExpense: boolean;
};

export type ConvertOcrExpenseToSupplyExpenseMutationVariables = Exact<{
  accountingDate: Scalars['Date'];
  amount: Scalars['Int'];
  categoryId: Scalars['ID'];
  dueDate: Scalars['Date'];
  invoiceNumber?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  ocrExpenseId: Scalars['ID'];
  supplierId: Scalars['ID'];
  timezone?: InputMaybe<Scalars['String']>;
}>;

export type ConvertOcrExpenseToSupplyExpenseMutation = {
  __typename?: 'Mutation';
  convertOcrExpenseToSupplyExpense: boolean;
};

export type InvoiceUploadsQueryVariables = Exact<{ [key: string]: never }>;

export type InvoiceUploadsQuery = {
  __typename?: 'Query';
  invoiceUploads: Array<{
    __typename?: 'InvoiceUpload';
    id: string;
    createdAt: any;
    uploadMethod: InvoiceUploadMethod;
    manualUploadMeta?: {
      __typename?: 'ManualInvoiceUploadMeta';
      id: string;
      createdBy: {
        __typename?: 'Account';
        firstName: string;
        lastName: string;
      };
    } | null;
    emailUploadMeta?: {
      __typename?: 'EmailInvoiceUploadMeta';
      receivedAt: any;
      receivedFrom: string;
    } | null;
    items: Array<{
      __typename?: 'InvoiceUploadItem';
      id: string;
      fileName?: string | null;
      fileSize: number;
      mimeType: string;
      state: UploadedInvoiceState;
      uploadError?: UploadError | null;
      bucket?: string | null;
      key?: string | null;
      invoiceUploadId: string;
      ocrExpense?: { __typename?: 'OcrExpense'; id: string } | null;
    }>;
  }>;
};

export type OcrExpenseQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type OcrExpenseQuery = {
  __typename?: 'Query';
  ocrExpense: {
    __typename?: 'OcrExpense';
    id: string;
    accountingDate: any;
    dueDate: any;
    amount: number;
    createdAt: any;
    invoiceNumber?: string | null;
    supplierId: string;
    currency: {
      __typename?: 'Currency';
      currencyCode: CurrencyCode;
      precision: number;
    };
  };
};

export type UpdateCurrentAccountMutationVariables = Exact<{
  cultureCode?: InputMaybe<CultureCode>;
  defaultUserId?: InputMaybe<Scalars['ID']>;
  firstName: Scalars['String'];
  languageCode?: InputMaybe<CultureCode>;
  lastName: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
}>;

export type UpdateCurrentAccountMutation = {
  __typename?: 'Mutation';
  updateCurrentAccount: {
    __typename?: 'Account';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    languageCode?: CultureCode | null;
    cultureCode?: CultureCode | null;
    defaultUserId?: string | null;
  };
};

export type CurrentUserAccountQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserAccountQuery = {
  __typename?: 'Query';
  currentUserAccount: {
    __typename?: 'Account';
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    languageCode?: CultureCode | null;
    cultureCode?: CultureCode | null;
    defaultUserId?: string | null;
    users: Array<{
      __typename?: 'User';
      id: string;
      venue: { __typename?: 'Venue'; id: string; name: string };
    }>;
  };
};

export type AddRevenueMutationVariables = Exact<{
  amount: Scalars['Int'];
  date: Scalars['Date'];
  revenueSourceId: Scalars['ID'];
}>;

export type AddRevenueMutation = {
  __typename?: 'Mutation';
  addRevenue: { __typename?: 'Revenue'; id: string };
};

export type UpdateRevenueMutationVariables = Exact<{
  id: Scalars['ID'];
  amount?: InputMaybe<Scalars['Int']>;
  date?: InputMaybe<Scalars['Date']>;
  revenueSourceId?: InputMaybe<Scalars['ID']>;
}>;

export type UpdateRevenueMutation = {
  __typename?: 'Mutation';
  updateRevenue: {
    __typename?: 'Revenue';
    id: string;
    amount: number;
    date: any;
    revenueSourceId: string;
  };
};

export type RemoveRevenueMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RemoveRevenueMutation = {
  __typename?: 'Mutation';
  removeRevenue: boolean;
};

export type CreateRevenueSourceMutationVariables = Exact<{
  name: Scalars['String'];
}>;

export type CreateRevenueSourceMutation = {
  __typename?: 'Mutation';
  createRevenueSource: { __typename?: 'RevenueSource'; id: string };
};

export type RevenueSourcesQueryVariables = Exact<{ [key: string]: never }>;

export type RevenueSourcesQuery = {
  __typename?: 'Query';
  revenueSources: {
    __typename?: 'PaginatedRevenueSourceObjectType';
    items: Array<{ __typename?: 'RevenueSource'; id: string; name: string }>;
  };
};

export type RevenuesQueryVariables = Exact<{
  from: Scalars['Date'];
  to: Scalars['Date'];
}>;

export type RevenuesQuery = {
  __typename?: 'Query';
  revenues: {
    __typename?: 'PaginatedRevenueObjectType';
    items: Array<{
      __typename?: 'Revenue';
      id: string;
      date: any;
      amount: number;
      currency: {
        __typename?: 'Currency';
        currencyCode: CurrencyCode;
        precision: number;
      };
      revenueSource: { __typename?: 'RevenueSource'; id: string; name: string };
    }>;
  };
};

export type RevenueWithSourcesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type RevenueWithSourcesQuery = {
  __typename?: 'Query';
  revenue: {
    __typename?: 'Revenue';
    id: string;
    amount: number;
    date: any;
    revenueSourceId: string;
    currency: {
      __typename?: 'Currency';
      currencyCode: CurrencyCode;
      precision: number;
    };
  };
  revenueSources: {
    __typename?: 'PaginatedRevenueSourceObjectType';
    items: Array<{ __typename?: 'RevenueSource'; id: string; name: string }>;
  };
};

export const CurrentUserFragmentDoc = gql`
  fragment CurrentUser on CurrentUserObjectType {
    client_id
    given_name
    family_name
    email
    zoneinfo
    cultureCode
    languageCode
    currency {
      currencyCode
      precision
    }
    scope {
      currentVenueName
      currentVenueId
      currentUserId
      userIds
    }
  }
`;
export const LogoutDocument = gql`
  mutation Logout {
    logout
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<
  LogoutMutation,
  LogoutMutationVariables
>;
export type LogoutComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    LogoutMutation,
    LogoutMutationVariables
  >,
  'mutation'
>;

export const LogoutComponent = (props: LogoutComponentProps) => (
  <ApolloReactComponents.Mutation<LogoutMutation, LogoutMutationVariables>
    mutation={LogoutDocument}
    {...props}
  />
);

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LogoutMutation,
    LogoutMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(
    LogoutDocument,
    options,
  );
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<
  LogoutMutation,
  LogoutMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;
export type LoginComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    LoginMutation,
    LoginMutationVariables
  >,
  'mutation'
>;

export const LoginComponent = (props: LoginComponentProps) => (
  <ApolloReactComponents.Mutation<LoginMutation, LoginMutationVariables>
    mutation={LoginDocument}
    {...props}
  />
);

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options,
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const SwitchVenueDocument = gql`
  mutation SwitchVenue($venueId: ID!) {
    switchVenue(targetVenueId: $venueId) {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;
export type SwitchVenueMutationFn = Apollo.MutationFunction<
  SwitchVenueMutation,
  SwitchVenueMutationVariables
>;
export type SwitchVenueComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SwitchVenueMutation,
    SwitchVenueMutationVariables
  >,
  'mutation'
>;

export const SwitchVenueComponent = (props: SwitchVenueComponentProps) => (
  <ApolloReactComponents.Mutation<
    SwitchVenueMutation,
    SwitchVenueMutationVariables
  >
    mutation={SwitchVenueDocument}
    {...props}
  />
);

/**
 * __useSwitchVenueMutation__
 *
 * To run a mutation, you first call `useSwitchVenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchVenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchVenueMutation, { data, loading, error }] = useSwitchVenueMutation({
 *   variables: {
 *      venueId: // value for 'venueId'
 *   },
 * });
 */
export function useSwitchVenueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SwitchVenueMutation,
    SwitchVenueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SwitchVenueMutation,
    SwitchVenueMutationVariables
  >(SwitchVenueDocument, options);
}
export type SwitchVenueMutationHookResult = ReturnType<
  typeof useSwitchVenueMutation
>;
export type SwitchVenueMutationResult =
  Apollo.MutationResult<SwitchVenueMutation>;
export type SwitchVenueMutationOptions = Apollo.BaseMutationOptions<
  SwitchVenueMutation,
  SwitchVenueMutationVariables
>;
export const CheckAuthStateDocument = gql`
  mutation CheckAuthState {
    checkAuthState {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;
export type CheckAuthStateMutationFn = Apollo.MutationFunction<
  CheckAuthStateMutation,
  CheckAuthStateMutationVariables
>;
export type CheckAuthStateComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CheckAuthStateMutation,
    CheckAuthStateMutationVariables
  >,
  'mutation'
>;

export const CheckAuthStateComponent = (
  props: CheckAuthStateComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CheckAuthStateMutation,
    CheckAuthStateMutationVariables
  >
    mutation={CheckAuthStateDocument}
    {...props}
  />
);

/**
 * __useCheckAuthStateMutation__
 *
 * To run a mutation, you first call `useCheckAuthStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckAuthStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkAuthStateMutation, { data, loading, error }] = useCheckAuthStateMutation({
 *   variables: {
 *   },
 * });
 */
export function useCheckAuthStateMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CheckAuthStateMutation,
    CheckAuthStateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CheckAuthStateMutation,
    CheckAuthStateMutationVariables
  >(CheckAuthStateDocument, options);
}
export type CheckAuthStateMutationHookResult = ReturnType<
  typeof useCheckAuthStateMutation
>;
export type CheckAuthStateMutationResult =
  Apollo.MutationResult<CheckAuthStateMutation>;
export type CheckAuthStateMutationOptions = Apollo.BaseMutationOptions<
  CheckAuthStateMutation,
  CheckAuthStateMutationVariables
>;
export const VenuesDocument = gql`
  query Venues {
    venues {
      id
      name
    }
  }
`;
export type VenuesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    VenuesQuery,
    VenuesQueryVariables
  >,
  'query'
>;

export const VenuesComponent = (props: VenuesComponentProps) => (
  <ApolloReactComponents.Query<VenuesQuery, VenuesQueryVariables>
    query={VenuesDocument}
    {...props}
  />
);

/**
 * __useVenuesQuery__
 *
 * To run a query within a React component, call `useVenuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useVenuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVenuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useVenuesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    VenuesQuery,
    VenuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<VenuesQuery, VenuesQueryVariables>(
    VenuesDocument,
    options,
  );
}
export function useVenuesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VenuesQuery,
    VenuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<VenuesQuery, VenuesQueryVariables>(
    VenuesDocument,
    options,
  );
}
export type VenuesQueryHookResult = ReturnType<typeof useVenuesQuery>;
export type VenuesLazyQueryHookResult = ReturnType<typeof useVenuesLazyQuery>;
export type VenuesQueryResult = Apollo.QueryResult<
  VenuesQuery,
  VenuesQueryVariables
>;
export function refetchVenuesQuery(variables?: VenuesQueryVariables) {
  return { query: VenuesDocument, variables: variables };
}
export const InvoiceUploadsInitialQueryDocument = gql`
  query InvoiceUploadsInitialQuery {
    invoiceUploads {
      id
      items {
        state
        uploadError
      }
    }
  }
`;
export type InvoiceUploadsInitialQueryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    InvoiceUploadsInitialQuery,
    InvoiceUploadsInitialQueryVariables
  >,
  'query'
>;

export const InvoiceUploadsInitialQueryComponent = (
  props: InvoiceUploadsInitialQueryComponentProps,
) => (
  <ApolloReactComponents.Query<
    InvoiceUploadsInitialQuery,
    InvoiceUploadsInitialQueryVariables
  >
    query={InvoiceUploadsInitialQueryDocument}
    {...props}
  />
);

/**
 * __useInvoiceUploadsInitialQuery__
 *
 * To run a query within a React component, call `useInvoiceUploadsInitialQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceUploadsInitialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceUploadsInitialQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoiceUploadsInitialQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    InvoiceUploadsInitialQuery,
    InvoiceUploadsInitialQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    InvoiceUploadsInitialQuery,
    InvoiceUploadsInitialQueryVariables
  >(InvoiceUploadsInitialQueryDocument, options);
}
export function useInvoiceUploadsInitialQueryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InvoiceUploadsInitialQuery,
    InvoiceUploadsInitialQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    InvoiceUploadsInitialQuery,
    InvoiceUploadsInitialQueryVariables
  >(InvoiceUploadsInitialQueryDocument, options);
}
export type InvoiceUploadsInitialQueryHookResult = ReturnType<
  typeof useInvoiceUploadsInitialQuery
>;
export type InvoiceUploadsInitialQueryLazyQueryHookResult = ReturnType<
  typeof useInvoiceUploadsInitialQueryLazyQuery
>;
export type InvoiceUploadsInitialQueryQueryResult = Apollo.QueryResult<
  InvoiceUploadsInitialQuery,
  InvoiceUploadsInitialQueryVariables
>;
export function refetchInvoiceUploadsInitialQuery(
  variables?: InvoiceUploadsInitialQueryVariables,
) {
  return { query: InvoiceUploadsInitialQueryDocument, variables: variables };
}
export const RevenuesTotalBySourceDocument = gql`
  query RevenuesTotalBySource($from: Date!, $to: Date!) {
    revenuesTotalBySource(from: $from, to: $to) {
      revenueSourceName
      revenueSourceId
      amount
      currencyCode
      precision
    }
    revenuesTotal(from: $from, to: $to) {
      amount
      currencyCode
      precision
    }
  }
`;
export type RevenuesTotalBySourceComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    RevenuesTotalBySourceQuery,
    RevenuesTotalBySourceQueryVariables
  >,
  'query'
> &
  (
    | { variables: RevenuesTotalBySourceQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const RevenuesTotalBySourceComponent = (
  props: RevenuesTotalBySourceComponentProps,
) => (
  <ApolloReactComponents.Query<
    RevenuesTotalBySourceQuery,
    RevenuesTotalBySourceQueryVariables
  >
    query={RevenuesTotalBySourceDocument}
    {...props}
  />
);

/**
 * __useRevenuesTotalBySourceQuery__
 *
 * To run a query within a React component, call `useRevenuesTotalBySourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenuesTotalBySourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenuesTotalBySourceQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useRevenuesTotalBySourceQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    RevenuesTotalBySourceQuery,
    RevenuesTotalBySourceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    RevenuesTotalBySourceQuery,
    RevenuesTotalBySourceQueryVariables
  >(RevenuesTotalBySourceDocument, options);
}
export function useRevenuesTotalBySourceLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RevenuesTotalBySourceQuery,
    RevenuesTotalBySourceQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    RevenuesTotalBySourceQuery,
    RevenuesTotalBySourceQueryVariables
  >(RevenuesTotalBySourceDocument, options);
}
export type RevenuesTotalBySourceQueryHookResult = ReturnType<
  typeof useRevenuesTotalBySourceQuery
>;
export type RevenuesTotalBySourceLazyQueryHookResult = ReturnType<
  typeof useRevenuesTotalBySourceLazyQuery
>;
export type RevenuesTotalBySourceQueryResult = Apollo.QueryResult<
  RevenuesTotalBySourceQuery,
  RevenuesTotalBySourceQueryVariables
>;
export function refetchRevenuesTotalBySourceQuery(
  variables: RevenuesTotalBySourceQueryVariables,
) {
  return { query: RevenuesTotalBySourceDocument, variables: variables };
}
export const StructuredTotalExpensesDocument = gql`
  query StructuredTotalExpenses($from: Date!, $to: Date!) {
    structuredTotalExpenses(
      from: $from
      to: $to
      dateFilterType: AccountingDate
    ) {
      recurringExpensesTotalAmount {
        amount
        currencyCode
        precision
      }
      oneTimeExpensesTotalAmount {
        amount
        currencyCode
        precision
      }
      supplyExpensesTotalAmount {
        amount
        currencyCode
        precision
      }
    }
  }
`;
export type StructuredTotalExpensesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    StructuredTotalExpensesQuery,
    StructuredTotalExpensesQueryVariables
  >,
  'query'
> &
  (
    | { variables: StructuredTotalExpensesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const StructuredTotalExpensesComponent = (
  props: StructuredTotalExpensesComponentProps,
) => (
  <ApolloReactComponents.Query<
    StructuredTotalExpensesQuery,
    StructuredTotalExpensesQueryVariables
  >
    query={StructuredTotalExpensesDocument}
    {...props}
  />
);

/**
 * __useStructuredTotalExpensesQuery__
 *
 * To run a query within a React component, call `useStructuredTotalExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStructuredTotalExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStructuredTotalExpensesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useStructuredTotalExpensesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    StructuredTotalExpensesQuery,
    StructuredTotalExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    StructuredTotalExpensesQuery,
    StructuredTotalExpensesQueryVariables
  >(StructuredTotalExpensesDocument, options);
}
export function useStructuredTotalExpensesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    StructuredTotalExpensesQuery,
    StructuredTotalExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    StructuredTotalExpensesQuery,
    StructuredTotalExpensesQueryVariables
  >(StructuredTotalExpensesDocument, options);
}
export type StructuredTotalExpensesQueryHookResult = ReturnType<
  typeof useStructuredTotalExpensesQuery
>;
export type StructuredTotalExpensesLazyQueryHookResult = ReturnType<
  typeof useStructuredTotalExpensesLazyQuery
>;
export type StructuredTotalExpensesQueryResult = Apollo.QueryResult<
  StructuredTotalExpensesQuery,
  StructuredTotalExpensesQueryVariables
>;
export function refetchStructuredTotalExpensesQuery(
  variables: StructuredTotalExpensesQueryVariables,
) {
  return { query: StructuredTotalExpensesDocument, variables: variables };
}
export const TotalExpensesByCategoryDocument = gql`
  query TotalExpensesByCategory($from: Date!, $to: Date!) {
    totalExpensesByCategory(
      from: $from
      to: $to
      dateFilterType: AccountingDate
    ) {
      amount
      currencyCode
      precision
      categoryId
      categoryName
    }
    totalExpenses(from: $from, to: $to, dateFilterType: AccountingDate) {
      amount
      currencyCode
      precision
    }
  }
`;
export type TotalExpensesByCategoryComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TotalExpensesByCategoryQuery,
    TotalExpensesByCategoryQueryVariables
  >,
  'query'
> &
  (
    | { variables: TotalExpensesByCategoryQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const TotalExpensesByCategoryComponent = (
  props: TotalExpensesByCategoryComponentProps,
) => (
  <ApolloReactComponents.Query<
    TotalExpensesByCategoryQuery,
    TotalExpensesByCategoryQueryVariables
  >
    query={TotalExpensesByCategoryDocument}
    {...props}
  />
);

/**
 * __useTotalExpensesByCategoryQuery__
 *
 * To run a query within a React component, call `useTotalExpensesByCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalExpensesByCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalExpensesByCategoryQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useTotalExpensesByCategoryQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    TotalExpensesByCategoryQuery,
    TotalExpensesByCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    TotalExpensesByCategoryQuery,
    TotalExpensesByCategoryQueryVariables
  >(TotalExpensesByCategoryDocument, options);
}
export function useTotalExpensesByCategoryLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TotalExpensesByCategoryQuery,
    TotalExpensesByCategoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    TotalExpensesByCategoryQuery,
    TotalExpensesByCategoryQueryVariables
  >(TotalExpensesByCategoryDocument, options);
}
export type TotalExpensesByCategoryQueryHookResult = ReturnType<
  typeof useTotalExpensesByCategoryQuery
>;
export type TotalExpensesByCategoryLazyQueryHookResult = ReturnType<
  typeof useTotalExpensesByCategoryLazyQuery
>;
export type TotalExpensesByCategoryQueryResult = Apollo.QueryResult<
  TotalExpensesByCategoryQuery,
  TotalExpensesByCategoryQueryVariables
>;
export function refetchTotalExpensesByCategoryQuery(
  variables: TotalExpensesByCategoryQueryVariables,
) {
  return { query: TotalExpensesByCategoryDocument, variables: variables };
}
export const SupplyExpensesTotalAmountDocument = gql`
  query SupplyExpensesTotalAmount(
    $from: Date!
    $to: Date!
    $startOfPrevMonth: Date!
    $endOfPrevMonth: Date!
  ) {
    supplyExpensesTotalAmount(from: $from, to: $to) {
      amount
      currencyCode
      precision
    }
    previousMonthSupplyExpensesTotalAmount: supplyExpensesTotalAmount(
      from: $startOfPrevMonth
      to: $endOfPrevMonth
    ) {
      amount
      currencyCode
      precision
    }
    revenuesTotal(from: $from, to: $to) {
      amount
      currencyCode
      precision
    }
    prevRevenuesTotal: revenuesTotal(
      from: $startOfPrevMonth
      to: $endOfPrevMonth
    ) {
      amount
      currencyCode
      precision
    }
  }
`;
export type SupplyExpensesTotalAmountComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SupplyExpensesTotalAmountQuery,
    SupplyExpensesTotalAmountQueryVariables
  >,
  'query'
> &
  (
    | { variables: SupplyExpensesTotalAmountQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const SupplyExpensesTotalAmountComponent = (
  props: SupplyExpensesTotalAmountComponentProps,
) => (
  <ApolloReactComponents.Query<
    SupplyExpensesTotalAmountQuery,
    SupplyExpensesTotalAmountQueryVariables
  >
    query={SupplyExpensesTotalAmountDocument}
    {...props}
  />
);

/**
 * __useSupplyExpensesTotalAmountQuery__
 *
 * To run a query within a React component, call `useSupplyExpensesTotalAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplyExpensesTotalAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplyExpensesTotalAmountQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      startOfPrevMonth: // value for 'startOfPrevMonth'
 *      endOfPrevMonth: // value for 'endOfPrevMonth'
 *   },
 * });
 */
export function useSupplyExpensesTotalAmountQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SupplyExpensesTotalAmountQuery,
    SupplyExpensesTotalAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SupplyExpensesTotalAmountQuery,
    SupplyExpensesTotalAmountQueryVariables
  >(SupplyExpensesTotalAmountDocument, options);
}
export function useSupplyExpensesTotalAmountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SupplyExpensesTotalAmountQuery,
    SupplyExpensesTotalAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SupplyExpensesTotalAmountQuery,
    SupplyExpensesTotalAmountQueryVariables
  >(SupplyExpensesTotalAmountDocument, options);
}
export type SupplyExpensesTotalAmountQueryHookResult = ReturnType<
  typeof useSupplyExpensesTotalAmountQuery
>;
export type SupplyExpensesTotalAmountLazyQueryHookResult = ReturnType<
  typeof useSupplyExpensesTotalAmountLazyQuery
>;
export type SupplyExpensesTotalAmountQueryResult = Apollo.QueryResult<
  SupplyExpensesTotalAmountQuery,
  SupplyExpensesTotalAmountQueryVariables
>;
export function refetchSupplyExpensesTotalAmountQuery(
  variables: SupplyExpensesTotalAmountQueryVariables,
) {
  return { query: SupplyExpensesTotalAmountDocument, variables: variables };
}
export const LaborExpensesDashboardTotalAmountDocument = gql`
  query LaborExpensesDashboardTotalAmount(
    $from: Date!
    $to: Date!
    $startOfPrevMonth: Date!
    $endOfPrevMonth: Date!
  ) {
    laborExpensesTotalAmount(
      from: $from
      to: $to
      dateFilterType: AccountingDate
    ) {
      amount
      currencyCode
      precision
    }
    previousMonthLaborExpensesTotalAmount: laborExpensesTotalAmount(
      from: $startOfPrevMonth
      to: $endOfPrevMonth
      dateFilterType: AccountingDate
    ) {
      amount
      currencyCode
      precision
    }
    revenuesTotal(from: $from, to: $to) {
      amount
      currencyCode
      precision
    }
    prevRevenuesTotal: revenuesTotal(
      from: $startOfPrevMonth
      to: $endOfPrevMonth
    ) {
      amount
      currencyCode
      precision
    }
  }
`;
export type LaborExpensesDashboardTotalAmountComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LaborExpensesDashboardTotalAmountQuery,
    LaborExpensesDashboardTotalAmountQueryVariables
  >,
  'query'
> &
  (
    | {
        variables: LaborExpensesDashboardTotalAmountQueryVariables;
        skip?: boolean;
      }
    | { skip: boolean }
  );

export const LaborExpensesDashboardTotalAmountComponent = (
  props: LaborExpensesDashboardTotalAmountComponentProps,
) => (
  <ApolloReactComponents.Query<
    LaborExpensesDashboardTotalAmountQuery,
    LaborExpensesDashboardTotalAmountQueryVariables
  >
    query={LaborExpensesDashboardTotalAmountDocument}
    {...props}
  />
);

/**
 * __useLaborExpensesDashboardTotalAmountQuery__
 *
 * To run a query within a React component, call `useLaborExpensesDashboardTotalAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLaborExpensesDashboardTotalAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLaborExpensesDashboardTotalAmountQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      startOfPrevMonth: // value for 'startOfPrevMonth'
 *      endOfPrevMonth: // value for 'endOfPrevMonth'
 *   },
 * });
 */
export function useLaborExpensesDashboardTotalAmountQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LaborExpensesDashboardTotalAmountQuery,
    LaborExpensesDashboardTotalAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    LaborExpensesDashboardTotalAmountQuery,
    LaborExpensesDashboardTotalAmountQueryVariables
  >(LaborExpensesDashboardTotalAmountDocument, options);
}
export function useLaborExpensesDashboardTotalAmountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LaborExpensesDashboardTotalAmountQuery,
    LaborExpensesDashboardTotalAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LaborExpensesDashboardTotalAmountQuery,
    LaborExpensesDashboardTotalAmountQueryVariables
  >(LaborExpensesDashboardTotalAmountDocument, options);
}
export type LaborExpensesDashboardTotalAmountQueryHookResult = ReturnType<
  typeof useLaborExpensesDashboardTotalAmountQuery
>;
export type LaborExpensesDashboardTotalAmountLazyQueryHookResult = ReturnType<
  typeof useLaborExpensesDashboardTotalAmountLazyQuery
>;
export type LaborExpensesDashboardTotalAmountQueryResult = Apollo.QueryResult<
  LaborExpensesDashboardTotalAmountQuery,
  LaborExpensesDashboardTotalAmountQueryVariables
>;
export function refetchLaborExpensesDashboardTotalAmountQuery(
  variables: LaborExpensesDashboardTotalAmountQueryVariables,
) {
  return {
    query: LaborExpensesDashboardTotalAmountDocument,
    variables: variables,
  };
}
export const TotalProfitDocument = gql`
  query TotalProfit($from: Date!, $to: Date!) {
    totalProfit(from: $from, to: $to) {
      currencyCode
      precision
      amount
    }
  }
`;
export type TotalProfitComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TotalProfitQuery,
    TotalProfitQueryVariables
  >,
  'query'
> &
  (
    | { variables: TotalProfitQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const TotalProfitComponent = (props: TotalProfitComponentProps) => (
  <ApolloReactComponents.Query<TotalProfitQuery, TotalProfitQueryVariables>
    query={TotalProfitDocument}
    {...props}
  />
);

/**
 * __useTotalProfitQuery__
 *
 * To run a query within a React component, call `useTotalProfitQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalProfitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalProfitQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useTotalProfitQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    TotalProfitQuery,
    TotalProfitQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<TotalProfitQuery, TotalProfitQueryVariables>(
    TotalProfitDocument,
    options,
  );
}
export function useTotalProfitLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TotalProfitQuery,
    TotalProfitQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    TotalProfitQuery,
    TotalProfitQueryVariables
  >(TotalProfitDocument, options);
}
export type TotalProfitQueryHookResult = ReturnType<typeof useTotalProfitQuery>;
export type TotalProfitLazyQueryHookResult = ReturnType<
  typeof useTotalProfitLazyQuery
>;
export type TotalProfitQueryResult = Apollo.QueryResult<
  TotalProfitQuery,
  TotalProfitQueryVariables
>;
export function refetchTotalProfitQuery(variables: TotalProfitQueryVariables) {
  return { query: TotalProfitDocument, variables: variables };
}
export const PaidExpensesDocument = gql`
  query PaidExpenses($from: Date!, $to: Date!) {
    expensesAmountsByMonthWithPaidAmount(
      from: $from
      to: $to
      dateFilterType: AccountingDate
      supplyExpenses: true
      oneTimeExpenses: true
      recurringExpenses: true
    ) {
      currencyCode
      precision
      amount
      isoMonth
      isoYear
      amountPaid
    }
  }
`;
export type PaidExpensesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PaidExpensesQuery,
    PaidExpensesQueryVariables
  >,
  'query'
> &
  (
    | { variables: PaidExpensesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const PaidExpensesComponent = (props: PaidExpensesComponentProps) => (
  <ApolloReactComponents.Query<PaidExpensesQuery, PaidExpensesQueryVariables>
    query={PaidExpensesDocument}
    {...props}
  />
);

/**
 * __usePaidExpensesQuery__
 *
 * To run a query within a React component, call `usePaidExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaidExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaidExpensesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function usePaidExpensesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PaidExpensesQuery,
    PaidExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PaidExpensesQuery,
    PaidExpensesQueryVariables
  >(PaidExpensesDocument, options);
}
export function usePaidExpensesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaidExpensesQuery,
    PaidExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PaidExpensesQuery,
    PaidExpensesQueryVariables
  >(PaidExpensesDocument, options);
}
export type PaidExpensesQueryHookResult = ReturnType<
  typeof usePaidExpensesQuery
>;
export type PaidExpensesLazyQueryHookResult = ReturnType<
  typeof usePaidExpensesLazyQuery
>;
export type PaidExpensesQueryResult = Apollo.QueryResult<
  PaidExpensesQuery,
  PaidExpensesQueryVariables
>;
export function refetchPaidExpensesQuery(
  variables: PaidExpensesQueryVariables,
) {
  return { query: PaidExpensesDocument, variables: variables };
}
export const PaidLaborExpensesDocument = gql`
  query PaidLaborExpenses($from: Date!, $to: Date!) {
    expensesAmountsByMonthWithPaidAmount(
      from: $from
      to: $to
      dateFilterType: AccountingDate
      oneTimeLaborExpenses: true
      repeatingLaborExpenses: true
    ) {
      currencyCode
      precision
      amount
      isoMonth
      isoYear
      amountPaid
    }
  }
`;
export type PaidLaborExpensesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    PaidLaborExpensesQuery,
    PaidLaborExpensesQueryVariables
  >,
  'query'
> &
  (
    | { variables: PaidLaborExpensesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const PaidLaborExpensesComponent = (
  props: PaidLaborExpensesComponentProps,
) => (
  <ApolloReactComponents.Query<
    PaidLaborExpensesQuery,
    PaidLaborExpensesQueryVariables
  >
    query={PaidLaborExpensesDocument}
    {...props}
  />
);

/**
 * __usePaidLaborExpensesQuery__
 *
 * To run a query within a React component, call `usePaidLaborExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaidLaborExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaidLaborExpensesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function usePaidLaborExpensesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    PaidLaborExpensesQuery,
    PaidLaborExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    PaidLaborExpensesQuery,
    PaidLaborExpensesQueryVariables
  >(PaidLaborExpensesDocument, options);
}
export function usePaidLaborExpensesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    PaidLaborExpensesQuery,
    PaidLaborExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    PaidLaborExpensesQuery,
    PaidLaborExpensesQueryVariables
  >(PaidLaborExpensesDocument, options);
}
export type PaidLaborExpensesQueryHookResult = ReturnType<
  typeof usePaidLaborExpensesQuery
>;
export type PaidLaborExpensesLazyQueryHookResult = ReturnType<
  typeof usePaidLaborExpensesLazyQuery
>;
export type PaidLaborExpensesQueryResult = Apollo.QueryResult<
  PaidLaborExpensesQuery,
  PaidLaborExpensesQueryVariables
>;
export function refetchPaidLaborExpensesQuery(
  variables: PaidLaborExpensesQueryVariables,
) {
  return { query: PaidLaborExpensesDocument, variables: variables };
}
export const RevenuesVsExpenseCategoriesDocument = gql`
  query RevenuesVsExpenseCategories($from: Date!, $to: Date!) {
    revenuesTotal(from: $from, to: $to) {
      amount
      currencyCode
      precision
    }
    totalExpenses(from: $from, to: $to, dateFilterType: AccountingDate) {
      amount
      currencyCode
      precision
    }
    totalExpensesByCategory(
      from: $from
      to: $to
      dateFilterType: AccountingDate
    ) {
      amount
      currencyCode
      precision
      categoryId
      categoryName
    }
  }
`;
export type RevenuesVsExpenseCategoriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    RevenuesVsExpenseCategoriesQuery,
    RevenuesVsExpenseCategoriesQueryVariables
  >,
  'query'
> &
  (
    | { variables: RevenuesVsExpenseCategoriesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const RevenuesVsExpenseCategoriesComponent = (
  props: RevenuesVsExpenseCategoriesComponentProps,
) => (
  <ApolloReactComponents.Query<
    RevenuesVsExpenseCategoriesQuery,
    RevenuesVsExpenseCategoriesQueryVariables
  >
    query={RevenuesVsExpenseCategoriesDocument}
    {...props}
  />
);

/**
 * __useRevenuesVsExpenseCategoriesQuery__
 *
 * To run a query within a React component, call `useRevenuesVsExpenseCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenuesVsExpenseCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenuesVsExpenseCategoriesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useRevenuesVsExpenseCategoriesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    RevenuesVsExpenseCategoriesQuery,
    RevenuesVsExpenseCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    RevenuesVsExpenseCategoriesQuery,
    RevenuesVsExpenseCategoriesQueryVariables
  >(RevenuesVsExpenseCategoriesDocument, options);
}
export function useRevenuesVsExpenseCategoriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RevenuesVsExpenseCategoriesQuery,
    RevenuesVsExpenseCategoriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    RevenuesVsExpenseCategoriesQuery,
    RevenuesVsExpenseCategoriesQueryVariables
  >(RevenuesVsExpenseCategoriesDocument, options);
}
export type RevenuesVsExpenseCategoriesQueryHookResult = ReturnType<
  typeof useRevenuesVsExpenseCategoriesQuery
>;
export type RevenuesVsExpenseCategoriesLazyQueryHookResult = ReturnType<
  typeof useRevenuesVsExpenseCategoriesLazyQuery
>;
export type RevenuesVsExpenseCategoriesQueryResult = Apollo.QueryResult<
  RevenuesVsExpenseCategoriesQuery,
  RevenuesVsExpenseCategoriesQueryVariables
>;
export function refetchRevenuesVsExpenseCategoriesQuery(
  variables: RevenuesVsExpenseCategoriesQueryVariables,
) {
  return { query: RevenuesVsExpenseCategoriesDocument, variables: variables };
}
export const CreateSupplierDocument = gql`
  mutation createSupplier($name: String!) {
    createSupplier(name: $name) {
      id
    }
  }
`;
export type CreateSupplierMutationFn = Apollo.MutationFunction<
  CreateSupplierMutation,
  CreateSupplierMutationVariables
>;
export type CreateSupplierComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateSupplierMutation,
    CreateSupplierMutationVariables
  >,
  'mutation'
>;

export const CreateSupplierComponent = (
  props: CreateSupplierComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CreateSupplierMutation,
    CreateSupplierMutationVariables
  >
    mutation={CreateSupplierDocument}
    {...props}
  />
);

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateSupplierMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSupplierMutation,
    CreateSupplierMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateSupplierMutation,
    CreateSupplierMutationVariables
  >(CreateSupplierDocument, options);
}
export type CreateSupplierMutationHookResult = ReturnType<
  typeof useCreateSupplierMutation
>;
export type CreateSupplierMutationResult =
  Apollo.MutationResult<CreateSupplierMutation>;
export type CreateSupplierMutationOptions = Apollo.BaseMutationOptions<
  CreateSupplierMutation,
  CreateSupplierMutationVariables
>;
export const CreateCategoryDocument = gql`
  mutation CreateCategory($name: String!) {
    createCategory(name: $name) {
      id
    }
  }
`;
export type CreateCategoryMutationFn = Apollo.MutationFunction<
  CreateCategoryMutation,
  CreateCategoryMutationVariables
>;
export type CreateCategoryComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateCategoryMutation,
    CreateCategoryMutationVariables
  >,
  'mutation'
>;

export const CreateCategoryComponent = (
  props: CreateCategoryComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CreateCategoryMutation,
    CreateCategoryMutationVariables
  >
    mutation={CreateCategoryDocument}
    {...props}
  />
);

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCategoryMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateCategoryMutation,
    CreateCategoryMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateCategoryMutation,
    CreateCategoryMutationVariables
  >(CreateCategoryDocument, options);
}
export type CreateCategoryMutationHookResult = ReturnType<
  typeof useCreateCategoryMutation
>;
export type CreateCategoryMutationResult =
  Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<
  CreateCategoryMutation,
  CreateCategoryMutationVariables
>;
export const AllExpensesDocument = gql`
  query AllExpenses($from: Date!, $to: Date!) {
    oneTimeExpenses(dateFilter: { from: $from, to: $to }) {
      items {
        name
        id
        amount
        paymentId
        link
        currency {
          currencyCode
          precision
        }
        accountingDate
        dueDate
        supplier {
          id
          name
        }
        category {
          id
          name
        }
      }
    }
    supplyExpenses(dateFilter: { from: $from, to: $to }) {
      items {
        id
        amount
        currency {
          currencyCode
          precision
        }
        supplier {
          id
          name
        }
        category {
          id
          name
        }
        paymentId
        dueDate
        accountingDate
        invoiceNumber
      }
    }
    recurringExpensesTimeSeries(from: $from, to: $to) {
      id
      date
      repeatingExpenseId
      currencyCode
      currencyPrecision
      isPaid
      repeatingExpense {
        id
        category {
          id
          name
        }
        supplier {
          id
          name
        }
        amount
        name
        recurrencePattern {
          id
          interval
          startDate
        }
      }
    }
    laborExpensesTimeSeries(from: $from, to: $to) {
      id
      employeeId
      employee {
        id
        firstName
        lastName
      }
      amount
      date
      isPaid
      currencyCode
      currencyPrecision
      laborExpense {
        ... on OneTimeLaborExpense {
          id
          dueDate
          paymentId
          accountingDate
        }
        ... on RepeatingLaborExpense {
          id
          recurrencePattern {
            id
            interval
          }
        }
      }
    }
  }
`;
export type AllExpensesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllExpensesQuery,
    AllExpensesQueryVariables
  >,
  'query'
> &
  (
    | { variables: AllExpensesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const AllExpensesComponent = (props: AllExpensesComponentProps) => (
  <ApolloReactComponents.Query<AllExpensesQuery, AllExpensesQueryVariables>
    query={AllExpensesDocument}
    {...props}
  />
);

/**
 * __useAllExpensesQuery__
 *
 * To run a query within a React component, call `useAllExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllExpensesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useAllExpensesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AllExpensesQuery,
    AllExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<AllExpensesQuery, AllExpensesQueryVariables>(
    AllExpensesDocument,
    options,
  );
}
export function useAllExpensesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllExpensesQuery,
    AllExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AllExpensesQuery,
    AllExpensesQueryVariables
  >(AllExpensesDocument, options);
}
export type AllExpensesQueryHookResult = ReturnType<typeof useAllExpensesQuery>;
export type AllExpensesLazyQueryHookResult = ReturnType<
  typeof useAllExpensesLazyQuery
>;
export type AllExpensesQueryResult = Apollo.QueryResult<
  AllExpensesQuery,
  AllExpensesQueryVariables
>;
export function refetchAllExpensesQuery(variables: AllExpensesQueryVariables) {
  return { query: AllExpensesDocument, variables: variables };
}
export const TotalExpensesDocument = gql`
  query TotalExpenses($from: Date!, $to: Date!) {
    totalExpenses(from: $from, to: $to, dateFilterType: AccountingDate) {
      id
      amount
      currencyCode
      precision
    }
  }
`;
export type TotalExpensesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TotalExpensesQuery,
    TotalExpensesQueryVariables
  >,
  'query'
> &
  (
    | { variables: TotalExpensesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const TotalExpensesComponent = (props: TotalExpensesComponentProps) => (
  <ApolloReactComponents.Query<TotalExpensesQuery, TotalExpensesQueryVariables>
    query={TotalExpensesDocument}
    {...props}
  />
);

/**
 * __useTotalExpensesQuery__
 *
 * To run a query within a React component, call `useTotalExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalExpensesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useTotalExpensesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    TotalExpensesQuery,
    TotalExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    TotalExpensesQuery,
    TotalExpensesQueryVariables
  >(TotalExpensesDocument, options);
}
export function useTotalExpensesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TotalExpensesQuery,
    TotalExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    TotalExpensesQuery,
    TotalExpensesQueryVariables
  >(TotalExpensesDocument, options);
}
export type TotalExpensesQueryHookResult = ReturnType<
  typeof useTotalExpensesQuery
>;
export type TotalExpensesLazyQueryHookResult = ReturnType<
  typeof useTotalExpensesLazyQuery
>;
export type TotalExpensesQueryResult = Apollo.QueryResult<
  TotalExpensesQuery,
  TotalExpensesQueryVariables
>;
export function refetchTotalExpensesQuery(
  variables: TotalExpensesQueryVariables,
) {
  return { query: TotalExpensesDocument, variables: variables };
}
export const AllExpensesToPayDocument = gql`
  query AllExpensesToPay($from: Date!, $to: Date!) {
    oneTimeExpenses(
      dateFilter: { from: $from, to: $to }
      dateFilterType: DueDate
    ) {
      items {
        name
        id
        amount
        paymentId
        currency {
          currencyCode
          precision
        }
        accountingDate
        dueDate
        link
        supplier {
          id
          name
        }
        category {
          id
          name
        }
      }
    }
    supplyExpenses(
      dateFilter: { from: $from, to: $to }
      dateFilterType: DueDate
    ) {
      items {
        id
        amount
        currency {
          currencyCode
          precision
        }
        supplier {
          id
          name
        }
        category {
          id
          name
        }
        paymentId
        dueDate
        accountingDate
        invoiceNumber
        link
      }
    }
    recurringExpensesTimeSeries(from: $from, to: $to) {
      id
      date
      repeatingExpenseId
      currencyCode
      currencyPrecision
      isPaid
      repeatingExpense {
        id
        category {
          id
          name
        }
        supplier {
          id
          name
        }
        amount
        name
        recurrencePattern {
          id
          interval
          startDate
        }
      }
    }
    laborExpensesTimeSeries(from: $from, to: $to, dateFilterType: DueDate) {
      id
      employeeId
      employee {
        id
        firstName
        lastName
      }
      amount
      date
      isPaid
      currencyCode
      currencyPrecision
      laborExpense {
        ... on OneTimeLaborExpense {
          id
          dueDate
          accountingDate
          note
          category {
            name
          }
        }
        ... on RepeatingLaborExpense {
          id
          category {
            name
          }
          note
        }
      }
    }
  }
`;
export type AllExpensesToPayComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    AllExpensesToPayQuery,
    AllExpensesToPayQueryVariables
  >,
  'query'
> &
  (
    | { variables: AllExpensesToPayQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const AllExpensesToPayComponent = (
  props: AllExpensesToPayComponentProps,
) => (
  <ApolloReactComponents.Query<
    AllExpensesToPayQuery,
    AllExpensesToPayQueryVariables
  >
    query={AllExpensesToPayDocument}
    {...props}
  />
);

/**
 * __useAllExpensesToPayQuery__
 *
 * To run a query within a React component, call `useAllExpensesToPayQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllExpensesToPayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllExpensesToPayQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useAllExpensesToPayQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    AllExpensesToPayQuery,
    AllExpensesToPayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    AllExpensesToPayQuery,
    AllExpensesToPayQueryVariables
  >(AllExpensesToPayDocument, options);
}
export function useAllExpensesToPayLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AllExpensesToPayQuery,
    AllExpensesToPayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    AllExpensesToPayQuery,
    AllExpensesToPayQueryVariables
  >(AllExpensesToPayDocument, options);
}
export type AllExpensesToPayQueryHookResult = ReturnType<
  typeof useAllExpensesToPayQuery
>;
export type AllExpensesToPayLazyQueryHookResult = ReturnType<
  typeof useAllExpensesToPayLazyQuery
>;
export type AllExpensesToPayQueryResult = Apollo.QueryResult<
  AllExpensesToPayQuery,
  AllExpensesToPayQueryVariables
>;
export function refetchAllExpensesToPayQuery(
  variables: AllExpensesToPayQueryVariables,
) {
  return { query: AllExpensesToPayDocument, variables: variables };
}
export const TotalExpensesToPayDocument = gql`
  query TotalExpensesToPay($from: Date!, $to: Date!) {
    totalExpenses(from: $from, to: $to, dateFilterType: DueDate) {
      id
      amount
      currencyCode
      precision
    }
  }
`;
export type TotalExpensesToPayComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    TotalExpensesToPayQuery,
    TotalExpensesToPayQueryVariables
  >,
  'query'
> &
  (
    | { variables: TotalExpensesToPayQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const TotalExpensesToPayComponent = (
  props: TotalExpensesToPayComponentProps,
) => (
  <ApolloReactComponents.Query<
    TotalExpensesToPayQuery,
    TotalExpensesToPayQueryVariables
  >
    query={TotalExpensesToPayDocument}
    {...props}
  />
);

/**
 * __useTotalExpensesToPayQuery__
 *
 * To run a query within a React component, call `useTotalExpensesToPayQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalExpensesToPayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalExpensesToPayQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useTotalExpensesToPayQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    TotalExpensesToPayQuery,
    TotalExpensesToPayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    TotalExpensesToPayQuery,
    TotalExpensesToPayQueryVariables
  >(TotalExpensesToPayDocument, options);
}
export function useTotalExpensesToPayLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    TotalExpensesToPayQuery,
    TotalExpensesToPayQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    TotalExpensesToPayQuery,
    TotalExpensesToPayQueryVariables
  >(TotalExpensesToPayDocument, options);
}
export type TotalExpensesToPayQueryHookResult = ReturnType<
  typeof useTotalExpensesToPayQuery
>;
export type TotalExpensesToPayLazyQueryHookResult = ReturnType<
  typeof useTotalExpensesToPayLazyQuery
>;
export type TotalExpensesToPayQueryResult = Apollo.QueryResult<
  TotalExpensesToPayQuery,
  TotalExpensesToPayQueryVariables
>;
export function refetchTotalExpensesToPayQuery(
  variables: TotalExpensesToPayQueryVariables,
) {
  return { query: TotalExpensesToPayDocument, variables: variables };
}
export const CreateOneTimeLaborExpenseDocument = gql`
  mutation CreateOneTimeLaborExpense(
    $dueDate: Date!
    $note: String
    $employeeId: ID!
    $amount: Int!
    $accountingDate: Date!
  ) {
    createOneTimeLaborExpense(
      note: $note
      dueDate: $dueDate
      employeeId: $employeeId
      amount: $amount
      accountingDate: $accountingDate
    ) {
      id
    }
  }
`;
export type CreateOneTimeLaborExpenseMutationFn = Apollo.MutationFunction<
  CreateOneTimeLaborExpenseMutation,
  CreateOneTimeLaborExpenseMutationVariables
>;
export type CreateOneTimeLaborExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateOneTimeLaborExpenseMutation,
    CreateOneTimeLaborExpenseMutationVariables
  >,
  'mutation'
>;

export const CreateOneTimeLaborExpenseComponent = (
  props: CreateOneTimeLaborExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CreateOneTimeLaborExpenseMutation,
    CreateOneTimeLaborExpenseMutationVariables
  >
    mutation={CreateOneTimeLaborExpenseDocument}
    {...props}
  />
);

/**
 * __useCreateOneTimeLaborExpenseMutation__
 *
 * To run a mutation, you first call `useCreateOneTimeLaborExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTimeLaborExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneTimeLaborExpenseMutation, { data, loading, error }] = useCreateOneTimeLaborExpenseMutation({
 *   variables: {
 *      dueDate: // value for 'dueDate'
 *      note: // value for 'note'
 *      employeeId: // value for 'employeeId'
 *      amount: // value for 'amount'
 *      accountingDate: // value for 'accountingDate'
 *   },
 * });
 */
export function useCreateOneTimeLaborExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOneTimeLaborExpenseMutation,
    CreateOneTimeLaborExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateOneTimeLaborExpenseMutation,
    CreateOneTimeLaborExpenseMutationVariables
  >(CreateOneTimeLaborExpenseDocument, options);
}
export type CreateOneTimeLaborExpenseMutationHookResult = ReturnType<
  typeof useCreateOneTimeLaborExpenseMutation
>;
export type CreateOneTimeLaborExpenseMutationResult =
  Apollo.MutationResult<CreateOneTimeLaborExpenseMutation>;
export type CreateOneTimeLaborExpenseMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOneTimeLaborExpenseMutation,
    CreateOneTimeLaborExpenseMutationVariables
  >;
export const UpdateOneTimeLaborExpenseDocument = gql`
  mutation UpdateOneTimeLaborExpense(
    $id: ID!
    $accountingDate: Date
    $amount: Int
    $dueDate: Date
    $employeeId: ID
    $note: String
  ) {
    updateOneTimeLaborExpense(
      id: $id
      accountingDate: $accountingDate
      amount: $amount
      dueDate: $dueDate
      employeeId: $employeeId
      note: $note
    ) {
      id
    }
  }
`;
export type UpdateOneTimeLaborExpenseMutationFn = Apollo.MutationFunction<
  UpdateOneTimeLaborExpenseMutation,
  UpdateOneTimeLaborExpenseMutationVariables
>;
export type UpdateOneTimeLaborExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateOneTimeLaborExpenseMutation,
    UpdateOneTimeLaborExpenseMutationVariables
  >,
  'mutation'
>;

export const UpdateOneTimeLaborExpenseComponent = (
  props: UpdateOneTimeLaborExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UpdateOneTimeLaborExpenseMutation,
    UpdateOneTimeLaborExpenseMutationVariables
  >
    mutation={UpdateOneTimeLaborExpenseDocument}
    {...props}
  />
);

/**
 * __useUpdateOneTimeLaborExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateOneTimeLaborExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneTimeLaborExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneTimeLaborExpenseMutation, { data, loading, error }] = useUpdateOneTimeLaborExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      accountingDate: // value for 'accountingDate'
 *      amount: // value for 'amount'
 *      dueDate: // value for 'dueDate'
 *      employeeId: // value for 'employeeId'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useUpdateOneTimeLaborExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOneTimeLaborExpenseMutation,
    UpdateOneTimeLaborExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateOneTimeLaborExpenseMutation,
    UpdateOneTimeLaborExpenseMutationVariables
  >(UpdateOneTimeLaborExpenseDocument, options);
}
export type UpdateOneTimeLaborExpenseMutationHookResult = ReturnType<
  typeof useUpdateOneTimeLaborExpenseMutation
>;
export type UpdateOneTimeLaborExpenseMutationResult =
  Apollo.MutationResult<UpdateOneTimeLaborExpenseMutation>;
export type UpdateOneTimeLaborExpenseMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateOneTimeLaborExpenseMutation,
    UpdateOneTimeLaborExpenseMutationVariables
  >;
export const CreateRepeatingLaborExpenseDocument = gql`
  mutation CreateRepeatingLaborExpense(
    $note: String
    $employeeId: ID!
    $amount: Int!
    $startDate: Date!
    $dayOfMonth: Int!
  ) {
    createRepeatingLaborExpense(
      amount: $amount
      employeeId: $employeeId
      note: $note
      recurrencePattern: {
        interval: MONTH
        dayOfMonth: $dayOfMonth
        startDate: $startDate
      }
    ) {
      id
    }
  }
`;
export type CreateRepeatingLaborExpenseMutationFn = Apollo.MutationFunction<
  CreateRepeatingLaborExpenseMutation,
  CreateRepeatingLaborExpenseMutationVariables
>;
export type CreateRepeatingLaborExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateRepeatingLaborExpenseMutation,
    CreateRepeatingLaborExpenseMutationVariables
  >,
  'mutation'
>;

export const CreateRepeatingLaborExpenseComponent = (
  props: CreateRepeatingLaborExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CreateRepeatingLaborExpenseMutation,
    CreateRepeatingLaborExpenseMutationVariables
  >
    mutation={CreateRepeatingLaborExpenseDocument}
    {...props}
  />
);

/**
 * __useCreateRepeatingLaborExpenseMutation__
 *
 * To run a mutation, you first call `useCreateRepeatingLaborExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRepeatingLaborExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRepeatingLaborExpenseMutation, { data, loading, error }] = useCreateRepeatingLaborExpenseMutation({
 *   variables: {
 *      note: // value for 'note'
 *      employeeId: // value for 'employeeId'
 *      amount: // value for 'amount'
 *      startDate: // value for 'startDate'
 *      dayOfMonth: // value for 'dayOfMonth'
 *   },
 * });
 */
export function useCreateRepeatingLaborExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateRepeatingLaborExpenseMutation,
    CreateRepeatingLaborExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateRepeatingLaborExpenseMutation,
    CreateRepeatingLaborExpenseMutationVariables
  >(CreateRepeatingLaborExpenseDocument, options);
}
export type CreateRepeatingLaborExpenseMutationHookResult = ReturnType<
  typeof useCreateRepeatingLaborExpenseMutation
>;
export type CreateRepeatingLaborExpenseMutationResult =
  Apollo.MutationResult<CreateRepeatingLaborExpenseMutation>;
export type CreateRepeatingLaborExpenseMutationOptions =
  Apollo.BaseMutationOptions<
    CreateRepeatingLaborExpenseMutation,
    CreateRepeatingLaborExpenseMutationVariables
  >;
export const CreateEmployeeDocument = gql`
  mutation CreateEmployee($firstName: String!, $lastName: String) {
    createEmployee(firstName: $firstName, lastName: $lastName) {
      id
      firstName
      lastName
    }
  }
`;
export type CreateEmployeeMutationFn = Apollo.MutationFunction<
  CreateEmployeeMutation,
  CreateEmployeeMutationVariables
>;
export type CreateEmployeeComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateEmployeeMutation,
    CreateEmployeeMutationVariables
  >,
  'mutation'
>;

export const CreateEmployeeComponent = (
  props: CreateEmployeeComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CreateEmployeeMutation,
    CreateEmployeeMutationVariables
  >
    mutation={CreateEmployeeDocument}
    {...props}
  />
);

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useCreateEmployeeMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateEmployeeMutation,
    CreateEmployeeMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateEmployeeMutation,
    CreateEmployeeMutationVariables
  >(CreateEmployeeDocument, options);
}
export type CreateEmployeeMutationHookResult = ReturnType<
  typeof useCreateEmployeeMutation
>;
export type CreateEmployeeMutationResult =
  Apollo.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<
  CreateEmployeeMutation,
  CreateEmployeeMutationVariables
>;
export const MarkOneTimeLaborExpensePayedDocument = gql`
  mutation MarkOneTimeLaborExpensePayed($id: ID!) {
    markOneTimeLaborExpensePayed(id: $id) {
      id
      paymentId
    }
  }
`;
export type MarkOneTimeLaborExpensePayedMutationFn = Apollo.MutationFunction<
  MarkOneTimeLaborExpensePayedMutation,
  MarkOneTimeLaborExpensePayedMutationVariables
>;
export type MarkOneTimeLaborExpensePayedComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MarkOneTimeLaborExpensePayedMutation,
    MarkOneTimeLaborExpensePayedMutationVariables
  >,
  'mutation'
>;

export const MarkOneTimeLaborExpensePayedComponent = (
  props: MarkOneTimeLaborExpensePayedComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    MarkOneTimeLaborExpensePayedMutation,
    MarkOneTimeLaborExpensePayedMutationVariables
  >
    mutation={MarkOneTimeLaborExpensePayedDocument}
    {...props}
  />
);

/**
 * __useMarkOneTimeLaborExpensePayedMutation__
 *
 * To run a mutation, you first call `useMarkOneTimeLaborExpensePayedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkOneTimeLaborExpensePayedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markOneTimeLaborExpensePayedMutation, { data, loading, error }] = useMarkOneTimeLaborExpensePayedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkOneTimeLaborExpensePayedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkOneTimeLaborExpensePayedMutation,
    MarkOneTimeLaborExpensePayedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MarkOneTimeLaborExpensePayedMutation,
    MarkOneTimeLaborExpensePayedMutationVariables
  >(MarkOneTimeLaborExpensePayedDocument, options);
}
export type MarkOneTimeLaborExpensePayedMutationHookResult = ReturnType<
  typeof useMarkOneTimeLaborExpensePayedMutation
>;
export type MarkOneTimeLaborExpensePayedMutationResult =
  Apollo.MutationResult<MarkOneTimeLaborExpensePayedMutation>;
export type MarkOneTimeLaborExpensePayedMutationOptions =
  Apollo.BaseMutationOptions<
    MarkOneTimeLaborExpensePayedMutation,
    MarkOneTimeLaborExpensePayedMutationVariables
  >;
export const UnmarkOneTimeLaborExpensePayedDocument = gql`
  mutation UnmarkOneTimeLaborExpensePayed($id: ID!) {
    unmarkOneTimeLaborExpensePayed(id: $id) {
      id
      paymentId
    }
  }
`;
export type UnmarkOneTimeLaborExpensePayedMutationFn = Apollo.MutationFunction<
  UnmarkOneTimeLaborExpensePayedMutation,
  UnmarkOneTimeLaborExpensePayedMutationVariables
>;
export type UnmarkOneTimeLaborExpensePayedComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UnmarkOneTimeLaborExpensePayedMutation,
    UnmarkOneTimeLaborExpensePayedMutationVariables
  >,
  'mutation'
>;

export const UnmarkOneTimeLaborExpensePayedComponent = (
  props: UnmarkOneTimeLaborExpensePayedComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UnmarkOneTimeLaborExpensePayedMutation,
    UnmarkOneTimeLaborExpensePayedMutationVariables
  >
    mutation={UnmarkOneTimeLaborExpensePayedDocument}
    {...props}
  />
);

/**
 * __useUnmarkOneTimeLaborExpensePayedMutation__
 *
 * To run a mutation, you first call `useUnmarkOneTimeLaborExpensePayedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmarkOneTimeLaborExpensePayedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmarkOneTimeLaborExpensePayedMutation, { data, loading, error }] = useUnmarkOneTimeLaborExpensePayedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnmarkOneTimeLaborExpensePayedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnmarkOneTimeLaborExpensePayedMutation,
    UnmarkOneTimeLaborExpensePayedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UnmarkOneTimeLaborExpensePayedMutation,
    UnmarkOneTimeLaborExpensePayedMutationVariables
  >(UnmarkOneTimeLaborExpensePayedDocument, options);
}
export type UnmarkOneTimeLaborExpensePayedMutationHookResult = ReturnType<
  typeof useUnmarkOneTimeLaborExpensePayedMutation
>;
export type UnmarkOneTimeLaborExpensePayedMutationResult =
  Apollo.MutationResult<UnmarkOneTimeLaborExpensePayedMutation>;
export type UnmarkOneTimeLaborExpensePayedMutationOptions =
  Apollo.BaseMutationOptions<
    UnmarkOneTimeLaborExpensePayedMutation,
    UnmarkOneTimeLaborExpensePayedMutationVariables
  >;
export const RemoveOneTimeLaborExpenseDocument = gql`
  mutation RemoveOneTimeLaborExpense($id: ID!) {
    removeOneTimeLaborExpense(id: $id)
  }
`;
export type RemoveOneTimeLaborExpenseMutationFn = Apollo.MutationFunction<
  RemoveOneTimeLaborExpenseMutation,
  RemoveOneTimeLaborExpenseMutationVariables
>;
export type RemoveOneTimeLaborExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveOneTimeLaborExpenseMutation,
    RemoveOneTimeLaborExpenseMutationVariables
  >,
  'mutation'
>;

export const RemoveOneTimeLaborExpenseComponent = (
  props: RemoveOneTimeLaborExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    RemoveOneTimeLaborExpenseMutation,
    RemoveOneTimeLaborExpenseMutationVariables
  >
    mutation={RemoveOneTimeLaborExpenseDocument}
    {...props}
  />
);

/**
 * __useRemoveOneTimeLaborExpenseMutation__
 *
 * To run a mutation, you first call `useRemoveOneTimeLaborExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneTimeLaborExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOneTimeLaborExpenseMutation, { data, loading, error }] = useRemoveOneTimeLaborExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOneTimeLaborExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveOneTimeLaborExpenseMutation,
    RemoveOneTimeLaborExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RemoveOneTimeLaborExpenseMutation,
    RemoveOneTimeLaborExpenseMutationVariables
  >(RemoveOneTimeLaborExpenseDocument, options);
}
export type RemoveOneTimeLaborExpenseMutationHookResult = ReturnType<
  typeof useRemoveOneTimeLaborExpenseMutation
>;
export type RemoveOneTimeLaborExpenseMutationResult =
  Apollo.MutationResult<RemoveOneTimeLaborExpenseMutation>;
export type RemoveOneTimeLaborExpenseMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveOneTimeLaborExpenseMutation,
    RemoveOneTimeLaborExpenseMutationVariables
  >;
export const MarkRepeatingLaborExpensePayedDocument = gql`
  mutation MarkRepeatingLaborExpensePayed($id: ID!, $date: Date!) {
    markRepeatingLaborExpensePaid(id: $id, date: $date)
  }
`;
export type MarkRepeatingLaborExpensePayedMutationFn = Apollo.MutationFunction<
  MarkRepeatingLaborExpensePayedMutation,
  MarkRepeatingLaborExpensePayedMutationVariables
>;
export type MarkRepeatingLaborExpensePayedComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MarkRepeatingLaborExpensePayedMutation,
    MarkRepeatingLaborExpensePayedMutationVariables
  >,
  'mutation'
>;

export const MarkRepeatingLaborExpensePayedComponent = (
  props: MarkRepeatingLaborExpensePayedComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    MarkRepeatingLaborExpensePayedMutation,
    MarkRepeatingLaborExpensePayedMutationVariables
  >
    mutation={MarkRepeatingLaborExpensePayedDocument}
    {...props}
  />
);

/**
 * __useMarkRepeatingLaborExpensePayedMutation__
 *
 * To run a mutation, you first call `useMarkRepeatingLaborExpensePayedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkRepeatingLaborExpensePayedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markRepeatingLaborExpensePayedMutation, { data, loading, error }] = useMarkRepeatingLaborExpensePayedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useMarkRepeatingLaborExpensePayedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkRepeatingLaborExpensePayedMutation,
    MarkRepeatingLaborExpensePayedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MarkRepeatingLaborExpensePayedMutation,
    MarkRepeatingLaborExpensePayedMutationVariables
  >(MarkRepeatingLaborExpensePayedDocument, options);
}
export type MarkRepeatingLaborExpensePayedMutationHookResult = ReturnType<
  typeof useMarkRepeatingLaborExpensePayedMutation
>;
export type MarkRepeatingLaborExpensePayedMutationResult =
  Apollo.MutationResult<MarkRepeatingLaborExpensePayedMutation>;
export type MarkRepeatingLaborExpensePayedMutationOptions =
  Apollo.BaseMutationOptions<
    MarkRepeatingLaborExpensePayedMutation,
    MarkRepeatingLaborExpensePayedMutationVariables
  >;
export const UnmarkRepeatingLaborExpensePayedDocument = gql`
  mutation UnmarkRepeatingLaborExpensePayed($id: ID!, $date: Date!) {
    unmarkRepeatingLaborExpensePaid(id: $id, date: $date)
  }
`;
export type UnmarkRepeatingLaborExpensePayedMutationFn =
  Apollo.MutationFunction<
    UnmarkRepeatingLaborExpensePayedMutation,
    UnmarkRepeatingLaborExpensePayedMutationVariables
  >;
export type UnmarkRepeatingLaborExpensePayedComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UnmarkRepeatingLaborExpensePayedMutation,
    UnmarkRepeatingLaborExpensePayedMutationVariables
  >,
  'mutation'
>;

export const UnmarkRepeatingLaborExpensePayedComponent = (
  props: UnmarkRepeatingLaborExpensePayedComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UnmarkRepeatingLaborExpensePayedMutation,
    UnmarkRepeatingLaborExpensePayedMutationVariables
  >
    mutation={UnmarkRepeatingLaborExpensePayedDocument}
    {...props}
  />
);

/**
 * __useUnmarkRepeatingLaborExpensePayedMutation__
 *
 * To run a mutation, you first call `useUnmarkRepeatingLaborExpensePayedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmarkRepeatingLaborExpensePayedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmarkRepeatingLaborExpensePayedMutation, { data, loading, error }] = useUnmarkRepeatingLaborExpensePayedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUnmarkRepeatingLaborExpensePayedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnmarkRepeatingLaborExpensePayedMutation,
    UnmarkRepeatingLaborExpensePayedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UnmarkRepeatingLaborExpensePayedMutation,
    UnmarkRepeatingLaborExpensePayedMutationVariables
  >(UnmarkRepeatingLaborExpensePayedDocument, options);
}
export type UnmarkRepeatingLaborExpensePayedMutationHookResult = ReturnType<
  typeof useUnmarkRepeatingLaborExpensePayedMutation
>;
export type UnmarkRepeatingLaborExpensePayedMutationResult =
  Apollo.MutationResult<UnmarkRepeatingLaborExpensePayedMutation>;
export type UnmarkRepeatingLaborExpensePayedMutationOptions =
  Apollo.BaseMutationOptions<
    UnmarkRepeatingLaborExpensePayedMutation,
    UnmarkRepeatingLaborExpensePayedMutationVariables
  >;
export const RemoveRepeatingLaborExpenseDocument = gql`
  mutation RemoveRepeatingLaborExpense($id: ID!) {
    removeRepeatingLaborExpense(id: $id)
  }
`;
export type RemoveRepeatingLaborExpenseMutationFn = Apollo.MutationFunction<
  RemoveRepeatingLaborExpenseMutation,
  RemoveRepeatingLaborExpenseMutationVariables
>;
export type RemoveRepeatingLaborExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveRepeatingLaborExpenseMutation,
    RemoveRepeatingLaborExpenseMutationVariables
  >,
  'mutation'
>;

export const RemoveRepeatingLaborExpenseComponent = (
  props: RemoveRepeatingLaborExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    RemoveRepeatingLaborExpenseMutation,
    RemoveRepeatingLaborExpenseMutationVariables
  >
    mutation={RemoveRepeatingLaborExpenseDocument}
    {...props}
  />
);

/**
 * __useRemoveRepeatingLaborExpenseMutation__
 *
 * To run a mutation, you first call `useRemoveRepeatingLaborExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRepeatingLaborExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRepeatingLaborExpenseMutation, { data, loading, error }] = useRemoveRepeatingLaborExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRepeatingLaborExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveRepeatingLaborExpenseMutation,
    RemoveRepeatingLaborExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RemoveRepeatingLaborExpenseMutation,
    RemoveRepeatingLaborExpenseMutationVariables
  >(RemoveRepeatingLaborExpenseDocument, options);
}
export type RemoveRepeatingLaborExpenseMutationHookResult = ReturnType<
  typeof useRemoveRepeatingLaborExpenseMutation
>;
export type RemoveRepeatingLaborExpenseMutationResult =
  Apollo.MutationResult<RemoveRepeatingLaborExpenseMutation>;
export type RemoveRepeatingLaborExpenseMutationOptions =
  Apollo.BaseMutationOptions<
    RemoveRepeatingLaborExpenseMutation,
    RemoveRepeatingLaborExpenseMutationVariables
  >;
export const SkipRepeatingLaborExpenseDocument = gql`
  mutation SkipRepeatingLaborExpense($id: ID!, $date: Date!) {
    skipRepeatingLaborExpense(id: $id, date: $date) {
      id
    }
  }
`;
export type SkipRepeatingLaborExpenseMutationFn = Apollo.MutationFunction<
  SkipRepeatingLaborExpenseMutation,
  SkipRepeatingLaborExpenseMutationVariables
>;
export type SkipRepeatingLaborExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SkipRepeatingLaborExpenseMutation,
    SkipRepeatingLaborExpenseMutationVariables
  >,
  'mutation'
>;

export const SkipRepeatingLaborExpenseComponent = (
  props: SkipRepeatingLaborExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    SkipRepeatingLaborExpenseMutation,
    SkipRepeatingLaborExpenseMutationVariables
  >
    mutation={SkipRepeatingLaborExpenseDocument}
    {...props}
  />
);

/**
 * __useSkipRepeatingLaborExpenseMutation__
 *
 * To run a mutation, you first call `useSkipRepeatingLaborExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipRepeatingLaborExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipRepeatingLaborExpenseMutation, { data, loading, error }] = useSkipRepeatingLaborExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useSkipRepeatingLaborExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SkipRepeatingLaborExpenseMutation,
    SkipRepeatingLaborExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SkipRepeatingLaborExpenseMutation,
    SkipRepeatingLaborExpenseMutationVariables
  >(SkipRepeatingLaborExpenseDocument, options);
}
export type SkipRepeatingLaborExpenseMutationHookResult = ReturnType<
  typeof useSkipRepeatingLaborExpenseMutation
>;
export type SkipRepeatingLaborExpenseMutationResult =
  Apollo.MutationResult<SkipRepeatingLaborExpenseMutation>;
export type SkipRepeatingLaborExpenseMutationOptions =
  Apollo.BaseMutationOptions<
    SkipRepeatingLaborExpenseMutation,
    SkipRepeatingLaborExpenseMutationVariables
  >;
export const TerminateRepeatingLaborExpenseDocument = gql`
  mutation TerminateRepeatingLaborExpense($id: ID!, $date: Date!) {
    terminateRepeatingLaborExpense(id: $id, terminationDate: $date) {
      id
    }
  }
`;
export type TerminateRepeatingLaborExpenseMutationFn = Apollo.MutationFunction<
  TerminateRepeatingLaborExpenseMutation,
  TerminateRepeatingLaborExpenseMutationVariables
>;
export type TerminateRepeatingLaborExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    TerminateRepeatingLaborExpenseMutation,
    TerminateRepeatingLaborExpenseMutationVariables
  >,
  'mutation'
>;

export const TerminateRepeatingLaborExpenseComponent = (
  props: TerminateRepeatingLaborExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    TerminateRepeatingLaborExpenseMutation,
    TerminateRepeatingLaborExpenseMutationVariables
  >
    mutation={TerminateRepeatingLaborExpenseDocument}
    {...props}
  />
);

/**
 * __useTerminateRepeatingLaborExpenseMutation__
 *
 * To run a mutation, you first call `useTerminateRepeatingLaborExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateRepeatingLaborExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateRepeatingLaborExpenseMutation, { data, loading, error }] = useTerminateRepeatingLaborExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useTerminateRepeatingLaborExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TerminateRepeatingLaborExpenseMutation,
    TerminateRepeatingLaborExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    TerminateRepeatingLaborExpenseMutation,
    TerminateRepeatingLaborExpenseMutationVariables
  >(TerminateRepeatingLaborExpenseDocument, options);
}
export type TerminateRepeatingLaborExpenseMutationHookResult = ReturnType<
  typeof useTerminateRepeatingLaborExpenseMutation
>;
export type TerminateRepeatingLaborExpenseMutationResult =
  Apollo.MutationResult<TerminateRepeatingLaborExpenseMutation>;
export type TerminateRepeatingLaborExpenseMutationOptions =
  Apollo.BaseMutationOptions<
    TerminateRepeatingLaborExpenseMutation,
    TerminateRepeatingLaborExpenseMutationVariables
  >;
export const MarkLaborExpensesAsPaidDocument = gql`
  mutation MarkLaborExpensesAsPaid(
    $from: Date!
    $to: Date!
    $expenseIds: [ID!]!
  ) {
    markLaborExpensesAsPaid(expenseIds: $expenseIds, from: $from, to: $to)
  }
`;
export type MarkLaborExpensesAsPaidMutationFn = Apollo.MutationFunction<
  MarkLaborExpensesAsPaidMutation,
  MarkLaborExpensesAsPaidMutationVariables
>;
export type MarkLaborExpensesAsPaidComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MarkLaborExpensesAsPaidMutation,
    MarkLaborExpensesAsPaidMutationVariables
  >,
  'mutation'
>;

export const MarkLaborExpensesAsPaidComponent = (
  props: MarkLaborExpensesAsPaidComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    MarkLaborExpensesAsPaidMutation,
    MarkLaborExpensesAsPaidMutationVariables
  >
    mutation={MarkLaborExpensesAsPaidDocument}
    {...props}
  />
);

/**
 * __useMarkLaborExpensesAsPaidMutation__
 *
 * To run a mutation, you first call `useMarkLaborExpensesAsPaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkLaborExpensesAsPaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markLaborExpensesAsPaidMutation, { data, loading, error }] = useMarkLaborExpensesAsPaidMutation({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      expenseIds: // value for 'expenseIds'
 *   },
 * });
 */
export function useMarkLaborExpensesAsPaidMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkLaborExpensesAsPaidMutation,
    MarkLaborExpensesAsPaidMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MarkLaborExpensesAsPaidMutation,
    MarkLaborExpensesAsPaidMutationVariables
  >(MarkLaborExpensesAsPaidDocument, options);
}
export type MarkLaborExpensesAsPaidMutationHookResult = ReturnType<
  typeof useMarkLaborExpensesAsPaidMutation
>;
export type MarkLaborExpensesAsPaidMutationResult =
  Apollo.MutationResult<MarkLaborExpensesAsPaidMutation>;
export type MarkLaborExpensesAsPaidMutationOptions = Apollo.BaseMutationOptions<
  MarkLaborExpensesAsPaidMutation,
  MarkLaborExpensesAsPaidMutationVariables
>;
export const UnmarkLaborExpensesAsPaidDocument = gql`
  mutation UnmarkLaborExpensesAsPaid(
    $from: Date!
    $to: Date!
    $expenseIds: [ID!]!
  ) {
    unmarkLaborExpensesAsPaid(expenseIds: $expenseIds, from: $from, to: $to)
  }
`;
export type UnmarkLaborExpensesAsPaidMutationFn = Apollo.MutationFunction<
  UnmarkLaborExpensesAsPaidMutation,
  UnmarkLaborExpensesAsPaidMutationVariables
>;
export type UnmarkLaborExpensesAsPaidComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UnmarkLaborExpensesAsPaidMutation,
    UnmarkLaborExpensesAsPaidMutationVariables
  >,
  'mutation'
>;

export const UnmarkLaborExpensesAsPaidComponent = (
  props: UnmarkLaborExpensesAsPaidComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UnmarkLaborExpensesAsPaidMutation,
    UnmarkLaborExpensesAsPaidMutationVariables
  >
    mutation={UnmarkLaborExpensesAsPaidDocument}
    {...props}
  />
);

/**
 * __useUnmarkLaborExpensesAsPaidMutation__
 *
 * To run a mutation, you first call `useUnmarkLaborExpensesAsPaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmarkLaborExpensesAsPaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmarkLaborExpensesAsPaidMutation, { data, loading, error }] = useUnmarkLaborExpensesAsPaidMutation({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      expenseIds: // value for 'expenseIds'
 *   },
 * });
 */
export function useUnmarkLaborExpensesAsPaidMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnmarkLaborExpensesAsPaidMutation,
    UnmarkLaborExpensesAsPaidMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UnmarkLaborExpensesAsPaidMutation,
    UnmarkLaborExpensesAsPaidMutationVariables
  >(UnmarkLaborExpensesAsPaidDocument, options);
}
export type UnmarkLaborExpensesAsPaidMutationHookResult = ReturnType<
  typeof useUnmarkLaborExpensesAsPaidMutation
>;
export type UnmarkLaborExpensesAsPaidMutationResult =
  Apollo.MutationResult<UnmarkLaborExpensesAsPaidMutation>;
export type UnmarkLaborExpensesAsPaidMutationOptions =
  Apollo.BaseMutationOptions<
    UnmarkLaborExpensesAsPaidMutation,
    UnmarkLaborExpensesAsPaidMutationVariables
  >;
export const LaborExpensesTimeSeriesDocument = gql`
  query LaborExpensesTimeSeries($from: Date!, $to: Date!) {
    laborExpensesTimeSeries(from: $from, to: $to) {
      id
      employeeId
      employee {
        id
        firstName
        lastName
      }
      amount
      date
      isPaid
      currencyCode
      currencyPrecision
      laborExpense {
        ... on OneTimeLaborExpense {
          id
          dueDate
          accountingDate
          note
        }
        ... on RepeatingLaborExpense {
          id
          note
        }
      }
    }
  }
`;
export type LaborExpensesTimeSeriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LaborExpensesTimeSeriesQuery,
    LaborExpensesTimeSeriesQueryVariables
  >,
  'query'
> &
  (
    | { variables: LaborExpensesTimeSeriesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const LaborExpensesTimeSeriesComponent = (
  props: LaborExpensesTimeSeriesComponentProps,
) => (
  <ApolloReactComponents.Query<
    LaborExpensesTimeSeriesQuery,
    LaborExpensesTimeSeriesQueryVariables
  >
    query={LaborExpensesTimeSeriesDocument}
    {...props}
  />
);

/**
 * __useLaborExpensesTimeSeriesQuery__
 *
 * To run a query within a React component, call `useLaborExpensesTimeSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLaborExpensesTimeSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLaborExpensesTimeSeriesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useLaborExpensesTimeSeriesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LaborExpensesTimeSeriesQuery,
    LaborExpensesTimeSeriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    LaborExpensesTimeSeriesQuery,
    LaborExpensesTimeSeriesQueryVariables
  >(LaborExpensesTimeSeriesDocument, options);
}
export function useLaborExpensesTimeSeriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LaborExpensesTimeSeriesQuery,
    LaborExpensesTimeSeriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LaborExpensesTimeSeriesQuery,
    LaborExpensesTimeSeriesQueryVariables
  >(LaborExpensesTimeSeriesDocument, options);
}
export type LaborExpensesTimeSeriesQueryHookResult = ReturnType<
  typeof useLaborExpensesTimeSeriesQuery
>;
export type LaborExpensesTimeSeriesLazyQueryHookResult = ReturnType<
  typeof useLaborExpensesTimeSeriesLazyQuery
>;
export type LaborExpensesTimeSeriesQueryResult = Apollo.QueryResult<
  LaborExpensesTimeSeriesQuery,
  LaborExpensesTimeSeriesQueryVariables
>;
export function refetchLaborExpensesTimeSeriesQuery(
  variables: LaborExpensesTimeSeriesQueryVariables,
) {
  return { query: LaborExpensesTimeSeriesDocument, variables: variables };
}
export const LaborExpensesTotalAmountDocument = gql`
  query LaborExpensesTotalAmount(
    $from: Date!
    $to: Date!
    $dateFilterType: ExpenseDateFilterType
  ) {
    laborExpensesTotalAmount(
      from: $from
      to: $to
      dateFilterType: $dateFilterType
    ) {
      currencyCode
      precision
      id
      amount
    }
  }
`;
export type LaborExpensesTotalAmountComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    LaborExpensesTotalAmountQuery,
    LaborExpensesTotalAmountQueryVariables
  >,
  'query'
> &
  (
    | { variables: LaborExpensesTotalAmountQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const LaborExpensesTotalAmountComponent = (
  props: LaborExpensesTotalAmountComponentProps,
) => (
  <ApolloReactComponents.Query<
    LaborExpensesTotalAmountQuery,
    LaborExpensesTotalAmountQueryVariables
  >
    query={LaborExpensesTotalAmountDocument}
    {...props}
  />
);

/**
 * __useLaborExpensesTotalAmountQuery__
 *
 * To run a query within a React component, call `useLaborExpensesTotalAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useLaborExpensesTotalAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLaborExpensesTotalAmountQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *      dateFilterType: // value for 'dateFilterType'
 *   },
 * });
 */
export function useLaborExpensesTotalAmountQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    LaborExpensesTotalAmountQuery,
    LaborExpensesTotalAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    LaborExpensesTotalAmountQuery,
    LaborExpensesTotalAmountQueryVariables
  >(LaborExpensesTotalAmountDocument, options);
}
export function useLaborExpensesTotalAmountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LaborExpensesTotalAmountQuery,
    LaborExpensesTotalAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    LaborExpensesTotalAmountQuery,
    LaborExpensesTotalAmountQueryVariables
  >(LaborExpensesTotalAmountDocument, options);
}
export type LaborExpensesTotalAmountQueryHookResult = ReturnType<
  typeof useLaborExpensesTotalAmountQuery
>;
export type LaborExpensesTotalAmountLazyQueryHookResult = ReturnType<
  typeof useLaborExpensesTotalAmountLazyQuery
>;
export type LaborExpensesTotalAmountQueryResult = Apollo.QueryResult<
  LaborExpensesTotalAmountQuery,
  LaborExpensesTotalAmountQueryVariables
>;
export function refetchLaborExpensesTotalAmountQuery(
  variables: LaborExpensesTotalAmountQueryVariables,
) {
  return { query: LaborExpensesTotalAmountDocument, variables: variables };
}
export const OneTimeLaborExpenseDocument = gql`
  query OneTimeLaborExpense($id: ID!) {
    oneTimeLaborExpense(id: $id) {
      id
      dueDate
      note
      employeeId
      amount
      accountingDate
    }
  }
`;
export type OneTimeLaborExpenseComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OneTimeLaborExpenseQuery,
    OneTimeLaborExpenseQueryVariables
  >,
  'query'
> &
  (
    | { variables: OneTimeLaborExpenseQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const OneTimeLaborExpenseComponent = (
  props: OneTimeLaborExpenseComponentProps,
) => (
  <ApolloReactComponents.Query<
    OneTimeLaborExpenseQuery,
    OneTimeLaborExpenseQueryVariables
  >
    query={OneTimeLaborExpenseDocument}
    {...props}
  />
);

/**
 * __useOneTimeLaborExpenseQuery__
 *
 * To run a query within a React component, call `useOneTimeLaborExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneTimeLaborExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneTimeLaborExpenseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOneTimeLaborExpenseQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OneTimeLaborExpenseQuery,
    OneTimeLaborExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OneTimeLaborExpenseQuery,
    OneTimeLaborExpenseQueryVariables
  >(OneTimeLaborExpenseDocument, options);
}
export function useOneTimeLaborExpenseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OneTimeLaborExpenseQuery,
    OneTimeLaborExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OneTimeLaborExpenseQuery,
    OneTimeLaborExpenseQueryVariables
  >(OneTimeLaborExpenseDocument, options);
}
export type OneTimeLaborExpenseQueryHookResult = ReturnType<
  typeof useOneTimeLaborExpenseQuery
>;
export type OneTimeLaborExpenseLazyQueryHookResult = ReturnType<
  typeof useOneTimeLaborExpenseLazyQuery
>;
export type OneTimeLaborExpenseQueryResult = Apollo.QueryResult<
  OneTimeLaborExpenseQuery,
  OneTimeLaborExpenseQueryVariables
>;
export function refetchOneTimeLaborExpenseQuery(
  variables: OneTimeLaborExpenseQueryVariables,
) {
  return { query: OneTimeLaborExpenseDocument, variables: variables };
}
export const RepeatingLaborExpenseDocument = gql`
  query RepeatingLaborExpense($id: ID!) {
    repeatingLaborExpense(id: $id) {
      note
      employeeId
      amount
      recurrencePattern {
        startDate
      }
    }
  }
`;
export type RepeatingLaborExpenseComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    RepeatingLaborExpenseQuery,
    RepeatingLaborExpenseQueryVariables
  >,
  'query'
> &
  (
    | { variables: RepeatingLaborExpenseQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const RepeatingLaborExpenseComponent = (
  props: RepeatingLaborExpenseComponentProps,
) => (
  <ApolloReactComponents.Query<
    RepeatingLaborExpenseQuery,
    RepeatingLaborExpenseQueryVariables
  >
    query={RepeatingLaborExpenseDocument}
    {...props}
  />
);

/**
 * __useRepeatingLaborExpenseQuery__
 *
 * To run a query within a React component, call `useRepeatingLaborExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useRepeatingLaborExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRepeatingLaborExpenseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRepeatingLaborExpenseQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    RepeatingLaborExpenseQuery,
    RepeatingLaborExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    RepeatingLaborExpenseQuery,
    RepeatingLaborExpenseQueryVariables
  >(RepeatingLaborExpenseDocument, options);
}
export function useRepeatingLaborExpenseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RepeatingLaborExpenseQuery,
    RepeatingLaborExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    RepeatingLaborExpenseQuery,
    RepeatingLaborExpenseQueryVariables
  >(RepeatingLaborExpenseDocument, options);
}
export type RepeatingLaborExpenseQueryHookResult = ReturnType<
  typeof useRepeatingLaborExpenseQuery
>;
export type RepeatingLaborExpenseLazyQueryHookResult = ReturnType<
  typeof useRepeatingLaborExpenseLazyQuery
>;
export type RepeatingLaborExpenseQueryResult = Apollo.QueryResult<
  RepeatingLaborExpenseQuery,
  RepeatingLaborExpenseQueryVariables
>;
export function refetchRepeatingLaborExpenseQuery(
  variables: RepeatingLaborExpenseQueryVariables,
) {
  return { query: RepeatingLaborExpenseDocument, variables: variables };
}
export const EmployeesDocument = gql`
  query Employees {
    employees {
      items {
        id
        firstName
        lastName
      }
    }
  }
`;
export type EmployeesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    EmployeesQuery,
    EmployeesQueryVariables
  >,
  'query'
>;

export const EmployeesComponent = (props: EmployeesComponentProps) => (
  <ApolloReactComponents.Query<EmployeesQuery, EmployeesQueryVariables>
    query={EmployeesDocument}
    {...props}
  />
);

/**
 * __useEmployeesQuery__
 *
 * To run a query within a React component, call `useEmployeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeesQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmployeesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    EmployeesQuery,
    EmployeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<EmployeesQuery, EmployeesQueryVariables>(
    EmployeesDocument,
    options,
  );
}
export function useEmployeesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    EmployeesQuery,
    EmployeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<EmployeesQuery, EmployeesQueryVariables>(
    EmployeesDocument,
    options,
  );
}
export type EmployeesQueryHookResult = ReturnType<typeof useEmployeesQuery>;
export type EmployeesLazyQueryHookResult = ReturnType<
  typeof useEmployeesLazyQuery
>;
export type EmployeesQueryResult = Apollo.QueryResult<
  EmployeesQuery,
  EmployeesQueryVariables
>;
export function refetchEmployeesQuery(variables?: EmployeesQueryVariables) {
  return { query: EmployeesDocument, variables: variables };
}
export const CreateOneTimeExpenseDocument = gql`
  mutation CreateOneTimeExpense(
    $name: String!
    $amount: Int!
    $categoryId: ID!
    $dueDate: Date!
    $accountingDate: Date!
    $supplierId: ID
    $link: String
  ) {
    createOneTimeExpense(
      amount: $amount
      supplierId: $supplierId
      categoryId: $categoryId
      dueDate: $dueDate
      name: $name
      accountingDate: $accountingDate
      link: $link
    ) {
      id
    }
  }
`;
export type CreateOneTimeExpenseMutationFn = Apollo.MutationFunction<
  CreateOneTimeExpenseMutation,
  CreateOneTimeExpenseMutationVariables
>;
export type CreateOneTimeExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateOneTimeExpenseMutation,
    CreateOneTimeExpenseMutationVariables
  >,
  'mutation'
>;

export const CreateOneTimeExpenseComponent = (
  props: CreateOneTimeExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CreateOneTimeExpenseMutation,
    CreateOneTimeExpenseMutationVariables
  >
    mutation={CreateOneTimeExpenseDocument}
    {...props}
  />
);

/**
 * __useCreateOneTimeExpenseMutation__
 *
 * To run a mutation, you first call `useCreateOneTimeExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneTimeExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneTimeExpenseMutation, { data, loading, error }] = useCreateOneTimeExpenseMutation({
 *   variables: {
 *      name: // value for 'name'
 *      amount: // value for 'amount'
 *      categoryId: // value for 'categoryId'
 *      dueDate: // value for 'dueDate'
 *      accountingDate: // value for 'accountingDate'
 *      supplierId: // value for 'supplierId'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useCreateOneTimeExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateOneTimeExpenseMutation,
    CreateOneTimeExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateOneTimeExpenseMutation,
    CreateOneTimeExpenseMutationVariables
  >(CreateOneTimeExpenseDocument, options);
}
export type CreateOneTimeExpenseMutationHookResult = ReturnType<
  typeof useCreateOneTimeExpenseMutation
>;
export type CreateOneTimeExpenseMutationResult =
  Apollo.MutationResult<CreateOneTimeExpenseMutation>;
export type CreateOneTimeExpenseMutationOptions = Apollo.BaseMutationOptions<
  CreateOneTimeExpenseMutation,
  CreateOneTimeExpenseMutationVariables
>;
export const UpdateOneTimeExpenseDocument = gql`
  mutation UpdateOneTimeExpense(
    $id: ID!
    $name: String
    $amount: Int
    $supplierId: ID
    $categoryId: ID
    $dueDate: Date
    $accountingDate: Date
    $link: String
  ) {
    updateOneTimeExpense(
      id: $id
      amount: $amount
      supplierId: $supplierId
      categoryId: $categoryId
      dueDate: $dueDate
      name: $name
      accountingDate: $accountingDate
      link: $link
    ) {
      id
      amount
      supplierId
      categoryId
      dueDate
      name
      accountingDate
    }
  }
`;
export type UpdateOneTimeExpenseMutationFn = Apollo.MutationFunction<
  UpdateOneTimeExpenseMutation,
  UpdateOneTimeExpenseMutationVariables
>;
export type UpdateOneTimeExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateOneTimeExpenseMutation,
    UpdateOneTimeExpenseMutationVariables
  >,
  'mutation'
>;

export const UpdateOneTimeExpenseComponent = (
  props: UpdateOneTimeExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UpdateOneTimeExpenseMutation,
    UpdateOneTimeExpenseMutationVariables
  >
    mutation={UpdateOneTimeExpenseDocument}
    {...props}
  />
);

/**
 * __useUpdateOneTimeExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateOneTimeExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneTimeExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneTimeExpenseMutation, { data, loading, error }] = useUpdateOneTimeExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      amount: // value for 'amount'
 *      supplierId: // value for 'supplierId'
 *      categoryId: // value for 'categoryId'
 *      dueDate: // value for 'dueDate'
 *      accountingDate: // value for 'accountingDate'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useUpdateOneTimeExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateOneTimeExpenseMutation,
    UpdateOneTimeExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateOneTimeExpenseMutation,
    UpdateOneTimeExpenseMutationVariables
  >(UpdateOneTimeExpenseDocument, options);
}
export type UpdateOneTimeExpenseMutationHookResult = ReturnType<
  typeof useUpdateOneTimeExpenseMutation
>;
export type UpdateOneTimeExpenseMutationResult =
  Apollo.MutationResult<UpdateOneTimeExpenseMutation>;
export type UpdateOneTimeExpenseMutationOptions = Apollo.BaseMutationOptions<
  UpdateOneTimeExpenseMutation,
  UpdateOneTimeExpenseMutationVariables
>;
export const MarkOneTimeExpensePayedDocument = gql`
  mutation MarkOneTimeExpensePayed($id: ID!) {
    markOneTimeExpensePayed(id: $id) {
      id
      paymentId
    }
  }
`;
export type MarkOneTimeExpensePayedMutationFn = Apollo.MutationFunction<
  MarkOneTimeExpensePayedMutation,
  MarkOneTimeExpensePayedMutationVariables
>;
export type MarkOneTimeExpensePayedComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MarkOneTimeExpensePayedMutation,
    MarkOneTimeExpensePayedMutationVariables
  >,
  'mutation'
>;

export const MarkOneTimeExpensePayedComponent = (
  props: MarkOneTimeExpensePayedComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    MarkOneTimeExpensePayedMutation,
    MarkOneTimeExpensePayedMutationVariables
  >
    mutation={MarkOneTimeExpensePayedDocument}
    {...props}
  />
);

/**
 * __useMarkOneTimeExpensePayedMutation__
 *
 * To run a mutation, you first call `useMarkOneTimeExpensePayedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkOneTimeExpensePayedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markOneTimeExpensePayedMutation, { data, loading, error }] = useMarkOneTimeExpensePayedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkOneTimeExpensePayedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkOneTimeExpensePayedMutation,
    MarkOneTimeExpensePayedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MarkOneTimeExpensePayedMutation,
    MarkOneTimeExpensePayedMutationVariables
  >(MarkOneTimeExpensePayedDocument, options);
}
export type MarkOneTimeExpensePayedMutationHookResult = ReturnType<
  typeof useMarkOneTimeExpensePayedMutation
>;
export type MarkOneTimeExpensePayedMutationResult =
  Apollo.MutationResult<MarkOneTimeExpensePayedMutation>;
export type MarkOneTimeExpensePayedMutationOptions = Apollo.BaseMutationOptions<
  MarkOneTimeExpensePayedMutation,
  MarkOneTimeExpensePayedMutationVariables
>;
export const UnmarkOneTimeExpensePayedDocument = gql`
  mutation UnmarkOneTimeExpensePayed($id: ID!) {
    unmarkOneTimeExpensePayed(id: $id) {
      id
      paymentId
    }
  }
`;
export type UnmarkOneTimeExpensePayedMutationFn = Apollo.MutationFunction<
  UnmarkOneTimeExpensePayedMutation,
  UnmarkOneTimeExpensePayedMutationVariables
>;
export type UnmarkOneTimeExpensePayedComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UnmarkOneTimeExpensePayedMutation,
    UnmarkOneTimeExpensePayedMutationVariables
  >,
  'mutation'
>;

export const UnmarkOneTimeExpensePayedComponent = (
  props: UnmarkOneTimeExpensePayedComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UnmarkOneTimeExpensePayedMutation,
    UnmarkOneTimeExpensePayedMutationVariables
  >
    mutation={UnmarkOneTimeExpensePayedDocument}
    {...props}
  />
);

/**
 * __useUnmarkOneTimeExpensePayedMutation__
 *
 * To run a mutation, you first call `useUnmarkOneTimeExpensePayedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmarkOneTimeExpensePayedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmarkOneTimeExpensePayedMutation, { data, loading, error }] = useUnmarkOneTimeExpensePayedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnmarkOneTimeExpensePayedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnmarkOneTimeExpensePayedMutation,
    UnmarkOneTimeExpensePayedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UnmarkOneTimeExpensePayedMutation,
    UnmarkOneTimeExpensePayedMutationVariables
  >(UnmarkOneTimeExpensePayedDocument, options);
}
export type UnmarkOneTimeExpensePayedMutationHookResult = ReturnType<
  typeof useUnmarkOneTimeExpensePayedMutation
>;
export type UnmarkOneTimeExpensePayedMutationResult =
  Apollo.MutationResult<UnmarkOneTimeExpensePayedMutation>;
export type UnmarkOneTimeExpensePayedMutationOptions =
  Apollo.BaseMutationOptions<
    UnmarkOneTimeExpensePayedMutation,
    UnmarkOneTimeExpensePayedMutationVariables
  >;
export const RemoveOneTimeExpenseDocument = gql`
  mutation RemoveOneTimeExpense($id: ID!) {
    removeOneTimeExpense(id: $id)
  }
`;
export type RemoveOneTimeExpenseMutationFn = Apollo.MutationFunction<
  RemoveOneTimeExpenseMutation,
  RemoveOneTimeExpenseMutationVariables
>;
export type RemoveOneTimeExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveOneTimeExpenseMutation,
    RemoveOneTimeExpenseMutationVariables
  >,
  'mutation'
>;

export const RemoveOneTimeExpenseComponent = (
  props: RemoveOneTimeExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    RemoveOneTimeExpenseMutation,
    RemoveOneTimeExpenseMutationVariables
  >
    mutation={RemoveOneTimeExpenseDocument}
    {...props}
  />
);

/**
 * __useRemoveOneTimeExpenseMutation__
 *
 * To run a mutation, you first call `useRemoveOneTimeExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneTimeExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOneTimeExpenseMutation, { data, loading, error }] = useRemoveOneTimeExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveOneTimeExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveOneTimeExpenseMutation,
    RemoveOneTimeExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RemoveOneTimeExpenseMutation,
    RemoveOneTimeExpenseMutationVariables
  >(RemoveOneTimeExpenseDocument, options);
}
export type RemoveOneTimeExpenseMutationHookResult = ReturnType<
  typeof useRemoveOneTimeExpenseMutation
>;
export type RemoveOneTimeExpenseMutationResult =
  Apollo.MutationResult<RemoveOneTimeExpenseMutation>;
export type RemoveOneTimeExpenseMutationOptions = Apollo.BaseMutationOptions<
  RemoveOneTimeExpenseMutation,
  RemoveOneTimeExpenseMutationVariables
>;
export const OneTimeExpensesTotalAmountDocument = gql`
  query OneTimeExpensesTotalAmount($from: Date!, $to: Date!) {
    oneTimeExpensesTotalAmount(from: $from, to: $to) {
      currencyCode
      precision
      id
      amount
    }
  }
`;
export type OneTimeExpensesTotalAmountComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OneTimeExpensesTotalAmountQuery,
    OneTimeExpensesTotalAmountQueryVariables
  >,
  'query'
> &
  (
    | { variables: OneTimeExpensesTotalAmountQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const OneTimeExpensesTotalAmountComponent = (
  props: OneTimeExpensesTotalAmountComponentProps,
) => (
  <ApolloReactComponents.Query<
    OneTimeExpensesTotalAmountQuery,
    OneTimeExpensesTotalAmountQueryVariables
  >
    query={OneTimeExpensesTotalAmountDocument}
    {...props}
  />
);

/**
 * __useOneTimeExpensesTotalAmountQuery__
 *
 * To run a query within a React component, call `useOneTimeExpensesTotalAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneTimeExpensesTotalAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneTimeExpensesTotalAmountQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useOneTimeExpensesTotalAmountQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OneTimeExpensesTotalAmountQuery,
    OneTimeExpensesTotalAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OneTimeExpensesTotalAmountQuery,
    OneTimeExpensesTotalAmountQueryVariables
  >(OneTimeExpensesTotalAmountDocument, options);
}
export function useOneTimeExpensesTotalAmountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OneTimeExpensesTotalAmountQuery,
    OneTimeExpensesTotalAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OneTimeExpensesTotalAmountQuery,
    OneTimeExpensesTotalAmountQueryVariables
  >(OneTimeExpensesTotalAmountDocument, options);
}
export type OneTimeExpensesTotalAmountQueryHookResult = ReturnType<
  typeof useOneTimeExpensesTotalAmountQuery
>;
export type OneTimeExpensesTotalAmountLazyQueryHookResult = ReturnType<
  typeof useOneTimeExpensesTotalAmountLazyQuery
>;
export type OneTimeExpensesTotalAmountQueryResult = Apollo.QueryResult<
  OneTimeExpensesTotalAmountQuery,
  OneTimeExpensesTotalAmountQueryVariables
>;
export function refetchOneTimeExpensesTotalAmountQuery(
  variables: OneTimeExpensesTotalAmountQueryVariables,
) {
  return { query: OneTimeExpensesTotalAmountDocument, variables: variables };
}
export const OneTimeExpensesDocument = gql`
  query OneTimeExpenses($from: Date!, $to: Date!) {
    oneTimeExpenses(dateFilter: { from: $from, to: $to }) {
      items {
        name
        id
        amount
        paymentId
        link
        currency {
          currencyCode
          precision
        }
        accountingDate
        dueDate
        supplier {
          id
          name
        }
        category {
          id
          name
        }
      }
    }
  }
`;
export type OneTimeExpensesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OneTimeExpensesQuery,
    OneTimeExpensesQueryVariables
  >,
  'query'
> &
  (
    | { variables: OneTimeExpensesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const OneTimeExpensesComponent = (
  props: OneTimeExpensesComponentProps,
) => (
  <ApolloReactComponents.Query<
    OneTimeExpensesQuery,
    OneTimeExpensesQueryVariables
  >
    query={OneTimeExpensesDocument}
    {...props}
  />
);

/**
 * __useOneTimeExpensesQuery__
 *
 * To run a query within a React component, call `useOneTimeExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneTimeExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneTimeExpensesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useOneTimeExpensesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OneTimeExpensesQuery,
    OneTimeExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OneTimeExpensesQuery,
    OneTimeExpensesQueryVariables
  >(OneTimeExpensesDocument, options);
}
export function useOneTimeExpensesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OneTimeExpensesQuery,
    OneTimeExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OneTimeExpensesQuery,
    OneTimeExpensesQueryVariables
  >(OneTimeExpensesDocument, options);
}
export type OneTimeExpensesQueryHookResult = ReturnType<
  typeof useOneTimeExpensesQuery
>;
export type OneTimeExpensesLazyQueryHookResult = ReturnType<
  typeof useOneTimeExpensesLazyQuery
>;
export type OneTimeExpensesQueryResult = Apollo.QueryResult<
  OneTimeExpensesQuery,
  OneTimeExpensesQueryVariables
>;
export function refetchOneTimeExpensesQuery(
  variables: OneTimeExpensesQueryVariables,
) {
  return { query: OneTimeExpensesDocument, variables: variables };
}
export const OneTimeExpenseDocument = gql`
  query OneTimeExpense($id: ID!) {
    oneTimeExpense(id: $id) {
      id
      amount
      currency {
        currencyCode
        precision
      }
      name
      supplierId
      categoryId
      paymentId
      accountingDate
      dueDate
      link
    }
  }
`;
export type OneTimeExpenseComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OneTimeExpenseQuery,
    OneTimeExpenseQueryVariables
  >,
  'query'
> &
  (
    | { variables: OneTimeExpenseQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const OneTimeExpenseComponent = (
  props: OneTimeExpenseComponentProps,
) => (
  <ApolloReactComponents.Query<
    OneTimeExpenseQuery,
    OneTimeExpenseQueryVariables
  >
    query={OneTimeExpenseDocument}
    {...props}
  />
);

/**
 * __useOneTimeExpenseQuery__
 *
 * To run a query within a React component, call `useOneTimeExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useOneTimeExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOneTimeExpenseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOneTimeExpenseQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OneTimeExpenseQuery,
    OneTimeExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    OneTimeExpenseQuery,
    OneTimeExpenseQueryVariables
  >(OneTimeExpenseDocument, options);
}
export function useOneTimeExpenseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OneTimeExpenseQuery,
    OneTimeExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OneTimeExpenseQuery,
    OneTimeExpenseQueryVariables
  >(OneTimeExpenseDocument, options);
}
export type OneTimeExpenseQueryHookResult = ReturnType<
  typeof useOneTimeExpenseQuery
>;
export type OneTimeExpenseLazyQueryHookResult = ReturnType<
  typeof useOneTimeExpenseLazyQuery
>;
export type OneTimeExpenseQueryResult = Apollo.QueryResult<
  OneTimeExpenseQuery,
  OneTimeExpenseQueryVariables
>;
export function refetchOneTimeExpenseQuery(
  variables: OneTimeExpenseQueryVariables,
) {
  return { query: OneTimeExpenseDocument, variables: variables };
}
export const CreateRepeatingExpenseDocument = gql`
  mutation CreateRepeatingExpense(
    $amount: Int!
    $name: String!
    $categoryId: ID!
    $startDate: Date!
    $dayOfMonth: Int!
    $supplierId: ID
  ) {
    createRepeatingExpense(
      amount: $amount
      name: $name
      supplierId: $supplierId
      categoryId: $categoryId
      recurrencePattern: {
        interval: MONTH
        startDate: $startDate
        dayOfMonth: $dayOfMonth
      }
    ) {
      id
    }
  }
`;
export type CreateRepeatingExpenseMutationFn = Apollo.MutationFunction<
  CreateRepeatingExpenseMutation,
  CreateRepeatingExpenseMutationVariables
>;
export type CreateRepeatingExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateRepeatingExpenseMutation,
    CreateRepeatingExpenseMutationVariables
  >,
  'mutation'
>;

export const CreateRepeatingExpenseComponent = (
  props: CreateRepeatingExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CreateRepeatingExpenseMutation,
    CreateRepeatingExpenseMutationVariables
  >
    mutation={CreateRepeatingExpenseDocument}
    {...props}
  />
);

/**
 * __useCreateRepeatingExpenseMutation__
 *
 * To run a mutation, you first call `useCreateRepeatingExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRepeatingExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRepeatingExpenseMutation, { data, loading, error }] = useCreateRepeatingExpenseMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      name: // value for 'name'
 *      categoryId: // value for 'categoryId'
 *      startDate: // value for 'startDate'
 *      dayOfMonth: // value for 'dayOfMonth'
 *      supplierId: // value for 'supplierId'
 *   },
 * });
 */
export function useCreateRepeatingExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateRepeatingExpenseMutation,
    CreateRepeatingExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateRepeatingExpenseMutation,
    CreateRepeatingExpenseMutationVariables
  >(CreateRepeatingExpenseDocument, options);
}
export type CreateRepeatingExpenseMutationHookResult = ReturnType<
  typeof useCreateRepeatingExpenseMutation
>;
export type CreateRepeatingExpenseMutationResult =
  Apollo.MutationResult<CreateRepeatingExpenseMutation>;
export type CreateRepeatingExpenseMutationOptions = Apollo.BaseMutationOptions<
  CreateRepeatingExpenseMutation,
  CreateRepeatingExpenseMutationVariables
>;
export const MarkRepeatingExpensePaidDocument = gql`
  mutation MarkRepeatingExpensePaid($id: ID!, $date: Date!) {
    markRepeatingExpensePaid(id: $id, date: $date)
  }
`;
export type MarkRepeatingExpensePaidMutationFn = Apollo.MutationFunction<
  MarkRepeatingExpensePaidMutation,
  MarkRepeatingExpensePaidMutationVariables
>;
export type MarkRepeatingExpensePaidComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MarkRepeatingExpensePaidMutation,
    MarkRepeatingExpensePaidMutationVariables
  >,
  'mutation'
>;

export const MarkRepeatingExpensePaidComponent = (
  props: MarkRepeatingExpensePaidComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    MarkRepeatingExpensePaidMutation,
    MarkRepeatingExpensePaidMutationVariables
  >
    mutation={MarkRepeatingExpensePaidDocument}
    {...props}
  />
);

/**
 * __useMarkRepeatingExpensePaidMutation__
 *
 * To run a mutation, you first call `useMarkRepeatingExpensePaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkRepeatingExpensePaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markRepeatingExpensePaidMutation, { data, loading, error }] = useMarkRepeatingExpensePaidMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useMarkRepeatingExpensePaidMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkRepeatingExpensePaidMutation,
    MarkRepeatingExpensePaidMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MarkRepeatingExpensePaidMutation,
    MarkRepeatingExpensePaidMutationVariables
  >(MarkRepeatingExpensePaidDocument, options);
}
export type MarkRepeatingExpensePaidMutationHookResult = ReturnType<
  typeof useMarkRepeatingExpensePaidMutation
>;
export type MarkRepeatingExpensePaidMutationResult =
  Apollo.MutationResult<MarkRepeatingExpensePaidMutation>;
export type MarkRepeatingExpensePaidMutationOptions =
  Apollo.BaseMutationOptions<
    MarkRepeatingExpensePaidMutation,
    MarkRepeatingExpensePaidMutationVariables
  >;
export const UnmarkRepeatingExpensePaidDocument = gql`
  mutation UnmarkRepeatingExpensePaid($id: ID!, $date: Date!) {
    unmarkRepeatingExpensePaid(id: $id, date: $date)
  }
`;
export type UnmarkRepeatingExpensePaidMutationFn = Apollo.MutationFunction<
  UnmarkRepeatingExpensePaidMutation,
  UnmarkRepeatingExpensePaidMutationVariables
>;
export type UnmarkRepeatingExpensePaidComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UnmarkRepeatingExpensePaidMutation,
    UnmarkRepeatingExpensePaidMutationVariables
  >,
  'mutation'
>;

export const UnmarkRepeatingExpensePaidComponent = (
  props: UnmarkRepeatingExpensePaidComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UnmarkRepeatingExpensePaidMutation,
    UnmarkRepeatingExpensePaidMutationVariables
  >
    mutation={UnmarkRepeatingExpensePaidDocument}
    {...props}
  />
);

/**
 * __useUnmarkRepeatingExpensePaidMutation__
 *
 * To run a mutation, you first call `useUnmarkRepeatingExpensePaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmarkRepeatingExpensePaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmarkRepeatingExpensePaidMutation, { data, loading, error }] = useUnmarkRepeatingExpensePaidMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUnmarkRepeatingExpensePaidMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnmarkRepeatingExpensePaidMutation,
    UnmarkRepeatingExpensePaidMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UnmarkRepeatingExpensePaidMutation,
    UnmarkRepeatingExpensePaidMutationVariables
  >(UnmarkRepeatingExpensePaidDocument, options);
}
export type UnmarkRepeatingExpensePaidMutationHookResult = ReturnType<
  typeof useUnmarkRepeatingExpensePaidMutation
>;
export type UnmarkRepeatingExpensePaidMutationResult =
  Apollo.MutationResult<UnmarkRepeatingExpensePaidMutation>;
export type UnmarkRepeatingExpensePaidMutationOptions =
  Apollo.BaseMutationOptions<
    UnmarkRepeatingExpensePaidMutation,
    UnmarkRepeatingExpensePaidMutationVariables
  >;
export const SkipRepeatingExpenseDocument = gql`
  mutation SkipRepeatingExpense($id: ID!, $date: Date!) {
    skipRepeatingExpense(id: $id, date: $date) {
      id
    }
  }
`;
export type SkipRepeatingExpenseMutationFn = Apollo.MutationFunction<
  SkipRepeatingExpenseMutation,
  SkipRepeatingExpenseMutationVariables
>;
export type SkipRepeatingExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    SkipRepeatingExpenseMutation,
    SkipRepeatingExpenseMutationVariables
  >,
  'mutation'
>;

export const SkipRepeatingExpenseComponent = (
  props: SkipRepeatingExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    SkipRepeatingExpenseMutation,
    SkipRepeatingExpenseMutationVariables
  >
    mutation={SkipRepeatingExpenseDocument}
    {...props}
  />
);

/**
 * __useSkipRepeatingExpenseMutation__
 *
 * To run a mutation, you first call `useSkipRepeatingExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSkipRepeatingExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [skipRepeatingExpenseMutation, { data, loading, error }] = useSkipRepeatingExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useSkipRepeatingExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SkipRepeatingExpenseMutation,
    SkipRepeatingExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    SkipRepeatingExpenseMutation,
    SkipRepeatingExpenseMutationVariables
  >(SkipRepeatingExpenseDocument, options);
}
export type SkipRepeatingExpenseMutationHookResult = ReturnType<
  typeof useSkipRepeatingExpenseMutation
>;
export type SkipRepeatingExpenseMutationResult =
  Apollo.MutationResult<SkipRepeatingExpenseMutation>;
export type SkipRepeatingExpenseMutationOptions = Apollo.BaseMutationOptions<
  SkipRepeatingExpenseMutation,
  SkipRepeatingExpenseMutationVariables
>;
export const TerminateRepeatingExpenseDocument = gql`
  mutation TerminateRepeatingExpense($id: ID!, $date: Date!) {
    terminateRepeatingExpense(id: $id, terminationDate: $date) {
      id
    }
  }
`;
export type TerminateRepeatingExpenseMutationFn = Apollo.MutationFunction<
  TerminateRepeatingExpenseMutation,
  TerminateRepeatingExpenseMutationVariables
>;
export type TerminateRepeatingExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    TerminateRepeatingExpenseMutation,
    TerminateRepeatingExpenseMutationVariables
  >,
  'mutation'
>;

export const TerminateRepeatingExpenseComponent = (
  props: TerminateRepeatingExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    TerminateRepeatingExpenseMutation,
    TerminateRepeatingExpenseMutationVariables
  >
    mutation={TerminateRepeatingExpenseDocument}
    {...props}
  />
);

/**
 * __useTerminateRepeatingExpenseMutation__
 *
 * To run a mutation, you first call `useTerminateRepeatingExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTerminateRepeatingExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [terminateRepeatingExpenseMutation, { data, loading, error }] = useTerminateRepeatingExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useTerminateRepeatingExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    TerminateRepeatingExpenseMutation,
    TerminateRepeatingExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    TerminateRepeatingExpenseMutation,
    TerminateRepeatingExpenseMutationVariables
  >(TerminateRepeatingExpenseDocument, options);
}
export type TerminateRepeatingExpenseMutationHookResult = ReturnType<
  typeof useTerminateRepeatingExpenseMutation
>;
export type TerminateRepeatingExpenseMutationResult =
  Apollo.MutationResult<TerminateRepeatingExpenseMutation>;
export type TerminateRepeatingExpenseMutationOptions =
  Apollo.BaseMutationOptions<
    TerminateRepeatingExpenseMutation,
    TerminateRepeatingExpenseMutationVariables
  >;
export const RemoveRepeatingExpenseDocument = gql`
  mutation RemoveRepeatingExpense($id: ID!) {
    removeRepeatingExpense(id: $id)
  }
`;
export type RemoveRepeatingExpenseMutationFn = Apollo.MutationFunction<
  RemoveRepeatingExpenseMutation,
  RemoveRepeatingExpenseMutationVariables
>;
export type RemoveRepeatingExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveRepeatingExpenseMutation,
    RemoveRepeatingExpenseMutationVariables
  >,
  'mutation'
>;

export const RemoveRepeatingExpenseComponent = (
  props: RemoveRepeatingExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    RemoveRepeatingExpenseMutation,
    RemoveRepeatingExpenseMutationVariables
  >
    mutation={RemoveRepeatingExpenseDocument}
    {...props}
  />
);

/**
 * __useRemoveRepeatingExpenseMutation__
 *
 * To run a mutation, you first call `useRemoveRepeatingExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRepeatingExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRepeatingExpenseMutation, { data, loading, error }] = useRemoveRepeatingExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRepeatingExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveRepeatingExpenseMutation,
    RemoveRepeatingExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RemoveRepeatingExpenseMutation,
    RemoveRepeatingExpenseMutationVariables
  >(RemoveRepeatingExpenseDocument, options);
}
export type RemoveRepeatingExpenseMutationHookResult = ReturnType<
  typeof useRemoveRepeatingExpenseMutation
>;
export type RemoveRepeatingExpenseMutationResult =
  Apollo.MutationResult<RemoveRepeatingExpenseMutation>;
export type RemoveRepeatingExpenseMutationOptions = Apollo.BaseMutationOptions<
  RemoveRepeatingExpenseMutation,
  RemoveRepeatingExpenseMutationVariables
>;
export const RecurringExpensesTimeSeriesDocument = gql`
  query RecurringExpensesTimeSeries($from: Date!, $to: Date!) {
    recurringExpensesTimeSeries(from: $from, to: $to) {
      id
      date
      repeatingExpenseId
      currencyCode
      currencyPrecision
      isPaid
      repeatingExpense {
        id
        category {
          id
          name
        }
        supplier {
          id
          name
        }
        amount
        name
        recurrencePattern {
          id
          interval
          startDate
        }
      }
    }
  }
`;
export type RecurringExpensesTimeSeriesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    RecurringExpensesTimeSeriesQuery,
    RecurringExpensesTimeSeriesQueryVariables
  >,
  'query'
> &
  (
    | { variables: RecurringExpensesTimeSeriesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const RecurringExpensesTimeSeriesComponent = (
  props: RecurringExpensesTimeSeriesComponentProps,
) => (
  <ApolloReactComponents.Query<
    RecurringExpensesTimeSeriesQuery,
    RecurringExpensesTimeSeriesQueryVariables
  >
    query={RecurringExpensesTimeSeriesDocument}
    {...props}
  />
);

/**
 * __useRecurringExpensesTimeSeriesQuery__
 *
 * To run a query within a React component, call `useRecurringExpensesTimeSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecurringExpensesTimeSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurringExpensesTimeSeriesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useRecurringExpensesTimeSeriesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    RecurringExpensesTimeSeriesQuery,
    RecurringExpensesTimeSeriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    RecurringExpensesTimeSeriesQuery,
    RecurringExpensesTimeSeriesQueryVariables
  >(RecurringExpensesTimeSeriesDocument, options);
}
export function useRecurringExpensesTimeSeriesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RecurringExpensesTimeSeriesQuery,
    RecurringExpensesTimeSeriesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    RecurringExpensesTimeSeriesQuery,
    RecurringExpensesTimeSeriesQueryVariables
  >(RecurringExpensesTimeSeriesDocument, options);
}
export type RecurringExpensesTimeSeriesQueryHookResult = ReturnType<
  typeof useRecurringExpensesTimeSeriesQuery
>;
export type RecurringExpensesTimeSeriesLazyQueryHookResult = ReturnType<
  typeof useRecurringExpensesTimeSeriesLazyQuery
>;
export type RecurringExpensesTimeSeriesQueryResult = Apollo.QueryResult<
  RecurringExpensesTimeSeriesQuery,
  RecurringExpensesTimeSeriesQueryVariables
>;
export function refetchRecurringExpensesTimeSeriesQuery(
  variables: RecurringExpensesTimeSeriesQueryVariables,
) {
  return { query: RecurringExpensesTimeSeriesDocument, variables: variables };
}
export const RecurringExpensesTotalAmountDocument = gql`
  query RecurringExpensesTotalAmount($from: Date!, $to: Date!) {
    recurringExpensesTotalAmount(from: $from, to: $to) {
      currencyCode
      precision
      id
      amount
    }
  }
`;
export type RecurringExpensesTotalAmountComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    RecurringExpensesTotalAmountQuery,
    RecurringExpensesTotalAmountQueryVariables
  >,
  'query'
> &
  (
    | { variables: RecurringExpensesTotalAmountQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const RecurringExpensesTotalAmountComponent = (
  props: RecurringExpensesTotalAmountComponentProps,
) => (
  <ApolloReactComponents.Query<
    RecurringExpensesTotalAmountQuery,
    RecurringExpensesTotalAmountQueryVariables
  >
    query={RecurringExpensesTotalAmountDocument}
    {...props}
  />
);

/**
 * __useRecurringExpensesTotalAmountQuery__
 *
 * To run a query within a React component, call `useRecurringExpensesTotalAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecurringExpensesTotalAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecurringExpensesTotalAmountQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useRecurringExpensesTotalAmountQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    RecurringExpensesTotalAmountQuery,
    RecurringExpensesTotalAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    RecurringExpensesTotalAmountQuery,
    RecurringExpensesTotalAmountQueryVariables
  >(RecurringExpensesTotalAmountDocument, options);
}
export function useRecurringExpensesTotalAmountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RecurringExpensesTotalAmountQuery,
    RecurringExpensesTotalAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    RecurringExpensesTotalAmountQuery,
    RecurringExpensesTotalAmountQueryVariables
  >(RecurringExpensesTotalAmountDocument, options);
}
export type RecurringExpensesTotalAmountQueryHookResult = ReturnType<
  typeof useRecurringExpensesTotalAmountQuery
>;
export type RecurringExpensesTotalAmountLazyQueryHookResult = ReturnType<
  typeof useRecurringExpensesTotalAmountLazyQuery
>;
export type RecurringExpensesTotalAmountQueryResult = Apollo.QueryResult<
  RecurringExpensesTotalAmountQuery,
  RecurringExpensesTotalAmountQueryVariables
>;
export function refetchRecurringExpensesTotalAmountQuery(
  variables: RecurringExpensesTotalAmountQueryVariables,
) {
  return { query: RecurringExpensesTotalAmountDocument, variables: variables };
}
export const CategoriesAndSuppliersDocument = gql`
  query CategoriesAndSuppliers {
    categories {
      items {
        id
        name
      }
    }
    suppliers {
      items {
        id
        name
      }
    }
  }
`;
export type CategoriesAndSuppliersComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CategoriesAndSuppliersQuery,
    CategoriesAndSuppliersQueryVariables
  >,
  'query'
>;

export const CategoriesAndSuppliersComponent = (
  props: CategoriesAndSuppliersComponentProps,
) => (
  <ApolloReactComponents.Query<
    CategoriesAndSuppliersQuery,
    CategoriesAndSuppliersQueryVariables
  >
    query={CategoriesAndSuppliersDocument}
    {...props}
  />
);

/**
 * __useCategoriesAndSuppliersQuery__
 *
 * To run a query within a React component, call `useCategoriesAndSuppliersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesAndSuppliersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCategoriesAndSuppliersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCategoriesAndSuppliersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CategoriesAndSuppliersQuery,
    CategoriesAndSuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CategoriesAndSuppliersQuery,
    CategoriesAndSuppliersQueryVariables
  >(CategoriesAndSuppliersDocument, options);
}
export function useCategoriesAndSuppliersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CategoriesAndSuppliersQuery,
    CategoriesAndSuppliersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CategoriesAndSuppliersQuery,
    CategoriesAndSuppliersQueryVariables
  >(CategoriesAndSuppliersDocument, options);
}
export type CategoriesAndSuppliersQueryHookResult = ReturnType<
  typeof useCategoriesAndSuppliersQuery
>;
export type CategoriesAndSuppliersLazyQueryHookResult = ReturnType<
  typeof useCategoriesAndSuppliersLazyQuery
>;
export type CategoriesAndSuppliersQueryResult = Apollo.QueryResult<
  CategoriesAndSuppliersQuery,
  CategoriesAndSuppliersQueryVariables
>;
export function refetchCategoriesAndSuppliersQuery(
  variables?: CategoriesAndSuppliersQueryVariables,
) {
  return { query: CategoriesAndSuppliersDocument, variables: variables };
}
export const CreateSupplyExpenseDocument = gql`
  mutation CreateSupplyExpense(
    $amount: Int!
    $dueDate: Date!
    $accountingDate: Date!
    $invoiceNumber: String
    $supplierId: ID!
    $categoryId: ID!
    $link: String
  ) {
    createSupplyExpense(
      amount: $amount
      dueDate: $dueDate
      accountingDate: $accountingDate
      invoiceNumber: $invoiceNumber
      supplierId: $supplierId
      categoryId: $categoryId
      link: $link
    ) {
      id
    }
  }
`;
export type CreateSupplyExpenseMutationFn = Apollo.MutationFunction<
  CreateSupplyExpenseMutation,
  CreateSupplyExpenseMutationVariables
>;
export type CreateSupplyExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateSupplyExpenseMutation,
    CreateSupplyExpenseMutationVariables
  >,
  'mutation'
>;

export const CreateSupplyExpenseComponent = (
  props: CreateSupplyExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CreateSupplyExpenseMutation,
    CreateSupplyExpenseMutationVariables
  >
    mutation={CreateSupplyExpenseDocument}
    {...props}
  />
);

/**
 * __useCreateSupplyExpenseMutation__
 *
 * To run a mutation, you first call `useCreateSupplyExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplyExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplyExpenseMutation, { data, loading, error }] = useCreateSupplyExpenseMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      dueDate: // value for 'dueDate'
 *      accountingDate: // value for 'accountingDate'
 *      invoiceNumber: // value for 'invoiceNumber'
 *      supplierId: // value for 'supplierId'
 *      categoryId: // value for 'categoryId'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useCreateSupplyExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateSupplyExpenseMutation,
    CreateSupplyExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateSupplyExpenseMutation,
    CreateSupplyExpenseMutationVariables
  >(CreateSupplyExpenseDocument, options);
}
export type CreateSupplyExpenseMutationHookResult = ReturnType<
  typeof useCreateSupplyExpenseMutation
>;
export type CreateSupplyExpenseMutationResult =
  Apollo.MutationResult<CreateSupplyExpenseMutation>;
export type CreateSupplyExpenseMutationOptions = Apollo.BaseMutationOptions<
  CreateSupplyExpenseMutation,
  CreateSupplyExpenseMutationVariables
>;
export const UpdateSupplyExpenseDocument = gql`
  mutation UpdateSupplyExpense(
    $id: ID!
    $amount: Int
    $supplierId: ID
    $categoryId: ID
    $dueDate: Date
    $accountingDate: Date
    $invoiceNumber: String
    $link: String
  ) {
    updateSupplyExpense(
      id: $id
      amount: $amount
      supplierId: $supplierId
      categoryId: $categoryId
      dueDate: $dueDate
      accountingDate: $accountingDate
      invoiceNumber: $invoiceNumber
      link: $link
    ) {
      id
      amount
      supplierId
      categoryId
      dueDate
      accountingDate
      invoiceNumber
      link
    }
  }
`;
export type UpdateSupplyExpenseMutationFn = Apollo.MutationFunction<
  UpdateSupplyExpenseMutation,
  UpdateSupplyExpenseMutationVariables
>;
export type UpdateSupplyExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateSupplyExpenseMutation,
    UpdateSupplyExpenseMutationVariables
  >,
  'mutation'
>;

export const UpdateSupplyExpenseComponent = (
  props: UpdateSupplyExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UpdateSupplyExpenseMutation,
    UpdateSupplyExpenseMutationVariables
  >
    mutation={UpdateSupplyExpenseDocument}
    {...props}
  />
);

/**
 * __useUpdateSupplyExpenseMutation__
 *
 * To run a mutation, you first call `useUpdateSupplyExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplyExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplyExpenseMutation, { data, loading, error }] = useUpdateSupplyExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      amount: // value for 'amount'
 *      supplierId: // value for 'supplierId'
 *      categoryId: // value for 'categoryId'
 *      dueDate: // value for 'dueDate'
 *      accountingDate: // value for 'accountingDate'
 *      invoiceNumber: // value for 'invoiceNumber'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useUpdateSupplyExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSupplyExpenseMutation,
    UpdateSupplyExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateSupplyExpenseMutation,
    UpdateSupplyExpenseMutationVariables
  >(UpdateSupplyExpenseDocument, options);
}
export type UpdateSupplyExpenseMutationHookResult = ReturnType<
  typeof useUpdateSupplyExpenseMutation
>;
export type UpdateSupplyExpenseMutationResult =
  Apollo.MutationResult<UpdateSupplyExpenseMutation>;
export type UpdateSupplyExpenseMutationOptions = Apollo.BaseMutationOptions<
  UpdateSupplyExpenseMutation,
  UpdateSupplyExpenseMutationVariables
>;
export const MarkSupplyExpensePayedDocument = gql`
  mutation MarkSupplyExpensePayed($id: ID!) {
    markSupplyExpensePayed(id: $id) {
      id
      paymentId
    }
  }
`;
export type MarkSupplyExpensePayedMutationFn = Apollo.MutationFunction<
  MarkSupplyExpensePayedMutation,
  MarkSupplyExpensePayedMutationVariables
>;
export type MarkSupplyExpensePayedComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    MarkSupplyExpensePayedMutation,
    MarkSupplyExpensePayedMutationVariables
  >,
  'mutation'
>;

export const MarkSupplyExpensePayedComponent = (
  props: MarkSupplyExpensePayedComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    MarkSupplyExpensePayedMutation,
    MarkSupplyExpensePayedMutationVariables
  >
    mutation={MarkSupplyExpensePayedDocument}
    {...props}
  />
);

/**
 * __useMarkSupplyExpensePayedMutation__
 *
 * To run a mutation, you first call `useMarkSupplyExpensePayedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkSupplyExpensePayedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markSupplyExpensePayedMutation, { data, loading, error }] = useMarkSupplyExpensePayedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkSupplyExpensePayedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    MarkSupplyExpensePayedMutation,
    MarkSupplyExpensePayedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    MarkSupplyExpensePayedMutation,
    MarkSupplyExpensePayedMutationVariables
  >(MarkSupplyExpensePayedDocument, options);
}
export type MarkSupplyExpensePayedMutationHookResult = ReturnType<
  typeof useMarkSupplyExpensePayedMutation
>;
export type MarkSupplyExpensePayedMutationResult =
  Apollo.MutationResult<MarkSupplyExpensePayedMutation>;
export type MarkSupplyExpensePayedMutationOptions = Apollo.BaseMutationOptions<
  MarkSupplyExpensePayedMutation,
  MarkSupplyExpensePayedMutationVariables
>;
export const UnmarkSupplyExpensePayedDocument = gql`
  mutation UnmarkSupplyExpensePayed($id: ID!) {
    unmarkSupplyExpensePayed(id: $id) {
      id
      paymentId
    }
  }
`;
export type UnmarkSupplyExpensePayedMutationFn = Apollo.MutationFunction<
  UnmarkSupplyExpensePayedMutation,
  UnmarkSupplyExpensePayedMutationVariables
>;
export type UnmarkSupplyExpensePayedComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UnmarkSupplyExpensePayedMutation,
    UnmarkSupplyExpensePayedMutationVariables
  >,
  'mutation'
>;

export const UnmarkSupplyExpensePayedComponent = (
  props: UnmarkSupplyExpensePayedComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UnmarkSupplyExpensePayedMutation,
    UnmarkSupplyExpensePayedMutationVariables
  >
    mutation={UnmarkSupplyExpensePayedDocument}
    {...props}
  />
);

/**
 * __useUnmarkSupplyExpensePayedMutation__
 *
 * To run a mutation, you first call `useUnmarkSupplyExpensePayedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnmarkSupplyExpensePayedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unmarkSupplyExpensePayedMutation, { data, loading, error }] = useUnmarkSupplyExpensePayedMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnmarkSupplyExpensePayedMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UnmarkSupplyExpensePayedMutation,
    UnmarkSupplyExpensePayedMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UnmarkSupplyExpensePayedMutation,
    UnmarkSupplyExpensePayedMutationVariables
  >(UnmarkSupplyExpensePayedDocument, options);
}
export type UnmarkSupplyExpensePayedMutationHookResult = ReturnType<
  typeof useUnmarkSupplyExpensePayedMutation
>;
export type UnmarkSupplyExpensePayedMutationResult =
  Apollo.MutationResult<UnmarkSupplyExpensePayedMutation>;
export type UnmarkSupplyExpensePayedMutationOptions =
  Apollo.BaseMutationOptions<
    UnmarkSupplyExpensePayedMutation,
    UnmarkSupplyExpensePayedMutationVariables
  >;
export const SuppliesExpensesTotalAmountDocument = gql`
  query SuppliesExpensesTotalAmount($from: Date!, $to: Date!) {
    supplyExpensesTotalAmount(from: $from, to: $to) {
      id
      amount
      currencyCode
      precision
    }
  }
`;
export type SuppliesExpensesTotalAmountComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SuppliesExpensesTotalAmountQuery,
    SuppliesExpensesTotalAmountQueryVariables
  >,
  'query'
> &
  (
    | { variables: SuppliesExpensesTotalAmountQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const SuppliesExpensesTotalAmountComponent = (
  props: SuppliesExpensesTotalAmountComponentProps,
) => (
  <ApolloReactComponents.Query<
    SuppliesExpensesTotalAmountQuery,
    SuppliesExpensesTotalAmountQueryVariables
  >
    query={SuppliesExpensesTotalAmountDocument}
    {...props}
  />
);

/**
 * __useSuppliesExpensesTotalAmountQuery__
 *
 * To run a query within a React component, call `useSuppliesExpensesTotalAmountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliesExpensesTotalAmountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliesExpensesTotalAmountQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useSuppliesExpensesTotalAmountQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SuppliesExpensesTotalAmountQuery,
    SuppliesExpensesTotalAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SuppliesExpensesTotalAmountQuery,
    SuppliesExpensesTotalAmountQueryVariables
  >(SuppliesExpensesTotalAmountDocument, options);
}
export function useSuppliesExpensesTotalAmountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SuppliesExpensesTotalAmountQuery,
    SuppliesExpensesTotalAmountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SuppliesExpensesTotalAmountQuery,
    SuppliesExpensesTotalAmountQueryVariables
  >(SuppliesExpensesTotalAmountDocument, options);
}
export type SuppliesExpensesTotalAmountQueryHookResult = ReturnType<
  typeof useSuppliesExpensesTotalAmountQuery
>;
export type SuppliesExpensesTotalAmountLazyQueryHookResult = ReturnType<
  typeof useSuppliesExpensesTotalAmountLazyQuery
>;
export type SuppliesExpensesTotalAmountQueryResult = Apollo.QueryResult<
  SuppliesExpensesTotalAmountQuery,
  SuppliesExpensesTotalAmountQueryVariables
>;
export function refetchSuppliesExpensesTotalAmountQuery(
  variables: SuppliesExpensesTotalAmountQueryVariables,
) {
  return { query: SuppliesExpensesTotalAmountDocument, variables: variables };
}
export const SuppliesExpensesDocument = gql`
  query SuppliesExpenses($from: Date!, $to: Date!) {
    supplyExpenses(dateFilter: { from: $from, to: $to }) {
      items {
        id
        amount
        currency {
          currencyCode
          precision
        }
        supplier {
          id
          name
        }
        category {
          id
          name
        }
        paymentId
        dueDate
        accountingDate
        invoiceNumber
        link
      }
    }
  }
`;
export type SuppliesExpensesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SuppliesExpensesQuery,
    SuppliesExpensesQueryVariables
  >,
  'query'
> &
  (
    | { variables: SuppliesExpensesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const SuppliesExpensesComponent = (
  props: SuppliesExpensesComponentProps,
) => (
  <ApolloReactComponents.Query<
    SuppliesExpensesQuery,
    SuppliesExpensesQueryVariables
  >
    query={SuppliesExpensesDocument}
    {...props}
  />
);

/**
 * __useSuppliesExpensesQuery__
 *
 * To run a query within a React component, call `useSuppliesExpensesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuppliesExpensesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuppliesExpensesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useSuppliesExpensesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SuppliesExpensesQuery,
    SuppliesExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SuppliesExpensesQuery,
    SuppliesExpensesQueryVariables
  >(SuppliesExpensesDocument, options);
}
export function useSuppliesExpensesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SuppliesExpensesQuery,
    SuppliesExpensesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SuppliesExpensesQuery,
    SuppliesExpensesQueryVariables
  >(SuppliesExpensesDocument, options);
}
export type SuppliesExpensesQueryHookResult = ReturnType<
  typeof useSuppliesExpensesQuery
>;
export type SuppliesExpensesLazyQueryHookResult = ReturnType<
  typeof useSuppliesExpensesLazyQuery
>;
export type SuppliesExpensesQueryResult = Apollo.QueryResult<
  SuppliesExpensesQuery,
  SuppliesExpensesQueryVariables
>;
export function refetchSuppliesExpensesQuery(
  variables: SuppliesExpensesQueryVariables,
) {
  return { query: SuppliesExpensesDocument, variables: variables };
}
export const SupplyExpenseDocument = gql`
  query SupplyExpense($id: ID!) {
    supplyExpense(id: $id) {
      id
      amount
      currency {
        currencyCode
        precision
      }
      invoiceNumber
      supplierId
      categoryId
      paymentId
      accountingDate
      dueDate
      link
    }
  }
`;
export type SupplyExpenseComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    SupplyExpenseQuery,
    SupplyExpenseQueryVariables
  >,
  'query'
> &
  (
    | { variables: SupplyExpenseQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const SupplyExpenseComponent = (props: SupplyExpenseComponentProps) => (
  <ApolloReactComponents.Query<SupplyExpenseQuery, SupplyExpenseQueryVariables>
    query={SupplyExpenseDocument}
    {...props}
  />
);

/**
 * __useSupplyExpenseQuery__
 *
 * To run a query within a React component, call `useSupplyExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupplyExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupplyExpenseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSupplyExpenseQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    SupplyExpenseQuery,
    SupplyExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    SupplyExpenseQuery,
    SupplyExpenseQueryVariables
  >(SupplyExpenseDocument, options);
}
export function useSupplyExpenseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    SupplyExpenseQuery,
    SupplyExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    SupplyExpenseQuery,
    SupplyExpenseQueryVariables
  >(SupplyExpenseDocument, options);
}
export type SupplyExpenseQueryHookResult = ReturnType<
  typeof useSupplyExpenseQuery
>;
export type SupplyExpenseLazyQueryHookResult = ReturnType<
  typeof useSupplyExpenseLazyQuery
>;
export type SupplyExpenseQueryResult = Apollo.QueryResult<
  SupplyExpenseQuery,
  SupplyExpenseQueryVariables
>;
export function refetchSupplyExpenseQuery(
  variables: SupplyExpenseQueryVariables,
) {
  return { query: SupplyExpenseDocument, variables: variables };
}
export const RemoveSupplyExpenseDocument = gql`
  mutation RemoveSupplyExpense($id: ID!) {
    removeSupplyExpense(id: $id)
  }
`;
export type RemoveSupplyExpenseMutationFn = Apollo.MutationFunction<
  RemoveSupplyExpenseMutation,
  RemoveSupplyExpenseMutationVariables
>;
export type RemoveSupplyExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveSupplyExpenseMutation,
    RemoveSupplyExpenseMutationVariables
  >,
  'mutation'
>;

export const RemoveSupplyExpenseComponent = (
  props: RemoveSupplyExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    RemoveSupplyExpenseMutation,
    RemoveSupplyExpenseMutationVariables
  >
    mutation={RemoveSupplyExpenseDocument}
    {...props}
  />
);

/**
 * __useRemoveSupplyExpenseMutation__
 *
 * To run a mutation, you first call `useRemoveSupplyExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSupplyExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSupplyExpenseMutation, { data, loading, error }] = useRemoveSupplyExpenseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSupplyExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveSupplyExpenseMutation,
    RemoveSupplyExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RemoveSupplyExpenseMutation,
    RemoveSupplyExpenseMutationVariables
  >(RemoveSupplyExpenseDocument, options);
}
export type RemoveSupplyExpenseMutationHookResult = ReturnType<
  typeof useRemoveSupplyExpenseMutation
>;
export type RemoveSupplyExpenseMutationResult =
  Apollo.MutationResult<RemoveSupplyExpenseMutation>;
export type RemoveSupplyExpenseMutationOptions = Apollo.BaseMutationOptions<
  RemoveSupplyExpenseMutation,
  RemoveSupplyExpenseMutationVariables
>;
export const UploadInvoiceDocument = gql`
  mutation UploadInvoice($files: [Upload!]!) {
    uploadInvoice(files: $files) {
      id
    }
  }
`;
export type UploadInvoiceMutationFn = Apollo.MutationFunction<
  UploadInvoiceMutation,
  UploadInvoiceMutationVariables
>;
export type UploadInvoiceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UploadInvoiceMutation,
    UploadInvoiceMutationVariables
  >,
  'mutation'
>;

export const UploadInvoiceComponent = (props: UploadInvoiceComponentProps) => (
  <ApolloReactComponents.Mutation<
    UploadInvoiceMutation,
    UploadInvoiceMutationVariables
  >
    mutation={UploadInvoiceDocument}
    {...props}
  />
);

/**
 * __useUploadInvoiceMutation__
 *
 * To run a mutation, you first call `useUploadInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadInvoiceMutation, { data, loading, error }] = useUploadInvoiceMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadInvoiceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UploadInvoiceMutation,
    UploadInvoiceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UploadInvoiceMutation,
    UploadInvoiceMutationVariables
  >(UploadInvoiceDocument, options);
}
export type UploadInvoiceMutationHookResult = ReturnType<
  typeof useUploadInvoiceMutation
>;
export type UploadInvoiceMutationResult =
  Apollo.MutationResult<UploadInvoiceMutation>;
export type UploadInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UploadInvoiceMutation,
  UploadInvoiceMutationVariables
>;
export const ConvertOcrExpenseToOneTimeExpenseDocument = gql`
  mutation ConvertOcrExpenseToOneTimeExpense(
    $accountingDate: Date!
    $amount: Int!
    $categoryId: ID!
    $dueDate: Date!
    $link: String
    $name: String!
    $ocrExpenseId: ID!
    $supplierId: ID
    $timezone: String
  ) {
    convertOcrExpenseToOneTimeExpense(
      accountingDate: $accountingDate
      amount: $amount
      categoryId: $categoryId
      dueDate: $dueDate
      link: $link
      name: $name
      ocrExpenseId: $ocrExpenseId
      supplierId: $supplierId
      timezone: $timezone
    )
  }
`;
export type ConvertOcrExpenseToOneTimeExpenseMutationFn =
  Apollo.MutationFunction<
    ConvertOcrExpenseToOneTimeExpenseMutation,
    ConvertOcrExpenseToOneTimeExpenseMutationVariables
  >;
export type ConvertOcrExpenseToOneTimeExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ConvertOcrExpenseToOneTimeExpenseMutation,
    ConvertOcrExpenseToOneTimeExpenseMutationVariables
  >,
  'mutation'
>;

export const ConvertOcrExpenseToOneTimeExpenseComponent = (
  props: ConvertOcrExpenseToOneTimeExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    ConvertOcrExpenseToOneTimeExpenseMutation,
    ConvertOcrExpenseToOneTimeExpenseMutationVariables
  >
    mutation={ConvertOcrExpenseToOneTimeExpenseDocument}
    {...props}
  />
);

/**
 * __useConvertOcrExpenseToOneTimeExpenseMutation__
 *
 * To run a mutation, you first call `useConvertOcrExpenseToOneTimeExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertOcrExpenseToOneTimeExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertOcrExpenseToOneTimeExpenseMutation, { data, loading, error }] = useConvertOcrExpenseToOneTimeExpenseMutation({
 *   variables: {
 *      accountingDate: // value for 'accountingDate'
 *      amount: // value for 'amount'
 *      categoryId: // value for 'categoryId'
 *      dueDate: // value for 'dueDate'
 *      link: // value for 'link'
 *      name: // value for 'name'
 *      ocrExpenseId: // value for 'ocrExpenseId'
 *      supplierId: // value for 'supplierId'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useConvertOcrExpenseToOneTimeExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConvertOcrExpenseToOneTimeExpenseMutation,
    ConvertOcrExpenseToOneTimeExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ConvertOcrExpenseToOneTimeExpenseMutation,
    ConvertOcrExpenseToOneTimeExpenseMutationVariables
  >(ConvertOcrExpenseToOneTimeExpenseDocument, options);
}
export type ConvertOcrExpenseToOneTimeExpenseMutationHookResult = ReturnType<
  typeof useConvertOcrExpenseToOneTimeExpenseMutation
>;
export type ConvertOcrExpenseToOneTimeExpenseMutationResult =
  Apollo.MutationResult<ConvertOcrExpenseToOneTimeExpenseMutation>;
export type ConvertOcrExpenseToOneTimeExpenseMutationOptions =
  Apollo.BaseMutationOptions<
    ConvertOcrExpenseToOneTimeExpenseMutation,
    ConvertOcrExpenseToOneTimeExpenseMutationVariables
  >;
export const ConvertOcrExpenseToSupplyExpenseDocument = gql`
  mutation ConvertOcrExpenseToSupplyExpense(
    $accountingDate: Date!
    $amount: Int!
    $categoryId: ID!
    $dueDate: Date!
    $invoiceNumber: String
    $link: String
    $note: String
    $ocrExpenseId: ID!
    $supplierId: ID!
    $timezone: String
  ) {
    convertOcrExpenseToSupplyExpense(
      accountingDate: $accountingDate
      amount: $amount
      categoryId: $categoryId
      dueDate: $dueDate
      invoiceNumber: $invoiceNumber
      link: $link
      note: $note
      ocrExpenseId: $ocrExpenseId
      supplierId: $supplierId
      timezone: $timezone
    )
  }
`;
export type ConvertOcrExpenseToSupplyExpenseMutationFn =
  Apollo.MutationFunction<
    ConvertOcrExpenseToSupplyExpenseMutation,
    ConvertOcrExpenseToSupplyExpenseMutationVariables
  >;
export type ConvertOcrExpenseToSupplyExpenseComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    ConvertOcrExpenseToSupplyExpenseMutation,
    ConvertOcrExpenseToSupplyExpenseMutationVariables
  >,
  'mutation'
>;

export const ConvertOcrExpenseToSupplyExpenseComponent = (
  props: ConvertOcrExpenseToSupplyExpenseComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    ConvertOcrExpenseToSupplyExpenseMutation,
    ConvertOcrExpenseToSupplyExpenseMutationVariables
  >
    mutation={ConvertOcrExpenseToSupplyExpenseDocument}
    {...props}
  />
);

/**
 * __useConvertOcrExpenseToSupplyExpenseMutation__
 *
 * To run a mutation, you first call `useConvertOcrExpenseToSupplyExpenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConvertOcrExpenseToSupplyExpenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [convertOcrExpenseToSupplyExpenseMutation, { data, loading, error }] = useConvertOcrExpenseToSupplyExpenseMutation({
 *   variables: {
 *      accountingDate: // value for 'accountingDate'
 *      amount: // value for 'amount'
 *      categoryId: // value for 'categoryId'
 *      dueDate: // value for 'dueDate'
 *      invoiceNumber: // value for 'invoiceNumber'
 *      link: // value for 'link'
 *      note: // value for 'note'
 *      ocrExpenseId: // value for 'ocrExpenseId'
 *      supplierId: // value for 'supplierId'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useConvertOcrExpenseToSupplyExpenseMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ConvertOcrExpenseToSupplyExpenseMutation,
    ConvertOcrExpenseToSupplyExpenseMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    ConvertOcrExpenseToSupplyExpenseMutation,
    ConvertOcrExpenseToSupplyExpenseMutationVariables
  >(ConvertOcrExpenseToSupplyExpenseDocument, options);
}
export type ConvertOcrExpenseToSupplyExpenseMutationHookResult = ReturnType<
  typeof useConvertOcrExpenseToSupplyExpenseMutation
>;
export type ConvertOcrExpenseToSupplyExpenseMutationResult =
  Apollo.MutationResult<ConvertOcrExpenseToSupplyExpenseMutation>;
export type ConvertOcrExpenseToSupplyExpenseMutationOptions =
  Apollo.BaseMutationOptions<
    ConvertOcrExpenseToSupplyExpenseMutation,
    ConvertOcrExpenseToSupplyExpenseMutationVariables
  >;
export const InvoiceUploadsDocument = gql`
  query InvoiceUploads {
    invoiceUploads {
      id
      createdAt
      manualUploadMeta {
        id
        createdBy {
          firstName
          lastName
        }
      }
      emailUploadMeta {
        receivedAt
        receivedFrom
      }
      uploadMethod
      items {
        id
        fileName
        fileSize
        mimeType
        state
        uploadError
        bucket
        key
        invoiceUploadId
        ocrExpense {
          id
        }
      }
    }
  }
`;
export type InvoiceUploadsComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    InvoiceUploadsQuery,
    InvoiceUploadsQueryVariables
  >,
  'query'
>;

export const InvoiceUploadsComponent = (
  props: InvoiceUploadsComponentProps,
) => (
  <ApolloReactComponents.Query<
    InvoiceUploadsQuery,
    InvoiceUploadsQueryVariables
  >
    query={InvoiceUploadsDocument}
    {...props}
  />
);

/**
 * __useInvoiceUploadsQuery__
 *
 * To run a query within a React component, call `useInvoiceUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceUploadsQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvoiceUploadsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    InvoiceUploadsQuery,
    InvoiceUploadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    InvoiceUploadsQuery,
    InvoiceUploadsQueryVariables
  >(InvoiceUploadsDocument, options);
}
export function useInvoiceUploadsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InvoiceUploadsQuery,
    InvoiceUploadsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    InvoiceUploadsQuery,
    InvoiceUploadsQueryVariables
  >(InvoiceUploadsDocument, options);
}
export type InvoiceUploadsQueryHookResult = ReturnType<
  typeof useInvoiceUploadsQuery
>;
export type InvoiceUploadsLazyQueryHookResult = ReturnType<
  typeof useInvoiceUploadsLazyQuery
>;
export type InvoiceUploadsQueryResult = Apollo.QueryResult<
  InvoiceUploadsQuery,
  InvoiceUploadsQueryVariables
>;
export function refetchInvoiceUploadsQuery(
  variables?: InvoiceUploadsQueryVariables,
) {
  return { query: InvoiceUploadsDocument, variables: variables };
}
export const OcrExpenseDocument = gql`
  query OcrExpense($id: ID!) {
    ocrExpense(id: $id) {
      id
      accountingDate
      dueDate
      amount
      createdAt
      currency {
        currencyCode
        precision
      }
      invoiceNumber
      supplierId
    }
  }
`;
export type OcrExpenseComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    OcrExpenseQuery,
    OcrExpenseQueryVariables
  >,
  'query'
> &
  ({ variables: OcrExpenseQueryVariables; skip?: boolean } | { skip: boolean });

export const OcrExpenseComponent = (props: OcrExpenseComponentProps) => (
  <ApolloReactComponents.Query<OcrExpenseQuery, OcrExpenseQueryVariables>
    query={OcrExpenseDocument}
    {...props}
  />
);

/**
 * __useOcrExpenseQuery__
 *
 * To run a query within a React component, call `useOcrExpenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useOcrExpenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOcrExpenseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOcrExpenseQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    OcrExpenseQuery,
    OcrExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<OcrExpenseQuery, OcrExpenseQueryVariables>(
    OcrExpenseDocument,
    options,
  );
}
export function useOcrExpenseLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    OcrExpenseQuery,
    OcrExpenseQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    OcrExpenseQuery,
    OcrExpenseQueryVariables
  >(OcrExpenseDocument, options);
}
export type OcrExpenseQueryHookResult = ReturnType<typeof useOcrExpenseQuery>;
export type OcrExpenseLazyQueryHookResult = ReturnType<
  typeof useOcrExpenseLazyQuery
>;
export type OcrExpenseQueryResult = Apollo.QueryResult<
  OcrExpenseQuery,
  OcrExpenseQueryVariables
>;
export function refetchOcrExpenseQuery(variables: OcrExpenseQueryVariables) {
  return { query: OcrExpenseDocument, variables: variables };
}
export const UpdateCurrentAccountDocument = gql`
  mutation UpdateCurrentAccount(
    $cultureCode: CultureCode
    $defaultUserId: ID
    $firstName: String!
    $languageCode: CultureCode
    $lastName: String!
    $password: String
  ) {
    updateCurrentAccount(
      cultureCode: $cultureCode
      defaultUserId: $defaultUserId
      firstName: $firstName
      languageCode: $languageCode
      lastName: $lastName
      password: $password
    ) {
      id
      firstName
      lastName
      email
      languageCode
      cultureCode
      defaultUserId
    }
  }
`;
export type UpdateCurrentAccountMutationFn = Apollo.MutationFunction<
  UpdateCurrentAccountMutation,
  UpdateCurrentAccountMutationVariables
>;
export type UpdateCurrentAccountComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateCurrentAccountMutation,
    UpdateCurrentAccountMutationVariables
  >,
  'mutation'
>;

export const UpdateCurrentAccountComponent = (
  props: UpdateCurrentAccountComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    UpdateCurrentAccountMutation,
    UpdateCurrentAccountMutationVariables
  >
    mutation={UpdateCurrentAccountDocument}
    {...props}
  />
);

/**
 * __useUpdateCurrentAccountMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentAccountMutation, { data, loading, error }] = useUpdateCurrentAccountMutation({
 *   variables: {
 *      cultureCode: // value for 'cultureCode'
 *      defaultUserId: // value for 'defaultUserId'
 *      firstName: // value for 'firstName'
 *      languageCode: // value for 'languageCode'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateCurrentAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateCurrentAccountMutation,
    UpdateCurrentAccountMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateCurrentAccountMutation,
    UpdateCurrentAccountMutationVariables
  >(UpdateCurrentAccountDocument, options);
}
export type UpdateCurrentAccountMutationHookResult = ReturnType<
  typeof useUpdateCurrentAccountMutation
>;
export type UpdateCurrentAccountMutationResult =
  Apollo.MutationResult<UpdateCurrentAccountMutation>;
export type UpdateCurrentAccountMutationOptions = Apollo.BaseMutationOptions<
  UpdateCurrentAccountMutation,
  UpdateCurrentAccountMutationVariables
>;
export const CurrentUserAccountDocument = gql`
  query CurrentUserAccount {
    currentUserAccount {
      id
      firstName
      lastName
      email
      languageCode
      cultureCode
      defaultUserId
      users {
        id
        venue {
          id
          name
        }
      }
    }
  }
`;
export type CurrentUserAccountComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    CurrentUserAccountQuery,
    CurrentUserAccountQueryVariables
  >,
  'query'
>;

export const CurrentUserAccountComponent = (
  props: CurrentUserAccountComponentProps,
) => (
  <ApolloReactComponents.Query<
    CurrentUserAccountQuery,
    CurrentUserAccountQueryVariables
  >
    query={CurrentUserAccountDocument}
    {...props}
  />
);

/**
 * __useCurrentUserAccountQuery__
 *
 * To run a query within a React component, call `useCurrentUserAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserAccountQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    CurrentUserAccountQuery,
    CurrentUserAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    CurrentUserAccountQuery,
    CurrentUserAccountQueryVariables
  >(CurrentUserAccountDocument, options);
}
export function useCurrentUserAccountLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    CurrentUserAccountQuery,
    CurrentUserAccountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    CurrentUserAccountQuery,
    CurrentUserAccountQueryVariables
  >(CurrentUserAccountDocument, options);
}
export type CurrentUserAccountQueryHookResult = ReturnType<
  typeof useCurrentUserAccountQuery
>;
export type CurrentUserAccountLazyQueryHookResult = ReturnType<
  typeof useCurrentUserAccountLazyQuery
>;
export type CurrentUserAccountQueryResult = Apollo.QueryResult<
  CurrentUserAccountQuery,
  CurrentUserAccountQueryVariables
>;
export function refetchCurrentUserAccountQuery(
  variables?: CurrentUserAccountQueryVariables,
) {
  return { query: CurrentUserAccountDocument, variables: variables };
}
export const AddRevenueDocument = gql`
  mutation AddRevenue($amount: Int!, $date: Date!, $revenueSourceId: ID!) {
    addRevenue(
      amount: $amount
      date: $date
      revenueSourceId: $revenueSourceId
      mode: DAY
    ) {
      id
    }
  }
`;
export type AddRevenueMutationFn = Apollo.MutationFunction<
  AddRevenueMutation,
  AddRevenueMutationVariables
>;
export type AddRevenueComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    AddRevenueMutation,
    AddRevenueMutationVariables
  >,
  'mutation'
>;

export const AddRevenueComponent = (props: AddRevenueComponentProps) => (
  <ApolloReactComponents.Mutation<
    AddRevenueMutation,
    AddRevenueMutationVariables
  >
    mutation={AddRevenueDocument}
    {...props}
  />
);

/**
 * __useAddRevenueMutation__
 *
 * To run a mutation, you first call `useAddRevenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRevenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRevenueMutation, { data, loading, error }] = useAddRevenueMutation({
 *   variables: {
 *      amount: // value for 'amount'
 *      date: // value for 'date'
 *      revenueSourceId: // value for 'revenueSourceId'
 *   },
 * });
 */
export function useAddRevenueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    AddRevenueMutation,
    AddRevenueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    AddRevenueMutation,
    AddRevenueMutationVariables
  >(AddRevenueDocument, options);
}
export type AddRevenueMutationHookResult = ReturnType<
  typeof useAddRevenueMutation
>;
export type AddRevenueMutationResult =
  Apollo.MutationResult<AddRevenueMutation>;
export type AddRevenueMutationOptions = Apollo.BaseMutationOptions<
  AddRevenueMutation,
  AddRevenueMutationVariables
>;
export const UpdateRevenueDocument = gql`
  mutation UpdateRevenue(
    $id: ID!
    $amount: Int
    $date: Date
    $revenueSourceId: ID
  ) {
    updateRevenue(
      id: $id
      amount: $amount
      date: $date
      revenueSourceId: $revenueSourceId
    ) {
      id
      amount
      date
      revenueSourceId
    }
  }
`;
export type UpdateRevenueMutationFn = Apollo.MutationFunction<
  UpdateRevenueMutation,
  UpdateRevenueMutationVariables
>;
export type UpdateRevenueComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    UpdateRevenueMutation,
    UpdateRevenueMutationVariables
  >,
  'mutation'
>;

export const UpdateRevenueComponent = (props: UpdateRevenueComponentProps) => (
  <ApolloReactComponents.Mutation<
    UpdateRevenueMutation,
    UpdateRevenueMutationVariables
  >
    mutation={UpdateRevenueDocument}
    {...props}
  />
);

/**
 * __useUpdateRevenueMutation__
 *
 * To run a mutation, you first call `useUpdateRevenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRevenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRevenueMutation, { data, loading, error }] = useUpdateRevenueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      amount: // value for 'amount'
 *      date: // value for 'date'
 *      revenueSourceId: // value for 'revenueSourceId'
 *   },
 * });
 */
export function useUpdateRevenueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateRevenueMutation,
    UpdateRevenueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    UpdateRevenueMutation,
    UpdateRevenueMutationVariables
  >(UpdateRevenueDocument, options);
}
export type UpdateRevenueMutationHookResult = ReturnType<
  typeof useUpdateRevenueMutation
>;
export type UpdateRevenueMutationResult =
  Apollo.MutationResult<UpdateRevenueMutation>;
export type UpdateRevenueMutationOptions = Apollo.BaseMutationOptions<
  UpdateRevenueMutation,
  UpdateRevenueMutationVariables
>;
export const RemoveRevenueDocument = gql`
  mutation RemoveRevenue($id: ID!) {
    removeRevenue(id: $id)
  }
`;
export type RemoveRevenueMutationFn = Apollo.MutationFunction<
  RemoveRevenueMutation,
  RemoveRevenueMutationVariables
>;
export type RemoveRevenueComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    RemoveRevenueMutation,
    RemoveRevenueMutationVariables
  >,
  'mutation'
>;

export const RemoveRevenueComponent = (props: RemoveRevenueComponentProps) => (
  <ApolloReactComponents.Mutation<
    RemoveRevenueMutation,
    RemoveRevenueMutationVariables
  >
    mutation={RemoveRevenueDocument}
    {...props}
  />
);

/**
 * __useRemoveRevenueMutation__
 *
 * To run a mutation, you first call `useRemoveRevenueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRevenueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRevenueMutation, { data, loading, error }] = useRemoveRevenueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveRevenueMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RemoveRevenueMutation,
    RemoveRevenueMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    RemoveRevenueMutation,
    RemoveRevenueMutationVariables
  >(RemoveRevenueDocument, options);
}
export type RemoveRevenueMutationHookResult = ReturnType<
  typeof useRemoveRevenueMutation
>;
export type RemoveRevenueMutationResult =
  Apollo.MutationResult<RemoveRevenueMutation>;
export type RemoveRevenueMutationOptions = Apollo.BaseMutationOptions<
  RemoveRevenueMutation,
  RemoveRevenueMutationVariables
>;
export const CreateRevenueSourceDocument = gql`
  mutation CreateRevenueSource($name: String!) {
    createRevenueSource(name: $name) {
      id
    }
  }
`;
export type CreateRevenueSourceMutationFn = Apollo.MutationFunction<
  CreateRevenueSourceMutation,
  CreateRevenueSourceMutationVariables
>;
export type CreateRevenueSourceComponentProps = Omit<
  ApolloReactComponents.MutationComponentOptions<
    CreateRevenueSourceMutation,
    CreateRevenueSourceMutationVariables
  >,
  'mutation'
>;

export const CreateRevenueSourceComponent = (
  props: CreateRevenueSourceComponentProps,
) => (
  <ApolloReactComponents.Mutation<
    CreateRevenueSourceMutation,
    CreateRevenueSourceMutationVariables
  >
    mutation={CreateRevenueSourceDocument}
    {...props}
  />
);

/**
 * __useCreateRevenueSourceMutation__
 *
 * To run a mutation, you first call `useCreateRevenueSourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRevenueSourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRevenueSourceMutation, { data, loading, error }] = useCreateRevenueSourceMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateRevenueSourceMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    CreateRevenueSourceMutation,
    CreateRevenueSourceMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useMutation<
    CreateRevenueSourceMutation,
    CreateRevenueSourceMutationVariables
  >(CreateRevenueSourceDocument, options);
}
export type CreateRevenueSourceMutationHookResult = ReturnType<
  typeof useCreateRevenueSourceMutation
>;
export type CreateRevenueSourceMutationResult =
  Apollo.MutationResult<CreateRevenueSourceMutation>;
export type CreateRevenueSourceMutationOptions = Apollo.BaseMutationOptions<
  CreateRevenueSourceMutation,
  CreateRevenueSourceMutationVariables
>;
export const RevenueSourcesDocument = gql`
  query RevenueSources {
    revenueSources {
      items {
        id
        name
      }
    }
  }
`;
export type RevenueSourcesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    RevenueSourcesQuery,
    RevenueSourcesQueryVariables
  >,
  'query'
>;

export const RevenueSourcesComponent = (
  props: RevenueSourcesComponentProps,
) => (
  <ApolloReactComponents.Query<
    RevenueSourcesQuery,
    RevenueSourcesQueryVariables
  >
    query={RevenueSourcesDocument}
    {...props}
  />
);

/**
 * __useRevenueSourcesQuery__
 *
 * To run a query within a React component, call `useRevenueSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenueSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenueSourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRevenueSourcesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RevenueSourcesQuery,
    RevenueSourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    RevenueSourcesQuery,
    RevenueSourcesQueryVariables
  >(RevenueSourcesDocument, options);
}
export function useRevenueSourcesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RevenueSourcesQuery,
    RevenueSourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    RevenueSourcesQuery,
    RevenueSourcesQueryVariables
  >(RevenueSourcesDocument, options);
}
export type RevenueSourcesQueryHookResult = ReturnType<
  typeof useRevenueSourcesQuery
>;
export type RevenueSourcesLazyQueryHookResult = ReturnType<
  typeof useRevenueSourcesLazyQuery
>;
export type RevenueSourcesQueryResult = Apollo.QueryResult<
  RevenueSourcesQuery,
  RevenueSourcesQueryVariables
>;
export function refetchRevenueSourcesQuery(
  variables?: RevenueSourcesQueryVariables,
) {
  return { query: RevenueSourcesDocument, variables: variables };
}
export const RevenuesDocument = gql`
  query Revenues($from: Date!, $to: Date!) {
    revenues(dateFilter: { from: $from, to: $to }) {
      items {
        id
        date
        amount
        currency {
          currencyCode
          precision
        }
        revenueSource {
          id
          name
        }
      }
    }
  }
`;
export type RevenuesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    RevenuesQuery,
    RevenuesQueryVariables
  >,
  'query'
> &
  ({ variables: RevenuesQueryVariables; skip?: boolean } | { skip: boolean });

export const RevenuesComponent = (props: RevenuesComponentProps) => (
  <ApolloReactComponents.Query<RevenuesQuery, RevenuesQueryVariables>
    query={RevenuesDocument}
    {...props}
  />
);

/**
 * __useRevenuesQuery__
 *
 * To run a query within a React component, call `useRevenuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenuesQuery({
 *   variables: {
 *      from: // value for 'from'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useRevenuesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    RevenuesQuery,
    RevenuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<RevenuesQuery, RevenuesQueryVariables>(
    RevenuesDocument,
    options,
  );
}
export function useRevenuesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RevenuesQuery,
    RevenuesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<RevenuesQuery, RevenuesQueryVariables>(
    RevenuesDocument,
    options,
  );
}
export type RevenuesQueryHookResult = ReturnType<typeof useRevenuesQuery>;
export type RevenuesLazyQueryHookResult = ReturnType<
  typeof useRevenuesLazyQuery
>;
export type RevenuesQueryResult = Apollo.QueryResult<
  RevenuesQuery,
  RevenuesQueryVariables
>;
export function refetchRevenuesQuery(variables: RevenuesQueryVariables) {
  return { query: RevenuesDocument, variables: variables };
}
export const RevenueWithSourcesDocument = gql`
  query RevenueWithSources($id: ID!) {
    revenue(id: $id) {
      id
      amount
      currency {
        currencyCode
        precision
      }
      date
      revenueSourceId
    }
    revenueSources {
      items {
        id
        name
      }
    }
  }
`;
export type RevenueWithSourcesComponentProps = Omit<
  ApolloReactComponents.QueryComponentOptions<
    RevenueWithSourcesQuery,
    RevenueWithSourcesQueryVariables
  >,
  'query'
> &
  (
    | { variables: RevenueWithSourcesQueryVariables; skip?: boolean }
    | { skip: boolean }
  );

export const RevenueWithSourcesComponent = (
  props: RevenueWithSourcesComponentProps,
) => (
  <ApolloReactComponents.Query<
    RevenueWithSourcesQuery,
    RevenueWithSourcesQueryVariables
  >
    query={RevenueWithSourcesDocument}
    {...props}
  />
);

/**
 * __useRevenueWithSourcesQuery__
 *
 * To run a query within a React component, call `useRevenueWithSourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRevenueWithSourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRevenueWithSourcesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRevenueWithSourcesQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    RevenueWithSourcesQuery,
    RevenueWithSourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    RevenueWithSourcesQuery,
    RevenueWithSourcesQueryVariables
  >(RevenueWithSourcesDocument, options);
}
export function useRevenueWithSourcesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RevenueWithSourcesQuery,
    RevenueWithSourcesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    RevenueWithSourcesQuery,
    RevenueWithSourcesQueryVariables
  >(RevenueWithSourcesDocument, options);
}
export type RevenueWithSourcesQueryHookResult = ReturnType<
  typeof useRevenueWithSourcesQuery
>;
export type RevenueWithSourcesLazyQueryHookResult = ReturnType<
  typeof useRevenueWithSourcesLazyQuery
>;
export type RevenueWithSourcesQueryResult = Apollo.QueryResult<
  RevenueWithSourcesQuery,
  RevenueWithSourcesQueryVariables
>;
export function refetchRevenueWithSourcesQuery(
  variables: RevenueWithSourcesQueryVariables,
) {
  return { query: RevenueWithSourcesDocument, variables: variables };
}
