import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, InputAdornment, Tooltip } from '@mui/material';
import { useFormik } from 'formik';
import { orderBy } from 'lodash/fp';
import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import {
  refetchCategoriesAndSuppliersQuery,
  useCreateCategoryMutation,
  useCreateSupplierMutation,
} from '../../../../generated/graphql';
import { useUser } from '../../../../providers/current-user.provider';
import { useMoneyService } from '../../../../providers/money-service.provider';
import { DatePickerField, SelectField, TextField } from '../../../../ui';
import { translateAndCastToString } from '../../../../utils/translate';

const validationSchema = yup.object({
  invoiceNumber: yup
    .string()
    .min(
      2,
      translateAndCastToString(
        'Invoice number should be of minimum 2 characters length',
      ),
    ),
  categoryId: yup
    .string()
    .required(translateAndCastToString('Category is required')),
  supplierId: yup
    .string()
    .required(translateAndCastToString('Supplier is required')),
  amount: yup
    .number()
    .required(translateAndCastToString('Amount is required'))
    .min(0, translateAndCastToString('Amount cannot be negative'))
    .max(
      1000000,
      translateAndCastToString('Amount cannot be larger than 1000000'),
    ),
  dueDate: yup
    .date()
    .required(translateAndCastToString('Due date is required')),
  purchaseDate: yup
    .date()
    .required(translateAndCastToString('Purchase date is required')),
  link: yup.string().nullable(),
});

interface ExpenseFormProps {
  categories: Array<{ value: string; label: string }>;
  suppliers: Array<{ value: string; label: string }>;
  onSubmit: (values: SupplyExpenseFormValues) => void;
  buttonName: string;
  initialData?: Partial<SupplyExpenseFormValues>;
}

export interface SupplyExpenseFormValues {
  invoiceNumber?: string;
  amount: number | '';
  dueDate: DateTime | null;
  purchaseDate: DateTime | null;
  categoryId: string;
  supplierId: string;
  link?: string;
}

const SupplyExpenseForm = ({
  categories,
  suppliers,
  onSubmit,
  buttonName,
  initialData,
}: ExpenseFormProps) => {
  const [createNewSupplier, { loading: createSupplierLoading }] =
    useCreateSupplierMutation({
      refetchQueries: [refetchCategoriesAndSuppliersQuery()],
    });

  const [createNewCategory, { loading: createCategoryLoading }] =
    useCreateCategoryMutation({
      refetchQueries: [refetchCategoriesAndSuppliersQuery()],
    });

  const loading = createCategoryLoading || createSupplierLoading;

  const orderedSuppliers = orderBy('label', 'asc', suppliers);
  const orderedCategories = orderBy('label', 'asc', categories);

  const { t } = useTranslation();
  const { moneyService } = useMoneyService();
  const { user } = useUser();

  const purchaseDate = DateTime.now();
  const initialDueDate = purchaseDate.plus({ days: 14 });

  const formik = useFormik<SupplyExpenseFormValues>({
    validateOnBlur: true,
    validateOnChange: true,
    initialValues: {
      purchaseDate,
      dueDate: initialDueDate,
      invoiceNumber: '',
      amount: '',
      categoryId: '',
      supplierId: '',
      link: '',
      ...initialData,
    },
    validationSchema,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const purchaseDateLabel = (
    <Tooltip
      title={t(
        'Purchase date is the date when expense will appear on your dashboard and at the One-Time expense list.',
      )}
    >
      <span>
        {t('Day of purchase')}
        <InfoOutlinedIcon
          sx={{ marginLeft: '4px', fontSize: '1rem' }}
          color="primary"
        />
      </span>
    </Tooltip>
  );

  return (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <TextField
        autoFocus
        fullWidth
        required
        id="amount"
        name="amount"
        label={t('Amount')}
        placeholder={t('1250.35')}
        value={formik.values.amount}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.amount && Boolean(formik.errors.amount)}
        helperText={formik.touched.amount && formik.errors.amount}
        InputProps={{
          type: 'number',
          endAdornment: (
            <InputAdornment position="end">
              {/* getCurrency doesn't localize it... We need another way */}
              {moneyService.getCurrency({
                currency: user.currency,
                locale: user.languageCode,
              })}
            </InputAdornment>
          ),
        }}
      />
      <SelectField
        fullWidth
        select
        required
        disabled={loading}
        placeholder={t('Makro s.r.o.')}
        id="supplierId"
        name="supplierId"
        label={t('Supplier')}
        value={formik.values.supplierId}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.supplierId && Boolean(formik.errors.supplierId)}
        helperText={formik.touched.supplierId && formik.errors.supplierId}
        options={orderedSuppliers}
        addNewText={t('Create supplier')}
        onCreateNewMutation={async (name: string) => {
          try {
            const result = await createNewSupplier({
              variables: {
                name,
              },
              toastConfig: {
                successText: t(
                  `New supplier {{name}} was successfully created`,
                  {
                    name,
                  },
                ),
              },
            });

            if (result?.data) {
              await formik.setFieldValue(
                'supplierId',
                result.data.createSupplier.id,
              );
            }
          } catch (e) {
            // Nothing we can do
          }
        }}
      />
      <SelectField
        fullWidth
        select
        required
        disabled={loading}
        id="categoryId"
        name="categoryId"
        label={t('Category')}
        placeholder={t('General')}
        value={formik.values.categoryId}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.categoryId && Boolean(formik.errors.categoryId)}
        helperText={formik.touched.categoryId && formik.errors.categoryId}
        options={orderedCategories}
        onCreateNewMutation={async (name: string) => {
          try {
            const result = await createNewCategory({
              variables: {
                name,
              },
              toastConfig: {
                successText: t(
                  `New category {{name}} was successfully created`,
                  {
                    name,
                  },
                ),
              },
            });

            if (result?.data) {
              await formik.setFieldValue(
                'categoryId',
                result.data.createCategory.id,
              );
            }
          } catch (e) {
            // Nothing we can do
          }
        }}
      />
      <DatePickerField
        name="purchaseDate"
        required
        label={purchaseDateLabel}
        value={formik.values.purchaseDate}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.purchaseDate && Boolean(formik.errors.purchaseDate)
        }
        helperText={formik.touched.purchaseDate && formik.errors.purchaseDate}
      />
      <DatePickerField
        name="dueDate"
        required
        label={t('Due date')}
        value={formik.values.dueDate}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.dueDate && Boolean(formik.errors.dueDate)}
        helperText={formik.touched.dueDate && formik.errors.dueDate}
      />
      <TextField
        id="invoiceNumber"
        name="invoiceNumber"
        label={t('Invoice number')}
        placeholder={t('#101325')}
        value={formik.values.invoiceNumber}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.invoiceNumber && Boolean(formik.errors.invoiceNumber)
        }
        helperText={formik.touched.invoiceNumber && formik.errors.invoiceNumber}
      />
      <TextField
        id="link"
        name="link"
        label={t('Document link')}
        placeholder="https://google.com"
        value={formik.values.link}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.link && Boolean(formik.errors.link)}
        helperText={formik.touched.link && formik.errors.link}
      />
      <Box mt={3} display="flex" justifyContent="flex-end" alignItems="center">
        <Button
          disabled={loading}
          color="primary"
          variant="contained"
          type="submit"
        >
          {buttonName}
        </Button>
      </Box>
    </form>
  );
};

export default SupplyExpenseForm;
