import React from 'react';
import ReactDOM from 'react-dom/client';
import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import App from './App';
import './intl';

const siteId = 3089839;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

const isProduction = import.meta.env.PROD;

const SENTRY_DSN = isProduction
  ? 'https://8c8d7bb97c1f459ab3b2aefe63cf12e0@o431833.ingest.sentry.io/6554952'
  : undefined;

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['demo-api.dishboard.app', 'api.dishboard.app'],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
  ],

  tracesSampleRate: 0.2,
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
