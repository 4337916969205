import { Box } from '@mui/material';
import styled from 'styled-components';
import { DropzoneRootProps } from 'react-dropzone';

export const InvoicesListItemsContainer = styled(Box)`
  ${({ theme }) => `
		border-radius: ${theme.borderRadius};
	`}
  border: solid 1px #dde6fd;
  overflow: hidden;

  > * {
    background: #dde6fd66;
  }

  > :nth-child(2n) {
    background: transparent;
  }
`;

interface UploadDeliveryListDropzoneProps extends DropzoneRootProps {
  backgroundColor: string;
  borderColor: string;
  isBorderDashed?: boolean;
}

export const UploadDeliveryListDropzone = styled.div<UploadDeliveryListDropzoneProps>`
  width: 100%;
  height: 400px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;

  background-color: ${(props) => props.backgroundColor};
  border: 2px ${(props) => (props.isBorderDashed ? 'dashed' : 'solid')}
    ${(props) => props.borderColor};
  border-radius: 10px;

  cursor: pointer;
  user-select: none;
`;
