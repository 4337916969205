import { FC, PropsWithChildren, createContext, useContext } from 'react';
import { CurrentUser } from './auth.provider';

interface UserContextInterface {
  user: CurrentUser;

  logout(): Promise<void>;
}

const UserContext = createContext<UserContextInterface>(
  {} as UserContextInterface,
);

export const CurrentUserProvider: FC<
  PropsWithChildren<UserContextInterface>
> = ({ children, ...context }) => (
  <UserContext.Provider value={context}>{children}</UserContext.Provider>
);

export const useUser = () => useContext(UserContext);
