import { LocalizationProvider } from '@mui/x-date-pickers';
import React, { PropsWithChildren } from 'react';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { useUser } from './current-user.provider';

const DateLocalizationprovider: React.FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const { user } = useUser();

  const locale = user.cultureCode;

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale={locale}>
      {children}
    </LocalizationProvider>
  );
};

export default DateLocalizationprovider;
