import { CultureCode } from '../generated/graphql';
import { LocaleEnumFe } from '../types/localeEnumFe';

export const mapBeLocaleToFeLocale = (locale: CultureCode) => {
  switch (locale) {
    case CultureCode.CsCz:
      return LocaleEnumFe.csCZ;
    case CultureCode.EnUs:
      return LocaleEnumFe.enUs;
    default:
      return LocaleEnumFe.csCZ;
  }
};
