import {
  Box,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import styled from 'styled-components';

export const Accordion = styled(MuiAccordion)`
  &.Mui-expanded {
    margin: 0;
  }

  && {
    ${({ theme }) => `
			border-radius: ${theme.borderRadius};
		`}
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  &&:hover {
    cursor: default;
  }

  ${({ theme }) => `
		padding: 0 ${theme.spacing(4.5)};
	`}
  & .Mui-expanded {
    margin: 12px 0;
  }

  &.MuiAccordionSummary-gutters {
    ${({ theme }) => `
			padding: ${theme.spacing(3)} ${theme.spacing(4.5)};
		`}
  }

  & .MuiAccordionSummary-contentGutters {
    margin: 0;
  }

  &.Mui-expanded {
    :after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #dfe8ff;
      bottom: 0;
    }
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  ${({ theme }) => `
		padding: ${theme.spacing(3)} ${theme.spacing(4.5)} ${theme.spacing(4.5)};
	`}
`;

export const EmployeeExpensesContainer = styled(Box)`
  ${({ theme }) => `
		border-radius: ${theme.borderRadius};
	`}
  border: solid 1px #dde6fd;
  overflow: hidden;

  > * {
    background: #dde6fd66;
  }

  > :nth-child(2n) {
    background: transparent;
  }
`;
