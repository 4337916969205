import React from 'react';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const ToggleableChevron = styled(({ open, ...rest }: { open: boolean }) => (
  <KeyboardArrowDownIcon {...rest} />
))`
  ${({ open }) => `
        ${open ? `transform: rotate(-180deg);` : ``}
    `}
  ${({ theme }) => `
        && {
        transition: ${theme.transitions.create(['transform'], {
          duration: theme.transitions.duration.standard,
          easing: theme.transitions.easing.easeInOut,
        })}}`}
`;

export default ToggleableChevron;
