import styled from 'styled-components';

interface DividerProps {
  isFullHeight?: boolean;
}

const Divider = styled.div<DividerProps>`
  width: 2px;
  ${({ isFullHeight }) => (isFullHeight ? `height: 100%;` : '')}
  background-color: ${(props) => props.theme.palette.divider};
`;

export default Divider;
