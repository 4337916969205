import { useApolloClient } from '@apollo/client';
import { Backdrop, CircularProgress } from '@mui/material';
import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import {
  CheckAuthStateDocument,
  CheckAuthStateMutation,
  CheckAuthStateMutationVariables,
  CurrentUserFragment,
  LoginDocument,
  LoginMutation,
  LoginMutationVariables,
  LogoutDocument,
  LogoutMutation,
  LogoutMutationVariables,
  SwitchVenueDocument,
  SwitchVenueMutation,
  SwitchVenueMutationVariables,
  useVenuesLazyQuery,
} from '../generated/graphql';
import useSilentMutation from '../graphql/hooks/useSilentMutation';
import i18n from '../intl';
import { LocaleEnumFe } from '../types';
import { mapBeLocaleToFeLocale } from '../utils';

export interface CurrentUser
  extends Omit<
    CurrentUserFragment,
    'cultureCode' | 'languageCode' | 'subscriptionData'
  > {
  cultureCode: LocaleEnumFe;
  languageCode: LocaleEnumFe;
  ocrEmail: string;
  ocrEnabled: boolean;
}

interface AuthContextInterface {
  user: CurrentUser | null;
  login: (loginArgs: LoginMutationVariables) => Promise<void>;
  isLoginLoading?: boolean;
  logout: () => Promise<void>;
  updateUserInfo: (userPayload: CurrentUserFragment) => void;
  updateUserProfileInfo: (
    userPayload: Omit<CurrentUserFragment, 'subscriptionData'>,
  ) => void;
  switchVenue: (venueId: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextInterface>({
  user: null,
} as AuthContextInterface);

const LOCAL_STORAGE_USER_KEY = 'user';

const setCurrentUserToLS = (currentUser: CurrentUser) =>
  localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(currentUser));
const unsetCurrentUserFromLS = () =>
  localStorage.removeItem(LOCAL_STORAGE_USER_KEY);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const apolloClient = useApolloClient();
  const [user, setUser] = useState<CurrentUser | null>(null);
  const [queryVenues, { loading: isVenuesLoading }] = useVenuesLazyQuery();

  const setUserWithSentry = (newUser: CurrentUser | null) => {
    Sentry.setContext('user', newUser);
    // @ts-ignore
    window.hj('identify', newUser?.client_id, newUser);
    setUser(newUser);
  };

  const [checkAuthMutation] = useSilentMutation<
    CheckAuthStateMutation,
    CheckAuthStateMutationVariables
  >(CheckAuthStateDocument);

  const updateUserProfileInfo = (
    userProfileInfo: Omit<CurrentUserFragment, 'subscriptionData'>,
  ) => {
    return updateUserInfo({
      ...userProfileInfo,
    });
  };
  const updateUserInfo = (userPayload: CurrentUserFragment) => {
    const { cultureCode, languageCode, ...other } = userPayload;
    const fetchedUser = {
      ...other,
      ocrEmail: '',
      ocrEnabled: false,
      cultureCode: mapBeLocaleToFeLocale(cultureCode),
      languageCode: mapBeLocaleToFeLocale(languageCode),
    };

    i18n
      .changeLanguage(fetchedUser.languageCode)
      .catch((e) => console.error(e));

    setCurrentUserToLS(fetchedUser);
    setUserWithSentry(fetchedUser);
  };

  const processFetchedUser = async (userPayload: CurrentUserFragment) => {
    updateUserInfo(userPayload);
    await queryVenues();
  };

  const checkAuth = async () => {
    try {
      const { data } = await checkAuthMutation();
      if (data && data.checkAuthState) {
        await processFetchedUser(data.checkAuthState);
      }
    } catch (err) {
      await logout();
      if (user) {
        toast(t('You are unauthorized and logged out.'), { type: 'error' });
      }
    }
  };

  const [loginMutation, { loading: isLoginLoading }] = useSilentMutation<
    LoginMutation,
    LoginMutationVariables
  >(LoginDocument);

  const login = async (variables: LoginMutationVariables) => {
    const { data } = await loginMutation({
      variables,
    });
    if (data) {
      await processFetchedUser(data.login);
    }
  };

  useEffect(() => {
    checkAuth()
      .catch((err) => {
        unsetCurrentUserFromLS();
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const [logoutMutation] = useSilentMutation<
    LogoutMutation,
    LogoutMutationVariables
  >(LogoutDocument);

  const [switchVenueMutation, { loading: isSwitchingVenue }] =
    useSilentMutation<SwitchVenueMutation, SwitchVenueMutationVariables>(
      SwitchVenueDocument,
    );

  const logout = async () => {
    try {
      await logoutMutation();
      setUserWithSentry(null);
      unsetCurrentUserFromLS();
    } catch (err) {
      console.error(err);
    }
  };

  const switchVenue = async (venueId: string) => {
    try {
      const { data } = await switchVenueMutation({ variables: { venueId } });
      if (data && data.switchVenue) {
        updateUserInfo(data.switchVenue);
        await apolloClient.resetStore();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const localStorageListener = (event: StorageEvent) => {
    if (event.key === LOCAL_STORAGE_USER_KEY) {
      const { oldValue, newValue } = event;

      if (!oldValue && newValue) {
        checkAuth().catch((err) => {
          console.error(err);
        });
      }

      if (oldValue && !newValue) {
        setUserWithSentry(null);
      }

      if (oldValue && newValue) {
        const parsedNewValue = JSON.parse(newValue) as CurrentUser;
        const parsedOldValue = JSON.parse(oldValue) as CurrentUser;
        if (
          parsedNewValue.scope.currentUserId !==
          parsedOldValue.scope.currentUserId
        ) {
          window.location.reload();
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener('storage', localStorageListener);
    return () => {
      window.removeEventListener('storage', localStorageListener);
    };
    // eslint-disable-next-line
  }, []);

  if (isLoading || isVenuesLoading || isSwitchingVenue) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  /* eslint-disable react/jsx-no-constructed-context-values */
  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        isLoginLoading,
        switchVenue,
        updateUserInfo,
        updateUserProfileInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
