import { Box, BoxProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const FixedBox = styled(
  ({
    showBottomBorder,
    showTopBorder,
    ...other
  }: Omit<BoxProps, 'position'> & {
    showBottomBorder?: boolean;
    showTopBorder?: boolean;
    isFullWidth?: boolean;
  }) => <Box position="fixed" {...other} />,
)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  ${({ showBottomBorder, theme }) =>
    showBottomBorder &&
    `
		border-bottom: 2px solid ${theme.palette.divider};
	`}
  ${({ showTopBorder, theme }) =>
    showTopBorder &&
    `
	border-top: 2px solid ${theme.palette.divider};
`}
	height: ${({ theme }) => theme.toolbar.desktopHeight}px;
  z-index: 10;
  width: 100%;
  right: 0;
  ${({ theme, isFullWidth }) =>
    !isFullWidth
      ? `
	${theme.breakpoints.up('md')} {
		width: calc(100% - ${theme.drawer.width}px);
	}`
      : ''}
`;

export default FixedBox;
